import LocalData from "../persistencia/LocalData";

const queryStatement = (params, strController) => {
  const URL = process.env.REACT_APP_URL_API;

  const resp = fetch(`${URL}${strController}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  })
    .then((response) => response.json())
    .then((responseData) => {
      //console.log(responseData);
      return responseData;
    })
    .catch((error) => console.warn(error));
    return resp;
};

const UsuarioService = {
  queryStatement,
};

export default UsuarioService;
