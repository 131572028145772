    import React, {useEffect, useState} from 'react';
    
    import MenuCustom from '../componentes/MenuCustom';
    import { InboxOutlined } from '@ant-design/icons';
    import {
    Button,
    Form,
    Spin,
    Upload,message,Layout,Breadcrumb
    } from 'antd';
    import HeaderCustom from '../componentes/HeaderCustom';
    import { Content,Footer } from 'antd/es/layout/layout'; 
    import FooterCustom from '../componentes/FooterCustom';

    const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 14,
    },
    };

    export default function  ConcentradorPagos(){

        useEffect(() => {
        
            setBandera(false);
        
        
        }, []);

        const [messageApi, contextHolder] = message.useMessage();
        const [bandera , setBandera] = useState(true);
        const [fileList, setFileList] = useState([]);

        

    const props = {
        onRemove: (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
        },
        beforeUpload: (file) => {
            if(fileList.length === 0){
                setFileList([...fileList, file]);
                return false;
            }
        
        },
        fileList, 
        
        
    };






    const getApiAsync = async  () => {

    
    
        
        
            const formData = new FormData();
            fileList.forEach((file) => {
                formData.append('file', file);
            });
            
    
                setBandera(true);
                fetch('http://192.168.0.91:8080/AmortizacionesCartera/excel', {
                    method: 'POST',
                    responseType: 'blob',
                    body: formData,
                }).then( response => checkStatus(response) && response.arrayBuffer())
                .then(buffer => {
                    setBandera(false);

                    const link = document.createElement( 'a' );
                    link.style.display = 'none';
                    document.body.appendChild( link );


                    const blob = new Blob( [ buffer ], { type: 'application/vnd.ms-excel' } );	
                    const objectURL = URL.createObjectURL( blob );
                    
                    link.href = objectURL;
                    link.href = URL.createObjectURL( blob );

                    const fecha = new Date();
                    const mesAnio = fecha.getMonth()+1 +'_'+fecha.getFullYear(); 
                    link.download =  'Concentrado Pagos Acendes_'+mesAnio+'.xlsx';
                    link.click();
                    messageApi.open({
                        type: 'success',
                        content: 'Operación realiza con éxito!!!',
                    });
                    
                    
                })

                .catch(err => {
                    console.error(err);
                    messageApi.open({
                        type: 'error',
                        content: 'Ocurrio un error en el servicio, comuniquese con soporte tecnico',
                    });
                    setBandera(false);
                  
                });
            
            
        
        }
        function checkStatus(response) {
            if (!response.ok) {
            throw new Error(`HTTP ${response.status} - ${response.statusText}`);
            }
            return response;
        }
        

        

    



    return (
        
            <Spin  tip="Cargando..." size="large" spinning={bandera} delay={500}>
                  {contextHolder}
                   <Layout
                style={{
                    minHeight: '100vh',
                }}
                >
                        <MenuCustom/>
                                <Layout className="site-layout">
                            
                                
                                
                                    <HeaderCustom/>
                                    
                                    <Breadcrumb
                                    style={{
                                    margin: '16px 20px',
                                    }}
                                >
                                    <Breadcrumb.Item>Reportes</Breadcrumb.Item>
                                    <Breadcrumb.Item>Finanzas</Breadcrumb.Item>
                                    <Breadcrumb.Item>Concentrado de Pagos Mensual</Breadcrumb.Item>
                                </Breadcrumb>
                                
                                    <Content  style={{
                                    margin: '0 20px',
                                }}>

                    
                        <Form
                        name="validate_other"
                        {...formItemLayout}
                        onFinish={getApiAsync}
                        initialValues={{
                        'input-number': 3,
                        'checkbox-group': ['A', 'B'],
                        rate: 3.5,
                        }}
                    >
              
                        <Form.Item label="Archivo:">
                        <Form.Item   noStyle>
                            <Upload.Dragger name="files"   {...props} >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Dar click ó arrastar el archivo en esta área</p>
                            </Upload.Dragger>
                        </Form.Item>
                        </Form.Item>

                        <Form.Item
                        wrapperCol={{
                            span: 12,
                            offset: 6,
                        }}
                        >
                        <Button type="primary" htmlType="submit" disabled={fileList.length === 0}>
                            Concentrar
                        </Button>
                        </Form.Item>
                    </Form>
                    </Content>
                    <FooterCustom/>
                    
            </Layout>
        </Layout>
                    
            </Spin>
        
    );
    };