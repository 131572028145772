import React, { useState, useEffect, useRef, useContext } from "react";
import AuthService from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import Highlighter from "react-highlight-words";
import Structure from "../../componentes/Structure";
import InversionService from "../../services/inversion.Service";
import FacturacionService from "../../services/pagos.Service";
import { ComponentesContext } from "../../contextos/componentesContext";
import "./Prospecciones.css";
import DatePickerCustom from "../../componentes/DatePickerCustom";
import moment from "moment";
import ModalDocumentViewer from "../../componentes/ModalDocumentViewer";
import InversionistaService from "../../services/inversionista.Service";
import {
  CarryOutOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  QuestionCircleOutlined,
  FundProjectionScreenOutlined,
  MailOutlined,
  TableOutlined
} from "@ant-design/icons";

import {
  Button,
  Input,
  Tag,
  FloatButton,
  Space,
  Table,
  Popconfirm,
  Breadcrumb,
  theme,
  Typography,
  Modal,
  Modal as ModalSolicitud,
} from "antd";
import HeaderCustom from "../../componentes/HeaderCustom";
import { Content, Footer } from "antd/es/layout/layout";

const { Column } = Table;
const { Title } = Typography;

const GenerarSolicitudFactura = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const { CloseSession, ShowLoading, ModalMessage } =
    useContext(ComponentesContext);
  const [dataSource, setDataSource] = useState([]);
  const [columnsData, setcolumnsData] = useState([]);
  const [codInvRow, setcodInvRow] = useState("");
  const [idInversion, setidInversion] = useState("");
  const [rfcRow, setrfcRow] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dateFormat = "YYYY-MM-DD";
  const [array, setArray] = useState([]);
  const [pathDocument, setpathDocument] = useState([]);
  const [isModalSolicitudOpen, setIsModalSolicitudOpen] = useState(false);

  const [mailValue, setmailValue ] = useState("");

  const [DateValue, setDateValue] = useState("");

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getSolicitudes = async () => {
    //console.log("entro");
    let respuesta = await FacturacionService.getSolicitudes();

    if (respuesta.estado == 403) {
      CloseSession();
      return false;
    }

    if (respuesta.estado == 500) {
      ModalMessage("Mensaje", "Error al obtener la solicitudes.");
      return false;
    }
    const newColumns = [...respuesta.arrHeader];
    const newData = newColumns.map(item => {
      //console.log(item.dataIndex);

      var arrwidth=100;
      switch (item.dataIndex) {
        case "nombreInversionista":
          arrwidth=210;
          break;
        case "rfc":
          arrwidth=120;
          break;
        case "isr":case "iva":
          arrwidth=60;
          break;
        default:
          arrwidth=100;
          break;
      }    

      return {
        ...item,
        key: item.dataIndex,
        width: arrwidth
      };
      
    });
    const extraColumn = {
      title: "Opciones",
      width: 105,
      render: (text) => (
        <div>
          {/* <a className="button" onClick={HandleEditar}>
            <span>Editar</span>
          </a> */}
          <a className="button" onClick={handleModalOpen}>
            <span>Mostrar</span>
          </a>
        </div>
      ),
    };

    newData.push(extraColumn);
    setcolumnsData(newData);

    setDataSource(respuesta.listSolicitudes);
    //console.log(respuesta.listSolicitudes);
  };

  const action = (dataIndex) => ({
    return: (
      <Space size="middle">
        <a>Invite {dataIndex}</a>
        <a>Delete</a>
      </Space>
    ),
  });
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Limpiar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleEdit = (id) => {
    //console.log("Clicked edit button", id);
    navigate("/conf-usuarios-update", { state: { id: id } });
  };
  const handleCancel = () => {
    //console.log("Clicked cancel button");
  };

  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    //console.log("USER HOOK", usr);

    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate("/login");
    }

    //getSolicitudes();
  }, []);

  const HandleActivar = async () => {
    let respuestaData = await InversionService.postCrearInversion({
      codInversionista: codInvRow,
      idInversion: idInversion,
    });
    if (respuestaData.status == 403) {
      CloseSession();
      return false;
    }

    if (respuestaData.estado == 500) {
      ModalMessage("Mensaje", "Se produjo un error al activar la inversión.");
      return false;
    }

    //getSolicitudes();
    setIsModalOpen(false);
    ModalMessage("Mensaje", "La inversión se activo correctamente.");
  };

  const HandleEditar = () => {
    //console.log(rfcRow);
    //console.log(codInvRow);

    navigate("/inversion-registro", {
      state: {
        record: {
          rfc: rfcRow,
          status: 0,
          codInv: codInvRow,
        },
      },
    });
  };

  const HandleMouseEnter = (record) => {
    //console.log(record);

    setcodInvRow(record.codInv);
    // setrfcRow(record.rfc);
    setidInversion(record.codInversion);
    // setpathDocument()
  };

  const handleDelete = (key) => {
    //console.log("DROP ITEM::", key);
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const handleVerListado = () => {
    navigate("/solicitudes");
  };

  const columns = [
    {
      title: "Periodo",
      dataIndex: "noPeriodo",
      key: "noPeriodo",
      width: 75,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.noPeriodo - b.noPeriodo,
      //...getColumnSearchProps('name'),
    },
    {
      title: "Capital inicial",
      dataIndex: "capIni",
      key: "capIni",

      ...getColumnSearchProps("name"),
    },
    {
      title: "Capital final",
      dataIndex: "capFin",
      key: "capFin",

      //...getColumnSearchProps('name'),
    },
    {
      title: "Capital pago",
      dataIndex: "capitalPago",
      key: "capitalPago",

      //...getColumnSearchProps('name'),
    },
    {
      title: "Interes",
      dataIndex: "interes",
      key: "interes",

      //...getColumnSearchProps('age'),
    },
    {
      title: "Valor tasa estimada",
      dataIndex: "valorTasaEst",
      key: "valorTasaEst",

      //...getColumnSearchProps('age'),
    },
    {
      title: "Tasa real",
      dataIndex: "valorTasaReal",
      key: "valorTasaReal",
      width: 66,
      //...getColumnSearchProps('age'),
    },
    {
      title: "Pago total",
      dataIndex: "pagoTotal",
      key: "pagoTotal",
      width: 66,
      //...getColumnSearchProps('age'),
    },
    {
      title: "Pago total real",
      dataIndex: "pagoTotalReal",
      key: "pagoTotalReal",
      width: 90,
      //...getColumnSearchProps('age'),
    },
    // {
    //   title: "Tipo Tasa",
    //   dataIndex: "descripcionTipoTasa",
    //   key: "descripcionTipoTasa",
    //   filters: [
    //     {
    //       text: "Cetes",
    //       value: "Cetes",
    //     },
    //     {
    //       text: "TIIE",
    //       value: "TIIE",
    //     },
    //     {
    //       text: "UDIS",
    //       value: "UDIS",
    //     },
    //   ],
    //   onFilter: (value, record) => record.descripcionTipoTasa.startsWith(value),
    // },
    {
      title: "Fecha inicio",
      dataIndex: "fechaInicial",
      key: "fechaInicial",
      width: 66,
      // ...getColumnSearchProps('address'),
    },
    {
      title: "Estado",
      key: "estado",
      dataIndex: "estado",
      filters: [
        {
          text: "Sin enviar",
          value: "4f",
        },
        {
          text: "Otro",
          value: "1",
        },
      ],
      onFilter: (value, record) => record.estado.startsWith(value),
      defaultSortOrder: "descend",
      sorter: (a, b) => a.estado - b.estado,
      render: (_, record) => <>{ReturnStatusTag(record.estado)}</>,
    },
    {
      title: "Opciones",
      render: (text) => (
        <div>
          <a className="button" onClick={handleModalSolicitudOpen}>
            <span>Ver</span>
          </a>
        </div>
      ),
    },
  ];

  const ReturnStatusTag = (status) => {
    let color = "";
    let text = "";
    switch (status) {
      case "4f":
        color = "#ffc300";
        text = "Sin enviar";
        break;
      case "0":
        color = "#24b237";
        text = "Enviado";
        break;
      default:
        color = "#ff00bb";
        text = "Otro";
    }
    return (
      <Tag color={color} key={status}>
        {text.toUpperCase()}
      </Tag>
    );
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalSolicitudOpen = async () => {
    let respuestaData = await FacturacionService.postSolicitudFacturacion({
      codInversionista: codInvRow,
      idInversion: idInversion,
    });

    if (respuestaData.status == 403) {
      CloseSession();
      return false;
    }

    if (respuestaData.estado == 500) {
      ModalMessage(
        "Mensaje",
        "Se produjo un error al generar al registrar la fecha de la orden de pago."
      );
      return false;
    }

    //console.log(respuestaData);

    setpathDocument(respuestaData.path);

    let respuesta = await InversionistaService.postRFC({
      rfc: respuestaData.objReport.rfc,
    });
    if (respuesta.status == 403) {
      CloseSession();
      return false;
    }

    if(respuesta.data===null){
      setmailValue("victor.villanueva@finamo.mx");
    }
    else{
      setmailValue(respuesta.data.inversionista.correoFactura);
    }

    setIsModalSolicitudOpen(true);
  };

  const handleGeneraOrden = async () => {
    if (DateValue == "") {
      ModalMessage("Mensaje", "La fecha no puede estar en blanco.");
      return false;
    }

    let respuestaData = await FacturacionService.postGeneraCortePago({
      fechaPago: moment(new Date(DateValue)).format("YYYY-MM-DD") ,
    });
    if (respuestaData.status == 403) {
      CloseSession();
      return false;
    }

    if (respuestaData.estado == 500) {
      ModalMessage(
        "Mensaje",
        "Se produjo un error al generar al registrar la fecha de la orden de pago."
      );
      return false;
    }

    //getSolicitudes();

    handleVerListado();


    //console.log(respuestaData);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalSolicitudClose = () => {
    setIsModalSolicitudOpen(false);
  };

  const handleDateInputChange = (date) => {
    //console.log(moment(new Date(date)).format("YYYY-MM-DD"));
    setDateValue(moment(new Date(date)).format("YYYY-MM-DD"));
  };

  const handleInputMail = (e) => {
    setmailValue(e.target.value);
  };

  const handleEnviarCorreo = () =>{
    var reg =
          /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

    if(mailValue==""){
      ModalMessage(
        "Mensaje",
        "El correo no puede estan en blanco."
      );
      return false;
    }
    
    if (!reg.test(mailValue)) {
      //onChange(e.target.value);
      //setAlertVisible(false);
      ModalMessage(
        "Mensaje",
        "El formato del correo es incorrecto."
      );
      return false;
    }

    ModalMessage(
      "Mensaje",
      "El correo se envió correctamente."
    );

  }

  return (
    <Structure breadcrumb={"Inversiones / Solicitudes"}>
      <center>
        <Typography.Title level={2} style={{ margin: 0, color: "#081b3e" }}>
          Generar solicitudes de facturas para pagos.
        </Typography.Title>
      </center>
      <br></br>
      <center>
        <table>
          <tbody>
          {/* <tr>
              <td style={{height:40}}>
                <Title style={{ marginTop: 0 }} level={5}>
                  Fecha ultima programación:
                </Title>
              </td>
              <td>
                <DatePickerCustom
                  format={dateFormat}
                  style={{ width: 160 }}
                  onChange={handleDateInputChange}
                  disabled={true}
                  // ref={(dateSelect) => { this.dateSelect = dateSelect }}
                  // disabledDate={(current) => {
                  //   return moment().add(-1, 'days')  >= current;
                  //   }}
                />
              </td>
            </tr> */}

            <tr>
              <td style={{height:40}}>
                <Title style={{ marginTop: 0 }} level={5}>
                  Fecha del proximo pago:
                </Title>
              </td>
              <td>
                <DatePickerCustom
                  format={dateFormat}
                  style={{ width: 160 }}
                  onChange={handleDateInputChange}
                  // ref={(dateSelect) => { this.dateSelect = dateSelect }}
                  // disabledDate={(current) => {
                  //   return moment().add(-1, 'days')  >= current;
                  //   }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2} style={{height:40}}>
              <Button
                  style={{ width: "100%" }}
                  type="primary"
                  onClick={() => handleGeneraOrden()}
                  icon={<CarryOutOutlined />}
                >
                  Generar solicitud para próximo pago
                </Button>
              </td>
            </tr>

            <tr>
              <td colSpan={2} style={{height:40}}>
              <Button
                  style={{ width: "100%" }}
                  type="primary"
                  onClick={() => handleVerListado()}
                  icon={<TableOutlined />}
                >
                  Ver listado de solicitudes de pago
                </Button>
              </td>
            </tr>

          </tbody>
        </table>
      </center>
      <br></br>

    </Structure>
  );
};
export default GenerarSolicitudFactura;
