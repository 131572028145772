import AuthService from "../services/auth.service";

const postSimulacion = (json) => {
  const URL = process.env.REACT_APP_URL_API;
  const resp = fetch(`${URL}inversion/view/simulacion`, {
    method: "POST",
    headers: {
      "Authorization": AuthService.getCurrentToken(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else if(responseData.status==90){
          return {"estado":90};
        }
        else{
          return {"estado":500};
        }
      }
      else{
        return responseData;
      }
    })
    .catch(err => {
      return {"estado":500};
  });
  return resp;
};


const postInversionCurso = (json) => {
  const URL = process.env.REACT_APP_URL_API;
  const resp = fetch(`${URL}inversion/view/inversiones-curso`, {
    method: "POST",
    headers: {
      "Authorization": AuthService.getCurrentToken(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else if(responseData.status==90){
          return {"estado":90};
        }
        else{
          return {"estado":500};
        }
      }
      else{
        return responseData;
      }
    })
    .catch(err => {
      return {"estado":500};
  });
  return resp;
};


const postProspecciones = () => {
  const URL = process.env.REACT_APP_URL_API;
  const resp = fetch(`${URL}inversion/view/all/inversiones`, {
    method: "POST",
    headers: {
      "Authorization": AuthService.getCurrentToken(),
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else if(responseData.status==90){
          return {"estado":90};
        }
        else{
          return {"estado":500};
        }
      }
      else{
        return responseData;
      }
    })
    .catch(err => {
      return {"estado":500};
  });
  return resp;
};

const postInversionesActivas = () => {
  const URL = process.env.REACT_APP_URL_API;
  const resp = fetch(`${URL}inversion/view/activas`, {
    method: "GET",
    headers: {
      "Authorization": AuthService.getCurrentToken(),
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else if(responseData.status==90){
          return {"estado":90};
        }
        else{
          return {"estado":500};
        }
      }
      else{
        return responseData;
      }
    })
    .catch(err => {
      return {"estado":500};
  });
  return resp;
};

const postPeriodosInversion = () => {
  const URL = process.env.REACT_APP_URL_API;
  const resp = fetch(`${URL}inversion/view/periodos`, {
    method: "POST",
    headers: {
      "Authorization": AuthService.getCurrentToken(),
      "Content-Type": "application/json",
    }
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else if(responseData.status==90){
          return {"estado":90};
        }
        else{
          return {"estado":500};
        }
      }
      else{
        return responseData;
      }
    })
    .catch(err => {
      return {"estado":500};
  });
  return resp;
};


const postCondicionesFinancieras = (json) => {
  const URL = process.env.REACT_APP_URL_API;
  const resp = fetch(`${URL}inversion/save/condiciones-financieras`, {
    method: "POST",
    headers: {
      "Authorization": AuthService.getCurrentToken(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else if(responseData.status==90){
          return {"estado":90};
        }
        else{
          return {"estado":500};
        }
      }
      else{
        return responseData;
      }
    })
    .catch(err => {
      return {"estado":500};
  });
  return resp;
};

const postCrearInversion = (json) => {
  const URL = process.env.REACT_APP_URL_API;
  const resp = fetch(`${URL}inversion/create/inversion`, {
    method: "POST",
    headers: {
      "Authorization": AuthService.getCurrentToken(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else if(responseData.status==90){
          return {"estado":90};
        }
        else{
          return {"estado":500};
        }
      }
      else{
        return responseData;
      }
    })
    .catch(err => {
      return {"estado":500};
  });
  return resp;
};

const postEliminarInversion = (idInversion) => {
  const URL = process.env.REACT_APP_URL_API;
  const resp = fetch(`${URL}inversion/save/eliminar-prospeccion/${idInversion}`, {
    method: "DELETE",
    headers: {
      "Authorization": AuthService.getCurrentToken(),
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else if(responseData.status==90){
          return {"estado":90};
        }
        else{
          return {"estado":500};
        }
      }
      else{
        return responseData;
      }
    })
    .catch(err => {
      return {"estado":500};
  });
  return resp;
};

const postActivarInversion = (idInversion) => {
  const URL = process.env.REACT_APP_URL_API;
  const resp = fetch(`${URL}inversion/save/activar-prospeccion/${idInversion}`, {
    method: "POST",
    headers: {
      "Authorization": AuthService.getCurrentToken(),
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else if(responseData.status==90){
          return {"estado":90};
        }
        else{
          return {"estado":500};
        }
      }
      else{
        return responseData;
      }
    })
    .catch(err => {
      return {"estado":500};
  });
  return resp;
};

const getListDocumentos = (idInversion) => {
  const URL = process.env.REACT_APP_URL_API;
  const resp = fetch(`${URL}inversion/documentos/${idInversion}`, {
    method: "GET",
    headers: {
      "Authorization": AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        if(responseData.status==403){
          return {"estado": 403};
        }
        else if(responseData.status==90){
          return {"estado": 90};
        }
        else{
          return {"estado": 500};
        }
      }
      else{
        return responseData;
      }
    })
    .catch(err => {
      return {"estado":500};
  });
  return resp;
};

const postGuardarDocumento = (idInversion, id, documento) => {
  console.log("Documento: ",documento);
  console.log("Ids: ", id);
  const URL = process.env.REACT_APP_URL_API;

  const formData = new FormData(); // Crea un objeto FormData
  formData.append('documento', documento); // Adjunta el archivo al objeto FormData
  
  const resp = fetch(`${URL}inversion/documentos?inversionId=${idInversion}&tipoDocumento=${id}`, {
    method: "POST",
    body: formData,
    headers: {
      "Authorization": AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        if(responseData.status==403){
          return {"estado": 403};
        }
        else if(responseData.status == 90){
          return {"estado": 90};
        }
        else{
          return {"estado": 500};
        }
      }
      else{
        return responseData;
      }
    })
    .catch(err => {
      return {"estado":500};
  });
  return resp;
};


const getImporteMinimo = () => {
  const URL = process.env.REACT_APP_URL_API;

  //console.log(AuthService.getCurrentToken());

  const resp = fetch(`${URL}inversion/importe-minimo`, {
    method: "GET",
    headers: {
      "Authorization": AuthService.getCurrentToken()
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      //console.log("respuesta",responseData);
      if (typeof responseData.datasets == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":200,"importeMinimo":responseData.importeMinimo};
        }
        
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const InversionService = {
  postSimulacion,
  postInversionCurso,
  postCondicionesFinancieras,
  postProspecciones,
  postCrearInversion,
  getImporteMinimo,
  postInversionesActivas,
  postPeriodosInversion,
  postEliminarInversion,
  getListDocumentos,
  postActivarInversion,
  postGuardarDocumento
};

export default InversionService;
