import React, { useState, useEffect, useContext } from "react";
import { ComponentesContext } from "../../contextos/componentesContext";
import AuthService from "../../services/auth.service";
import InversionistaService from "../../services/inversionista.Service";
import { useNavigate } from "react-router-dom";
import { Typography, Divider, Form, Button, Modal, message } from "antd";
import Structure from "../../componentes/Structure";
import MainInversion from "./Main";
import { ArrowRightOutlined, MessageOutlined } from "@ant-design/icons";
import RfcInput from "../../componentes/InputRfc";

const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 16,
  },
};

const Step0 = () => {
  const {
    varsInversion,
    RegistraVars,
    CloseSession,
    ShowLoading,
    ModalMessage,
  } = useContext(ComponentesContext);
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const [offsetButton, setoffsetButton] = useState(4);
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ModalContent, SetModalContent] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [value, setValue] = useState("");

  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    //console.log("USER HOOK", usr);
    setUser(usr);

    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate("/login");
    }

    varsInversion.inversionista.RFC = "";
  }, []);

  const FormValidateMessages = {
    required: "${label} es requerido!",
    types: {
      email: "${label} no es valido!",
      number: "${label} no es un numero valido!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const showModal = (strContent) => {
    SetModalContent(strContent);
    setIsModalOpen(true);
  };

  const handleNext = () => {
    let data = form.getFieldsValue();

    if (data.datos.rfc.length == 0) {
      showModal("El RFC no puede estar en blanco");
    } else {
      if (data.datos.rfc.length < 12) {
        showModal("La longitud del RFC no puede ser menor a 12 digitos");
      } else {
        const reg = /[A-Z]{3}[0-9]{6}[A-Z0-9]{3}/;
        if (reg.test(data.datos.rfc)) {
          getRFCData(data.datos.rfc);
        }
        else{
          showModal("El RFC de 12 dígitos ingresados tiene un formato incorrecto para persona moral, favor de verificar");
        }
        
      }
    }
  };

  const getRFCData = async (rfc) => {
    ShowLoading(true);
    let RFCData = await InversionistaService.postRFC({
      rfc: rfc,
    });
    ShowLoading(false);
    //console.log(RFCData);
    if (RFCData.estado == 403) {
      CloseSession();
      return false;
    }

    if (RFCData.estado == 500) {
      ModalMessage(
        "Mensaje",
        "Se género un error al intentar consultar el RFC."
      );
      return false;
    }

    varsInversion.inversionista.statusRFC = RFCData.estado;
    varsInversion.inversionista.codInv = 0;
    if (RFCData.estado == 0) {
      varsInversion.inversionista.codInv = RFCData.data.inversionista.codInv;
    }
    RegistraVars(varsInversion);

    navigate("/inversion-registro", {
      state: {
        record: {
          rfc: rfc,
          status: RFCData.estado,
          codInv: varsInversion.inversionista.codInv,
        },
      },
    });
  };

  const onFinish = async (values) => {
    //console.log(values);
    //
  };

  const handleWindowResize = () => {
    if (window.innerWidth > 575) {
      setoffsetButton(4);
    } else {
      setoffsetButton(0);
    }
  };

  window.addEventListener("resize", handleWindowResize);

  return (
    <Structure breadcrumb={"Inicio / Inversiones"}>
      <MainInversion step={0}>
        {contextHolder}
        <center>
          <Divider>
            <Typography.Title level={3} style={{ margin: 0, color: "#081b3e" }}>
              Inversionista
            </Typography.Title>
          </Divider>

          <Form
            form={form}
            {...layout}
            name="nest-messages"
            onFinish={onFinish}
            style={{
              maxWidth: 600,
            }}
            validateMessages={FormValidateMessages}
          >
            <Form.Item
              name={["datos", "rfc"]}
              label="RFC:"
              rules={[
                {
                  require: true,
                },
              ]}
            >
              <RfcInput
                value={varsInversion.inversionista.RFC}
                onChange={setValue}
                onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
              />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
                offset: offsetButton,
              }}
            >
              <Button
                style={{ width: "100%" }}
                type="primary"
                onClick={handleNext}
              >
                Continuar <ArrowRightOutlined />
              </Button>
            </Form.Item>
          </Form>
        </center>
        <Modal
          title={
            <div>
              <MessageOutlined /> &nbsp; Mensaje
            </div>
          }
          open={isModalOpen}
          onCancel={handleModalClose}
          footer={[
            <Button type="primary" onClick={handleModalClose}>
              OK
            </Button>,
          ]}
        >
          <Typography.Title
            level={5}
            style={{ margin: 20, color: "#081b3e", marginBottom: -10 }}
          >
            {ModalContent}
          </Typography.Title>
        </Modal>
      </MainInversion>
    </Structure>
  );
};
export default Step0;
