import React, { useState, useEffect, useRef } from "react";
import AuthService from "../../services/auth.service";
import UsuarioService from "../../services/usuarios.Service";
import { useNavigate } from "react-router-dom";
import Highlighter from "react-highlight-words";
import Structure from "../../componentes/Structure";

import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";

import {
  Button,
  Input,
  Layout,
  FloatButton,
  Space,
  Table,
  Popconfirm,
  Breadcrumb,
  theme,
} from "antd";
import HeaderCustom from "../../componentes/HeaderCustom";
import { Content, Footer } from "antd/es/layout/layout";

const { Column } = Table;

const User = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");

  const handleCancel = () => {
    console.log("Clicked cancel button");
  };

  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    console.log("USER HOOK", usr);

    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate("/login");
    }

    setUserListData();
  }, []);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const action = (dataIndex) => ({
    return: (
      <Space size="middle">
        <a>Invite {dataIndex}</a>
        <a>Delete</a>
      </Space>
    ),
  });
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Limpiar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const setUserListData = async () => {
    let respuesta = await UsuarioService.queryStatement(
      {
        id: "1",
      },
      "returnuserlist"
    );

    setDataSource(respuesta.usuario);
  };

  const [dataSource, setDataSource] = useState([
    {
      id: "1",
      nombre_completo: "Luis Alberto Rodriguez Beltran",
      fechaNacimiento: "2010-01-01",
      correo: "luis@finamo.mx",
    },
  ]);

  const columns = [
    {
      title: "Usuarios",
      dataIndex: "nombre_completo",
      key: "nombre_completo",
      width: "30%",
      ...getColumnSearchProps("nombre_completo"),
    },
    {
      title: "Fecha nacimiento",
      dataIndex: "fecha_nac",
      key: "fecha_nac",
      width: "20%",
      ...getColumnSearchProps("fecha_nac"),
    },
    {
      title: "Correo",
      dataIndex: "correo",
      key: "correo",
      ...getColumnSearchProps("correo"),
    },
    {
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => handleEdit(record)}>
            <EditOutlined />
          </Button>
          <Popconfirm
            title="Borrar Empleado"
            description={`Deseas borrar al empeado ${record.nombre_completo} ?`}
            onConfirm={() => handleDelete(record.id)}
            onCancel={handleCancel}
          >
            <Button>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleDelete = (key) => {
    console.log("DROP ITEM::", key);
    const newData = dataSource.filter((item) => item.id !== key);

    setDataSource(newData);
  };

  const handleEdit = (record) => {
    console.log("Clicked edit button", record);
    navigate("/conf-usuarios-update", { state: { record: record } });
  };

  const handleReturn = () => {
    navigate("/home");
  };

  const aggUsuario = () => {
    navigate("/conf-usuarios-update", { state: { record: { id: 0 } } });
  };

  return (
    <Structure breadcrumb={"Configuración / Usuarios"}>
      <Table columns={columns} dataSource={dataSource} pagination={false} />

      <FloatButton
        tooltip={<div>Agregar Empleado</div>}
        icon={<PlusOutlined />}
        type="primary"
        style={{
          right: 24,
        }}
        onClick={aggUsuario}
      />

      <FloatButton
        tooltip={<div>Regresar</div>}
        icon={<ArrowLeftOutlined />}
        type="primary"
        style={{
          right: 80,
        }}
        onClick={handleReturn}
        
        
      />
    </Structure>
  );
};
export default User;
