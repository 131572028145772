import React,{useState,useEffect,useRef} from 'react';
import AuthService from '../../services/auth.service';
import { useNavigate } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import Structure from '../../componentes/Structure';

import {
  DeleteOutlined,EditOutlined ,PlusOutlined ,SearchOutlined
} from '@ant-design/icons';

import { 
    Button,  
    Input,
    Layout,FloatButton,Space, Table, Popconfirm,Breadcrumb,theme} from 'antd';
import HeaderCustom from '../../componentes/HeaderCustom';
import { Content,Footer } from 'antd/es/layout/layout'; 

const { Column } = Table;



const User = () => {
  
    const navigate = useNavigate();
    const [name,setName] = useState('');
   
  
    const handleEdit = (id) => {
      console.log('Clicked edit button',id);
      navigate('/conf-usuarios-update',{state:{id:id}});
     
    };
    const handleCancel = () => {
      console.log('Clicked cancel button');
     
    };

    useEffect(()=>{
      let usr =  AuthService.getCurrentUser() ;
      console.log("USER HOOK", usr);
      
      if( usr === null || usr === undefined){
        AuthService.logout();
        navigate("/login");
  
      }
    },[]);
      
        
      const [searchText, setSearchText] = useState('');
      const [searchedColumn, setSearchedColumn] = useState('');
      const searchInput = useRef(null);
      const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
      };
      const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
      };

      const action = (dataIndex) =>({
        
        return: <Space size="middle">
            <a>Invite {dataIndex}</a>
            <a>Delete</a>
          </Space>
        
         
      });
      const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
          <div
            style={{
              padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <Input
              ref={searchInput}
              placeholder={`Buscar ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{
                marginBottom: 8,
                display: 'block',
              }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{
                  width: 90,
                }}
              >
                Buscar
              </Button>
              <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{
                  width: 90,
                }}
              >
                Limpiar
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({
                    closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
                }}
              >
                Filtar
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  close();
                }}
              >
                Cerrar
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{
              color: filtered ? '#1890ff' : undefined,
            }}
          />
        ),
        onFilter: (value, record) =>
          record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
          ,
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
          }
        },
        render: (text) =>
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{
                backgroundColor: '#ffc069',
                padding: 0,
              }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
    

      const [dataSource,setDataSource] = useState([
        {
          key: '1',
          name: 'John Brown',
          age: 32,
          address: 'New York No. 1 Lake Park',
        },
        {
          key: '2',
          name: 'Joe Black',
          age: 42,
          address: 'London No. 1 Lake Park',
        },
        {
          key: '3',
          name: 'Jim Green',
          age: 32,
          address: 'Sidney No. 1 Lake Park',
        },
        {
          key: '4',
          name: 'Jim Red',
          age: 32,
          address: 'London No. 2 Lake Park',
        },
        {
          key: '5',
          name: 'John Brown',
          age: 32,
          address: 'New York No. 1 Lake Park',
        },
        {
          key: '6',
          name: 'Joe Black',
          age: 42,
          address: 'London No. 1 Lake Park',
        },
        {
          key: '7',
          name: 'Jim Green',
          age: 32,
          address: 'Sidney No. 1 Lake Park',
        },
        {
          key: '8',
          name: 'Jim Red',
          age: 32,
          address: 'London No. 2 Lake Park',
        },
        {
          key: '9',
          name: 'John Brown',
          age: 32,
          address: 'New York No. 1 Lake Park',
        },
        {
          key: '10',
          name: 'Joe Black',
          age: 42,
          address: 'London No. 1 Lake Park',
        },
        {
          key: '11',
          name: 'Jim Green',
          age: 32,
          address: 'Sidney No. 1 Lake Park',
        },
        {
          key: '12',
          name: 'Jim Red',
          age: 32,
          address: 'London No. 2 Lake Park',
        },
        {
          key: '13',
          name: 'John Brown',
          age: 32,
          address: 'New York No. 1 Lake Park',
        },
        {
          key: '14',
          name: 'Joe Black',
          age: 42,
          address: 'London No. 1 Lake Park',
        },
        {
          key: '15',
          name: 'Jim Green',
          age: 32,
          address: 'Sidney No. 1 Lake Park',
        },
        {
          key: '16',
          name: 'Jim Red',
          age: 32,
          address: 'London No. 2 Lake Park',
        },
        {
          key: '17',
          name: 'John Brown',
          age: 32,
          address: 'New York No. 1 Lake Park',
        },
        {
          key: '18',
          name: 'Joe Black',
          age: 42,
          address: 'London No. 1 Lake Park',
        },
        {
          key: '19',
          name: 'Jim Green',
          age: 32,
          address: 'Sidney No. 1 Lake Park',
        },
        {
          key: '20',
          name: 'Jim Red',
          age: 32,
          address: 'London No. 2 Lake Park',
        },
      ]);
      

   

      const columns = [
        {
          title: 'Empleados',
          dataIndex: 'name',
          key: 'name',
          width: '30%',
          ...getColumnSearchProps('name'),
        },
        {
          title: 'Edad',
          dataIndex: 'age',
          key: 'age',
          width: '20%',
          ...getColumnSearchProps('age'),
        },
        {
          title: 'Domicilio',
          dataIndex: 'address',
          key: 'address',
          ...getColumnSearchProps('address'),
        },
        {

          key: 'action',
          render: (_, record) => (
            <Space size="middle">
              <Button  onClick={()=>handleEdit(record.key)} ><EditOutlined /></Button>
              <Popconfirm
                title="Borrar Empleado"
                description={`Deseas borrar al empeado ${record.name} ?`}
                onConfirm={() => handleDelete(record.key)}
                onCancel={handleCancel}
              >
                  <Button><DeleteOutlined/></Button>

              </Popconfirm>
              
          
            </Space>
          ),
        }
      ];

      const handleDelete = (key) => {

        console.log("DROP ITEM::",key)
        const newData = dataSource.filter((item) => item.key !== key);
       setDataSource(newData);

      };


      const aggEmpleado = () => {

        navigate("/user",{state:{id:0}});

      };
      


    return(
      <Structure breadcrumb={"Recursos Humanos / Empleados"}>
                <Table columns={columns} dataSource={dataSource}  pagination={false} />
                <FloatButton
                  tooltip={<div>Agregar Empleado</div>}
                    icon={<PlusOutlined  />}
                    type='primary'
                    style={{
                      right: 24,
                    }}
                    onClick={aggEmpleado}
                  />
      </Structure>
            
    )
};
export default User;