import React, { useState, useEffect } from "react";
import AuthService from "../../services/auth.service";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import DatePickerCustom from "../../componentes/DatePickerCustom";
import Structure from "../../componentes/Structure";
import { HomeOutlined } from "@ant-design/icons";

import { Button, Input, FloatButton, Form, Space, theme } from "antd";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} es requerido!",
  types: {
    email: "${label} no es valido!",
    number: "${label} no es un numero valido!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
/* eslint-enable no-template-curly-in-string */

const UserCRUD = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [id] = useState(location.state.id != null ? location.state.id : 0);

  const [fechaNac, setFechaNac] = useState({ value: "" });

  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    console.log("USER HOOK", usr);

    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate("/login");
    }
  }, []);

  const onFinish = (values) => {
    console.log(values);
    console.log(fechaNac);
  };

  const onChange = (date, dateString) => {
    setFechaNac(dateString);
  };

  return (
    <Structure breadcrumb={"Recursos Humanos / Empleados / "}>
      <Form
        {...layout}
        name="nest-messages"
        onFinish={onFinish}
        style={{
          maxWidth: 600,
        }}
        validateMessages={validateMessages}
      >
        <Form.Item
          name={["user", "primer_nombre"]}
          label="Primer Nombre"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={["user", "segundo_nombre"]}
          label="Segundo Nombre"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={["user", "apellido_paterno"]}
          label="Apellido Paterno"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={["user", "apellido_materno"]}
          label="Apellido Materno"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={["user", "email"]}
          label="Email"
          rules={[
            {
              type: "email",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={["user", "fecha_nacimiento"]}
          label="Fecha de Nacimiento"
          rules={[
            {
              type: "date",
            },
          ]}
        >
          <DatePickerCustom onChange={onChange} />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            ...layout.wrapperCol,
            offset: 8,
          }}
        >
          <Space size={20}>
            <Button type="primary" htmlType="submit">
              Guardar
            </Button>

            <Button type="primary" htmlType="reset">
              Cancelar
            </Button>
          </Space>
        </Form.Item>
      </Form>

      <FloatButton
        icon={<HomeOutlined />}
        type="primary"
        style={{
          right: 24,
        }}
        onClick={() => navigate("/home")}
      />
    </Structure>
  );
};
export default UserCRUD;
