import React, { useEffect, useState } from "react";
import {
  TeamOutlined,
  HomeOutlined,
  SettingOutlined,
  BankOutlined,
  UserAddOutlined,
  ProfileOutlined,
  ContainerOutlined
} from "@ant-design/icons";

import { useNavigate } from "react-router-dom";


import { Layout, Menu, Typography } from "antd";
import Logo from "./Logo_tiny";
const { Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

export default function MenuCustom() {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const { Title } = Typography;

  const handleNext = (option) => {
    navigate(option);
  };
  
  const itemsMenu = [
    getItem(<a onClick={() => handleNext("/")}>Inicio</a>, "1", <HomeOutlined />),
    getItem("Configuración", "sub1", <SettingOutlined />, [
      // getItem(<a onClick={() => handleNext("/conf-usuarios")}>Usuarios</a>, "2", <TeamOutlined />),
      // getItem(<a onClick={() => handleNext("/actualiza-tasas")}>Actualiza tasas</a>, "5", <HistoryOutlined />),
    ]),
    getItem("Inversiones", "sub2", <BankOutlined />, [
      // getItem(<a onClick={() => handleNext("/simulador-inicio")} >Simulación</a>, "3", <FileProtectOutlined />),
      getItem(<a onClick={() => handleNext("/inversion-inicio")}>Alta de inversión</a>, "4", <UserAddOutlined />),
      getItem(<a onClick={() => handleNext("/prospecciones")}>Lista de prospecciones</a>, "5", <ProfileOutlined />),

      getItem(<a onClick={() => handleNext("/generar-solicitudes")}>Solicitudes de factura</a>, "6", <ProfileOutlined />),
      
      getItem(<a onClick={() => handleNext("/estado-cuenta")}>Estado de cuenta</a>, "7", <ContainerOutlined />),
      getItem(<a onClick={() => handleNext("/inversiones-activa")}>Inversiones Activas</a>, "8", <ProfileOutlined />),
    ]),
  ];

  return (
    <Menu style={{with:1000, fontSize:17, color: "white" }} theme="dark" mode="horizontal" items={itemsMenu} />
  );
}
