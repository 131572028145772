import React, {
  useState,
  useEffect,
  useRef,
  Component,
  useContext,
} from "react";
import AuthService from "../../services/auth.service";
import ReportesService from "../../services/reportes.Service";
import { ComponentesContext } from "../../contextos/componentesContext";
import { useNavigate, useLocation } from "react-router-dom";
import returnValidUploadFileClass from "../../clases/returnValidUploadFile.class";
import InversionService from "../../services/inversion.Service";


import JSZip from 'jszip';
import { saveAs } from 'file-saver';

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import {
  Card,
  Typography,
  Divider,
  List,
  Button,
  Modal,
  Collapse,
  Row,
  Col,
  Empty,
  Upload
} from "antd";
import Structure from "../../componentes/Structure";
import MainInversion from "./Main";

import {
  FilePdfOutlined,
  ArrowLeftOutlined,
  DownloadOutlined,
  UploadOutlined,
  FileDoneOutlined,
  QuestionCircleOutlined,
  FileTextOutlined,
  PlusOutlined,
  CheckCircleOutlined
} from "@ant-design/icons";

const { Meta } = Card;

const Step4 = () => {
  const { CloseSession, ShowLoading, ModalMessage } =
    useContext(ComponentesContext);
  const { Panel } = Collapse;
  const location = useLocation();
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenGenerar, setisModalOpenGenerar] = useState(false);
  const [urlDocument, seturlDocument] = useState("");
  const [modalTitle, setmodalTitle] = useState("");
  const [dataDocumentos, setdataDocumentos] = useState([]);

  const [taskCompleted1, setTaskCompleted1] = useState(false);
  const [taskCompleted2, setTaskCompleted2] = useState(false);
  const [taskCompleted3, setTaskCompleted3] = useState(false);

  const [canRemove, setcanRemove] = useState(true);
  const [OpenModelDocument, setOpenModelDocument] = useState(false);
  const [loadingModal, setloadingModal] = useState(false);
  const [ModelAttachementType, setModelAttachementType] = useState(1);
  const [pathDocument, setpathDocument] = useState([]);

  const [RFC, SetRFC] = useState(
    typeof location.state.record.rfc != "undefined"
      ? location.state.record.rfc != null
        ? location.state.record.rfc
        : ""
      : ""
  );
  const [statusRFC, SetstatusRFC] = useState(
    typeof location.state.record.status != "undefined"
      ? location.state.record.status != null
        ? location.state.record.status
        : ""
      : ""
  );

  const [codInv, SetcodInv] = useState(
    typeof location.state.record.codInv != "undefined"
      ? location.state.record.codInv != null
        ? location.state.record.codInv
        : ""
      : ""
  );

  const [idInversion, SetidInversion] = useState(
    typeof location.state.record.idInversion != "undefined"
      ? location.state.record.idInversion != null
        ? location.state.record.idInversion
        : ""
      : ""
  );

  const pdfVersion = "2.16.105";
  const pdfWorkerUrl = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfVersion}/pdf.worker.js`;

  const getReportes = async () => {
    ShowLoading(true);
    let ReportesData = await ReportesService.postRegresaReportes({
      codInversionista: codInv,
      codInversion: idInversion,
    });
    ShowLoading(false);
    //console.log(ReportesData);
    if (ReportesData.estado == 403) {
      CloseSession();
      return false;
    }

    if (ReportesData.estado == 500) {
      ModalMessage(
        "Mensaje",
        "Se género un error al intentar generar los reportes."
      );
      return false;
    }

    //console.log(ReportesData.reportes);

    const listaReportes = ReportesData.reportes;
    let contador = 0;
    const dataDocumentos = listaReportes.map((reporte) => (
      <CreateDocument
        id={contador++}
        title={reporte.descripcion}
        url={reporte.path}
      />
    ));

    const nuevoElemento = (
      <div id={99} style={{ marginLeft: 30 }}>
        <table>
          <tr>
            <td width={500}>Descargar todos los documentos</td>
            <td>
              <Button
                style={{ width: 120, marginLeft: 50 }}
                type="primary"
                onClick={() => handleDescargarDocumentos(ReportesData.reportes)}
                icon={<DownloadOutlined />}
              >
                Descargar
              </Button>
            </td>
          </tr>
        </table>
      </div>
    );

    const dataDocumentosConNuevoElemento = dataDocumentos.concat(nuevoElemento);    

    setdataDocumentos(dataDocumentosConNuevoElemento);
  };

  const handleDescargarDocumentos = (listaUrl) => {
    const zip = new JSZip();

    //console.log(listaUrl);

    const promises = listaUrl.map(async (lista, index) => {
      //console.log(lista.path);
      const response = await fetch(lista.path);
      const blob = await response.blob();
      zip.file(`archivo${index + 1}.pdf`, blob);
    });

    Promise.all(promises).then(() => {
      zip.generateAsync({ type: 'blob' }).then(content => {
        saveAs(content, 'archivos.zip');
      });
    });

  }

  const handleBack = () => {
    navigate("/inversion-banco", {
      state: {
        record: {
          rfc: RFC,
          status: statusRFC,
          codInv: codInv,
          idInversion: idInversion,
        },
      },
    });
  };

  const handleNext = () => {
    navigate("/prospecciones");
  };

  function CreateDocument(props) {
    return (
      <div id={props.id} style={{ marginLeft: 30 }}>
        <table>
          <tr>
            <td width={500}>{props.title}</td>
            <td>
              <Button
                style={{ width: 120, marginLeft: 50 }}
                type="primary"
                onClick={() => ShowModalDocument(props.url, props.title)}
                icon={<FilePdfOutlined />}
              >
                Ver
              </Button>
            </td>
          </tr>
        </table>
      </div>
    );
  }


  const handlePreview = async (file) => {

    const fileExtension = file.name.split(".").pop();

    switch (fileExtension.toLowerCase() ) {
      case "pdf":
        setpathDocument(file.url);
        setOpenModelDocument(true);
        break;
    }
  };

  const handleRemove = async (file) => {
    setloadingModal(true);
    // let ticketsData = await TicketsService.postDeleteFileDetailTicket({
    //   nombre_archivo: file.name,
    //   id_ticket: IdTicket,
    // });
  };

  const handleChange = async ({ fileList: newFileList }) => {
    
    setTimeout(async function () {
      // let DetalleAdjuntosData = await TicketsService.postAdjuntosTicket({
      //   id_ticket: IdTicket,
      //   tipo: 1,
      //   id_ticket_detalle: 0,
      //   id_base_conocimiento: 0,
      // });

      //setFileList(DetalleAdjuntosData.data);
      setloadingModal(false);  
    }, 6000);

  };

  const handleBeforeUpload = async (file) =>{
    let flagArchivoValido= await returnValidUploadFileClass.returnPdfValidUploadFile(file.type);

    //console.log(file.type);
    if(flagArchivoValido==false){
      ModalMessage("Mensaje","El tipo de archivo que se desea adjuntar no es valido." );
      return false;
    }

    const fileSizeLimit = 10 * 1024 * 1024; // 5MB

    if (file.size > fileSizeLimit) {
      ModalMessage("Mensaje","El archivo no puede pesar mas de 10MB." );
      return false; // Cancela la carga del archivo
    }


    setloadingModal(true);
    return true;
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 0,
        }}
      >
        Adjuntar
      </div>
    </div>
  );

  const SuccessButton = (
    <div>
      <CheckCircleOutlined style={{ color: 'green' }}/>
      <div
        style={{
          marginTop: 0,
        }}
      >
        Completado
      </div>
    </div>
  );

  const handleUploadClick = (id) => {
    // Aquí puedes acceder al ID de la tabla (en este caso, 'id') y realizar acciones con él
    console.log(`ID de la tabla: ${id}`);

    // Luego, puedes ejecutar la lógica de subida de archivos
    // ...

    // Si deseas, puedes invocar la función 'guardarDocumento' pasando el ID como argumento
    // guardarDocumento(id);
  };

  const ObtenerListDocumentos = async () => {
    const respDocs = await InversionService.getListDocumentos(idInversion);
    const { data } = respDocs;
    for (const element of data.documentoList){
      switch (element.tipoDocumento) {
        case 1:
          setTaskCompleted1(true);
          break;
        case 2:
          setTaskCompleted2(true);
          break;
        case 3:
          setTaskCompleted3(true);
          break;
      }
    }
  }

  const dataUpload = [
    <UploadDocument
      key={1}
      id={1}
      title={"Reporte de simulación tabla de amortización"}
    />,
    <UploadDocument2
      key={2}
      id={2}
      title={"Contrato"}
    />,
    <UploadDocument3
      key={3}
      id={3}
      title={"Pagaré"}
    />,
  ];

  function UploadDocument(props) {
    const { id, title } = props;

    const guardarDocumento = async (file) => {
      ShowLoading(true);
      let resActivar = await InversionService.postGuardarDocumento(idInversion, id, file);
  
      if (resActivar.status == 403) {
        
        CloseSession();
        return false;
      }
  
      if (resActivar.estado == 500) {
        ModalMessage("Mensaje", "Se produjo un error al guardar el documento.");
        return false;
      }

      setTaskCompleted1(true);
      ShowLoading(false);
      ModalMessage(
        "Mensaje",
        "Documento Guardado Correctamente."
      );
    };

    return (
      <div id={ id } style={{ marginLeft: 0 }}>
        <table border={0} id={`tabDoc${id}`}>
          <tr>
            <td width={200}>{ title }</td>
            <td style={{textAlign:"center"}}>
              <div style={{ backgroundColor: "#f5f5f5", borderRadius: 10, textAlign:"center", paddingLeft:7 }}>

                <Upload
                  name="archivo"
                  listType="picture-card"
                  showUploadList={{
                    showRemoveIcon: canRemove,
                  }}
                  action={guardarDocumento}
                >
                  {taskCompleted1 == true ? SuccessButton : uploadButton}
                </Upload>
              </div>
            </td>
          </tr>
        </table>
      </div>
    );
  }

  function UploadDocument2(props) {

    const { id, title } = props;

    const guardarDocumento = async (file) => {
      ShowLoading(true);
      let resActivar = await InversionService.postGuardarDocumento(idInversion, id, file);
  
      if (resActivar.status == 403) {
        CloseSession();
        return false;
      }
  
      if (resActivar.estado == 500) {
        ModalMessage("Mensaje", "Se produjo un error al guardar el documento.");
        return false;
      }

      setTaskCompleted2(true);
      ShowLoading(false);
      ModalMessage(
        "Mensaje",
        "Documento Guardado Correctamente."
      );
    };

    return (
      <div id={ id } style={{ marginLeft: 0 }}>
        <table border={0} id={`tabDoc${id}`}>
          <tr>
            <td width={200}>{ title }</td>
            <td style={{textAlign:"center"}}>
              <div style={{ backgroundColor: "#f5f5f5", borderRadius: 10, textAlign:"center", paddingLeft:7 }}>

                <Upload
                  name="archivo"
                  listType="picture-card"
                  showUploadList={{
                    showRemoveIcon: canRemove,
                  }}
                  action={guardarDocumento}
                >
                  {taskCompleted2 == true ? SuccessButton : uploadButton}
                </Upload>
              </div>
            </td>
          </tr>
        </table>
      </div>
    );
  }

  function UploadDocument3(props) {

    const { id, title } = props;

    const guardarDocumento = async (file) => {
      ShowLoading(true);
      let resActivar = await InversionService.postGuardarDocumento(idInversion, id, file);
  
      if (resActivar.status == 403) {
        CloseSession();
        return false;
      }
  
      if (resActivar.estado == 500) {
        ModalMessage("Mensaje", "Se produjo un error al guardar el documento.");
        return false;
      }

      setTaskCompleted3(true);
      ShowLoading(false);
      ModalMessage(
        "Mensaje",
        "Documento Guardado Correctamente."
      );
    };

    return (
      <div id={ id } style={{ marginLeft: 0 }}>
        <table border={0} id={`tabDoc${id}`}>
          <tr>
            <td width={200}>{ title }</td>
            <td style={{textAlign:"center"}}>
              <div style={{ backgroundColor: "#f5f5f5", borderRadius: 10, textAlign:"center", paddingLeft:7 }}>

                <Upload
                  name="archivo"
                  listType="picture-card"
                  showUploadList={{
                    showRemoveIcon: canRemove,
                  }}
                  action={guardarDocumento}
                >
                  {taskCompleted3 == true ? SuccessButton : uploadButton}
                </Upload>
              </div>
            </td>
          </tr>
        </table>
      </div>
    );
  }

  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    //console.log("USER HOOK", usr);
    setUser(usr);

    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate("/login");
    }

    //getReportes();
    setisModalOpenGenerar(true);
    ObtenerListDocumentos();
  }, []);

  const ShowModalDocument = (url, title) => {
    seturlDocument(url);
    setmodalTitle(title);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalGenerarClose = () => {
    setisModalOpenGenerar(false);
  };

  const HandleActivar = async () => {
    getReportes();

    setisModalOpenGenerar(false);
  };

  const handleGenerarReportes = async () => {
    getReportes();
  };


  return (
    <Structure breadcrumb={"Inicio / Inversiones"}>
      <MainInversion step={4}>
        <center>
          <Divider>
            <Typography.Title level={2} style={{ margin: 0, color: "#081b3e" }}>
              Formalización
            </Typography.Title>
          </Divider>

          <Collapse bordered defaultActiveKey={["0"]} style={{ width: "60%" }}>
            <Collapse.Panel
              header={
                <Typography.Title
                  level={4}
                  style={{ margin: 0, color: "#081b3e", marginBottom: -10 }}
                >
                  <DownloadOutlined /> Descargar documentos
                </Typography.Title>
              }
              key="1"
            >
              <div style={{ height: "100%" }}>
                {" "}
                {/* Establecer altura del contenedor */}
                {dataDocumentos.length > 0 ? (
                  <List
                    bordered
                    style={{ width: "100%" }}
                    dataSource={dataDocumentos}
                    renderItem={(item) => (
                      <List.Item>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                          {item}
                        </Typography.Title>
                      </List.Item>
                    )}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%", // Establecer altura del contenedor para centrar Empty
                    }}
                  >
                    <Empty
                      description={
                        <Button
                          style={{ width: 200 }}
                          type="primary"
                          onClick={() => handleGenerarReportes()}
                          //htmlType="submit"
                        >
                          <FileTextOutlined /> Generar documentos
                        </Button>
                      }
                    />
                  </div>
                )}
              </div>
            </Collapse.Panel>
          </Collapse>

          <Collapse
            bordered={true}
            defaultActiveKey={["0"]}
            style={{
              width: "60%",
              
            }}
          >
            <Panel
              header={
                <>
                  <Typography.Title
                    level={4}
                    style={{
                      margin: 0,
                      color: "#081b3e",
                      marginBottom: -10
                    }}
                  >
                    <UploadOutlined /> Subir documentos
                  </Typography.Title>
                </>
              }
              key="1"
            >
              <List
                
                style={{ width: "100%", textAlign: "center", margin: "0 auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center", }}
                dataSource={dataUpload}
                renderItem={(item) => (
                  
                  <List.Item>
                    
                    <Typography.Title level={5} style={{ margin: 0 }}>
                      {item}
                    </Typography.Title>
                    
                  </List.Item>
                  
                )}
              />
            </Panel>
          </Collapse>
          {/*  */}

          <div style={{ width: "50%", marginTop: 20, paddingLeft: 20 }}>
            {/* <Button
                style={{ width: 320 }}
                type="primary"
                htmlType="submit"
                icon={<ArrowRightOutlined />}
              >
                Continuar
              </Button> */}
            <Row style={{ marginTop: 10, paddingLeft: 30 }}>
              <Col span={11}>
                <Button
                  style={{ width: 200 }}
                  type="primary"
                  onClick={() => handleBack()}
                  icon={<ArrowLeftOutlined />}
                >
                  Atras
                </Button>
              </Col>
              <Col>
                <Button
                  style={{ width: 200, marginBottom: 10 }}
                  type="primary"
                  onClick={() => handleNext()}
                  //htmlType="submit"
                >
                  <FileDoneOutlined /> Salir
                </Button>
              </Col>
            </Row>
          </div>
        </center>

        <Modal
          destroyOnClose={true}
          title={
            <div>
              <FilePdfOutlined /> &nbsp; {modalTitle}
            </div>
          }
          style={{ top: 80 }}
          open={isModalOpen}
          onCancel={handleModalClose}
          closable={false}
          width={"90%"}
          footer={[
            <Button type="primary" onClick={handleModalClose}>
              OK
            </Button>,
          ]}
        >
          <object
            type="application/pdf"
            data={urlDocument}
            width="100%"
            height={window.innerHeight - 250}
            style={{ borderRadius: 6 }}
          ></object>
        </Modal>

        <Modal
          title={
            <div>
              <QuestionCircleOutlined /> &nbsp; Confirmación
            </div>
          }
          open={isModalOpenGenerar}
          onCancel={handleModalGenerarClose}
          footer={[
            <Button type="primary" onClick={HandleActivar}>
              Si
            </Button>,
            <Button type="primary" onClick={handleModalGenerarClose}>
            No
          </Button>
          ]}
        >
          <Typography.Title
            level={5}
            style={{ margin: 20, color: "#081b3e", marginBottom: -10 }}
          >
            Deseas generar los documentos para el inversionista?
          </Typography.Title>
        </Modal>
      </MainInversion>
    </Structure>
  );
};
export default Step4;
