import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
  useContext
} from "react";
import { Typography, Spin, message, notification  } from "antd";
import moment from "moment";
import LocalData from "../persistencia/LocalData";
import AuthService from "../services/auth.service";
import { ComponentesContext } from "../contextos/componentesContext";
import { Modal } from "antd";
import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  HistoryOutlined,
} from "@ant-design/icons";

const SessionTimeout = () => {
  const [events, setEvents] = useState(['click','scroll']);
  const [second, setSecond] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [isAuthenticated, setisAuthenticated] = useState(false);
  const [EnableTimer, setEnableTimer] = useState(true);
  const { confirm } = Modal;
  const { CloseSession, WarningSession } = useContext(ComponentesContext);
  

  // const {
  //   authState: { isAuthenticated },
  //   logout,
  // } = useAuth();

  let timeStamp;
  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();

  const openNotification = (minutes) => {
    notification.open({
      message: 'Inactividad',
      description:(
        <Typography.Title level={3} style={{ margin: 0, color: "#081b3e" }}>
          <HistoryOutlined style={{ fontSize: 30, color: "#f9dc06" }} /> Sigues
          ahi?? si no tu sessión se cerrará en{" "}
          {minutes == 1 ? minutes + " minuto" : minutes + " minutos"}.
        </Typography.Title>
      ),
      className: 'custom-class',
      style: {
        width: 600,
      },
      duration:4
    });
  };

  let timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp = sessionStorage.getItem('lastTimeStamp');
      warningInactive(storedTimeStamp);
    }, 60000);
  };
  

  // warning timer
  let warningInactive = (timeString) => {
    clearTimeout(startTimerInterval.current);

    warningInactiveInterval.current = setInterval(async() => {

      const maxTime = 15;
      //const popTime = 9;

      const diff = moment.duration(moment().diff(moment(timeString)));
      const minPast = diff.minutes();
      const leftSecond = 60 - diff.seconds();

      if (minPast === 12) {
        setSecond(leftSecond);
        setOpen(true);
        //WarningSession(2);
        openNotification(3);
        clearInterval(warningInactiveInterval.current);
      }

      if (minPast === 13) {
        setSecond(leftSecond);
        setOpen(true);
        //WarningSession(1);
        openNotification(2);
        clearInterval(warningInactiveInterval.current);
      }

      if (minPast === 14) {
        setSecond(leftSecond);
        setOpen(true);
        //WarningSession(1);
        openNotification(1);
        clearInterval(warningInactiveInterval.current);
      }
      //console.log(moment().format());
      //console.log(moment(timeString).format());

      //console.log("minutos faltantes", minPast);
      //console.log("segundos faltantes", leftSecond);

      if(isNaN(minPast)){
        if(await AuthService.getIsAuthenticated()){
            timeStamp = moment();
            sessionStorage.setItem("lastTimeStamp", timeStamp);
            clearInterval(warningInactiveInterval.current);
            timeChecker();
        }
      }

      //console.log(minPast);
      //console.log(leftSecond);

      if (minPast == maxTime) {
        clearInterval(warningInactiveInterval.current);
        setOpen(false);
        sessionStorage.removeItem('lastTimeStamp');
        CloseSession();
        //logout();
      }
    }, 1000);
  };

  // reset interval timer
  let resetTimer =   useCallback(async()  =>  {
    
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);

    //console.log("entro reset");
    
    if (await AuthService.getIsAuthenticated()) {
      //console.log("entro autenticado");
      timeStamp = moment();
      sessionStorage.setItem("lastTimeStamp", timeStamp);
    } else {
      //console.log("entro no autenticado");
      clearInterval(warningInactiveInterval.current);
      sessionStorage.removeItem("lastTimeStamp");
    }
    timeChecker();
    setOpen(false);
  }, [isAuthenticated]);

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    timeChecker();

    return () => {
      clearTimeout(startTimerInterval.current);
      //resetTimer();
    };
  }, [resetTimer, events, timeChecker]);

  //console.log(second);

  if (!isOpen) {
    return null;
  }

  // change fragment to modal and handleclose func to close
  return <Fragment />;
};

export default SessionTimeout;
