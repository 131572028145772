import {  Input,Alert } from "antd";
import React, { useState} from "react";


export default function RfcInput(props) {
  const { value, onChange } = props;
  const [AlertVisible, setAlertVisible] = useState(false);
  
  const handleChange = (e) => {
    const reg =
      /^[A-Za-zñÑ&]{1,2}([A-Za-zñÑ&]([A-Za-zñÑ&](\d(\d(\d(\d(\d(\d(\w(\w(\w)?)?)?)?)?)?)?)?)?)?)?$/;

    if (reg.test(e.target.value)  || e.target.value === '') {
      onChange(e.target.value);
      setAlertVisible(false);
    }
    else{
      if(e.target.value.length<12 ){
        setAlertVisible(true);
      }
    }

    if(e.target.value.length==1 ){
      if(!isNaN(e.target.value)){
        onChange("");
      }
    }
  };

  return (
    <>
      <Input
        {...props}
        onChange={handleChange}
        placeholder="Ingrese un rfc valido"
        maxLength={14}
      />
      <div style={{display: AlertVisible ? "block" : "none"}}>
        <Alert message={<div style={{textAlign:"left"}}>Carácter inválido</div>} type="error" showIcon />
      </div>
    </>
  );
}
