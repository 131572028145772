import React from "react";
import Login from "./pages/auth/Login";
import Welcome from "./pages/Welcome";
import ConcentradorPagos from "./pages/ConcentradorPagos";
import UserChangePassword from "./pages/auth/UserChangePassword";
import Users from "./pages/empleados/Users";
import Rostro from "./pages/util/Rostro";
import UserCRUD from "./pages/empleados/UserCRUD";
import ReporteFactoraje from "./pages/ReporteFactoraje";
import Usuarios from "./pages/usuarios/Usuarios";
import UsuariosUpdate from "./pages/usuarios/UsuariosForm";

import InversionPaso0 from "./pages/inversiones/Step0";
import InversionPaso1 from "./pages/inversiones/Step1";
import InversionPaso2 from "./pages/inversiones/Step2";
import InversionPaso3 from "./pages/inversiones/Step3";
import InversionPaso4 from "./pages/inversiones/Step4";

import SimuladorPaso0 from "./pages/simulador/Step0";
import SimuladorPaso1 from "./pages/simulador/Step1";

import ActualizaTasas from "./pages/tasas/ActualizaTasas";


import Prospecciones from "./pages/inversiones/Prospecciones";
import InversionesActiva from "./pages/inversiones/InversionActiva"


import GenerarSolicitudes from "./pages/inversiones/GenerarSolicitudFactura";
import Solicitudes from "./pages/inversiones/SolicitudFactura";

import EstadoCuenta from "./pages/inversiones/EstadoCuenta";


import {
  Routes,
  Route,Redirect
} from 'react-router-dom';


const App = () => {

  function PrivateRoute({ component: Component, ...rest }) {
    // useAuth es un hook personalizado para obtener el estado de autenticación del usuario actual
   
  
    return (
      <Route
        {...rest}
        render={(props) =>
         <Component {...props}  />
        }
      />
    );
  }
  
 
  return(
  
    <div className="App">
      <Routes>
  
        
          <Route path="/" element={<Welcome/>} />
          <Route exact path='/login' element={<Login/>}></Route>
          <Route path='/userchangepassword/:token' element={< UserChangePassword/>}></Route>
          <Route exact path='/empleados' element={< Users/>}></Route>
          <Route path='/user' element={< UserCRUD/>}></Route>

          <Route exact path='/concentra-pagos-acendes' element={< ConcentradorPagos/>}></Route>
          <Route exact path='/reportewithoutfactoraje' element={< ReporteFactoraje/>}></Route>
          
          <Route exact path='/home' element={< Welcome/>}></Route>
         
          
          <Route exact path='/rostro' element={< Rostro/>}></Route>
          
          <Route exact path='/conf-usuarios' element={< Usuarios/>}></Route>
          <Route exact path='/conf-usuarios-update' element={< UsuariosUpdate/>}></Route>
       
          <Route exact path='/inversion-inicio' element={< InversionPaso0/>}></Route>
          <Route exact path='/inversion-registro' element={< InversionPaso1/>}></Route>
          <Route exact path='/inversion-rendimiento' element={< InversionPaso2/>}></Route>
          <Route exact path='/inversion-banco' element={< InversionPaso3/>}></Route>
          <Route exact path='/inversion-contrato' element={< InversionPaso4/>}></Route>

          <Route exact path='/simulador-inicio' element={< SimuladorPaso0/>}></Route>
          <Route exact path='/simulador-generar' element={< SimuladorPaso1/>}></Route>

          <Route exact path='/actualiza-tasas' element={< ActualizaTasas/>}></Route>

          <Route exact path='/prospecciones' element={< Prospecciones/>}></Route>
          <Route exact path="/inversiones-activa" element={< InversionesActiva/>}></Route>

          <Route exact path='/generar-solicitudes' element={< GenerarSolicitudes/>}></Route>
          
          <Route exact path='/solicitudes' element={< Solicitudes/>}></Route>

          <Route exact path='/estado-cuenta' element={< EstadoCuenta/>}></Route>
    </Routes>

    </div>
  
    

)};
export default App;