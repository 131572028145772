import React, { useState, useEffect, useContext } from "react";
import AuthService from "../../services/auth.service";
import InversionService from "../../services/inversion.Service";
import TasasService from "../../services/tasasBanxico.Service";
import { ComponentesContext } from "../../contextos/componentesContext";
import { useNavigate, useLocation } from "react-router-dom";
import ModalDocumentViewer from "../../componentes/ModalDocumentViewer";
import DatePickerCustom from "../../componentes/DatePickerCustom";
import moment from "moment";
import dayjs from "dayjs";
import {
  Card,
  Col,
  Row,
  Typography,
  Divider,
  Form,
  Slider,
  InputNumber,
  Select,
  Radio,
  Button,
  Modal,
  Table,
  Tooltip,
  Skeleton,
  Modal as Modalinversion
} from "antd";
import Structure from "../../componentes/Structure";
import MainInversion from "./Main";

import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  FundProjectionScreenOutlined,
  FundViewOutlined,
  BankOutlined,
  FileDoneOutlined,
  FileAddOutlined
} from "@ant-design/icons";
import InputCustom from "../../componentes/InputCustom";

const Step2 = () => {
  const dateFormat = "DD-MM-YYYY";
  const {CloseSession, ShowLoading, InputTypes,  ModalMessage } =
    useContext(ComponentesContext);
  const [periodoFin, setPeriodoFin] = useState(0);
  const [periodoIni, setPeriodoIni] = useState(0);
  const [periodos, setPeriodos] = useState([]);
  const [user, setUser] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [value, setValue] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalInversionOpen, setisModalInversionOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [columnSimulacion, setcolumnSimulacion] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceInversiones, setdataSourceInversiones] = useState([]);
  const [capitalSimulacion, setcapitalSimulacion] = useState(0);
  const [rendimientoSimulacion, setrendimientoSimulacion] = useState(0);
  const [cuentaSeleccionada, setcuentaSeleccionada] = useState({});
  const [modalButtonDisabled, setmodalButtonEnabled] = useState(true);
  const [totalSimulacion, settotalSimulacion] = useState(0);
  const [messageTooltip, setmessageTooltip] = useState("");
  const [OpenModelDocument, setOpenModelDocument] = useState(false);
  const [optTasas, setoptTasas] = useState([]);
  const [pathDocument, setpathDocument] = useState([]);
  const [estadoInversionActiva, setestadoInversionActiva] = useState("");
  const [idInversion, setidInversion] = useState("0");
  const [valorImporteMinimo, setvalorImporteMinimo] = useState(1);

  const [show, setshow] = useState(false);
  const [messageError, setmessageError] = useState("");
  const [RFC, SetRFC] = useState(
    typeof location.state.record.rfc != "undefined"
      ? location.state.record.rfc != null
        ? location.state.record.rfc
        : ""
      : ""
  );
  const [statusRFC, SetstatusRFC] = useState(
    typeof location.state.record.status != "undefined"
      ? location.state.record.status != null
        ? location.state.record.status
        : ""
      : ""
  );

  const [codInv, SetcodInv] = useState(
    typeof location.state.record.codInv != "undefined"
      ? location.state.record.codInv != null
        ? location.state.record.codInv
        : ""
      : ""
  );

  const CurrencyFormatter = ({ amount }) => {
    // Formatea el número como moneda en pesos mexicanos
    const formattedAmount = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(amount);
  
    return <span>Debes ingresar un valor mayor a {formattedAmount}</span>;
  };

  const [columnCuentas, setcolumnCuentas] = useState([
    {
      title: "Folio",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Monto",
      dataIndex: "montoInversionString",
      key: "montoInversionString",
    },
    {
      title: "Periodos",
      dataIndex: "periodos",
      key: "periodos",
    },
    {
      title: "Tasa",
      dataIndex: "tasa",
      key: "tasa",
    },

    {
      title: "Valor tasa",
      dataIndex: "valorTasaPactada",
      key: "valorTasaPactada",
    },
  ]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setcuentaSeleccionada(selectedRows[0]);
      setmodalButtonEnabled(false);
      
    },
  };

  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    setUser(usr);

    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate("/login");
    }

    setTimeout(function () {
      form.setFieldsValue({
        inversion: {
          montoInversion: 0,
          periodos: 12,
          retornoInversion: 1,
          tasaAdicional: 0,
        },
      });
    }, 0);

    let fecha = moment().format("YYYY-MM-DD")

    //console.log(fecha);

    setTasasData(fecha);
      
  }, []);

  const handleBack = () => {
    navigate("/inversion-registro", {
      state: { record: { rfc: RFC, status: statusRFC, codInv: codInv } },
    });
  };

  const onFinish = async (values) => {

    //console.log(values.inversion);

    ShowLoading(true);
    var respuesta = [];
    values.inversion.codInv = codInv;
    values.inversion.id = idInversion;
    //values.inversion.fechaInicial = moment(new Date(values.inversion.fechaInicial)).format("DD-MM-YYYY");

    //console.log(values.inversion);

    values.inversion.fechaInicial = moment(new Date(values.inversion.fechaInicial)).format("YYYY-MM-DD");


    if (values.inversion.montoInversion < valorImporteMinimo) {
      ShowLoading(false);
      ModalMessage("Mensaje",(<CurrencyFormatter amount={valorImporteMinimo} />));
      return false;
    }

    if (values.inversion.fechaInicial.toString().trim() == "Invalid date") {
      ShowLoading(false);
      ModalMessage("Mensaje","Debes seleccionar la fecha de inicio de inversión." );
      return false;
    }
    

    if (typeof values.inversion.tipoTasa == "undefined") {
      ShowLoading(false);
      ModalMessage("Mensaje","Debes seleccionar un tipo de tasa." );
      return false;
    }
    else{
      if(values.inversion.tipoTasa==1 && values.inversion.tasaAdicional==0 ){
        ShowLoading(false);
        ModalMessage("Mensaje","La tasa adicional debe ser mayor que 0." );
        return false;
      }
    }
    
    
    respuesta = await InversionService.postCondicionesFinancieras(
      values.inversion
    );

    //console.log(respuesta);

    // //80 error
    // //81 error
    if (respuesta.estado == 0) {
      //console.log("SE REGISTRO CORRECTAMENTE EL INVERSIONISTA");
      
      navigate("/inversion-banco", {
        state: { record: { rfc: RFC, status: statusRFC, codInv: codInv, idInversion: respuesta.id } },
      });
    } else {
      
      if (respuesta.estado == 403) {
        CloseSession();
        return false;
      }

      if (respuesta.estado == 500) {
        ModalMessage("Mensaje","Error al guardar la inversión." );
        return false;
      }

    }
    ShowLoading(false);
  };

  const showModal = () => {
    let data = form.getFieldsValue();
    SetSimulacionData(data.inversion);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };


  const handleInversionesClose = () => {
    setisModalInversionOpen(false);
  };

  const handleModalClose = () => {
    setOpenModelDocument(false);
  };

  const setTasasData = async (fecha) => {
    let tasasData = await TasasService.getTasas(fecha);

    if (tasasData.estado == 403) {
      CloseSession();
      return false;
    }

    let respuesta = await InversionService.getImporteMinimo();
    
    if (respuesta.estado == 403) {
      CloseSession();
      return false;
    }

    setvalorImporteMinimo(respuesta.importeMinimo);
    //console.log(valorImporteMinimo);
    getPeriodosInversion();


    let tasas = await AddItemComboTasa(tasasData.tasas);
    
    setoptTasas(tasas);
    //setoptTasas(tasasData.tasas);

    //console.log(codInv);
    let inversionActiva = await InversionService.postInversionCurso({codInv});

    setestadoInversionActiva(inversionActiva.estado);
    if(inversionActiva.estado==10){
      setLoading(false);
      return false;
    }

    if(inversionActiva.estado==403){
      setLoading(false);
      CloseSession();
      return false;
    }

    if(inversionActiva.estado==500){
      setLoading(false);
      return false;
    }

    let Respuesta = await AddNameTasa(inversionActiva.inversiones, tasasData.tasas);
    if(Respuesta.estado==500){
      setLoading(false);
      return false;
    }

    for (const element of Respuesta) {
      element.montoInversionString=element.montoInversion.toLocaleString(
        "en",
        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
      )
    }

    setdataSourceInversiones(Respuesta);
    if (Respuesta.length > 0) {
      setisModalInversionOpen(true);
    }
    setLoading(false);

  };

  const getPeriodosInversion = async() => {
    let respuesta = await InversionService.postPeriodosInversion();

    if (respuesta.estado == 403) {
      CloseSession();
      return false;
    }

    if (respuesta.estado == 500) {
      ModalMessage(
        "Mensaje",
        "Error al obtener los Periodos de Inversion."
      );
      return false;
    }

    setPeriodoIni(parseInt(respuesta.data[0]));
    setPeriodoFin(parseInt(respuesta.data[respuesta.data.length - 1]))
    setPeriodos(respuesta.data.reduce((obj, item) => {
      obj[item] = item;
      return obj;
    }, {}));
  };

  const AddItemComboTasa = async (arrayTasas) => {
    for (const element of arrayTasas) {
      element.value = element.value;
      element.id = element.value;
      element.key = element.value;
      element.label = (<Tooltip placement="right" title={<>valor: {element.valor} al: {element.fecha}</>}>{element.label}</Tooltip>) ;
    }

    return arrayTasas;
  };

  const AddNameTasa = async (arrayCuentas, arrayTasas) => {
    for (const element of arrayCuentas) {
      let nombreTasa = arrayTasas
        .filter((item) => item.value === element.tipoTasa)
        .map((item) => item.label);

      element.tasa = nombreTasa[0];
      element.key = element.id;
    }
    return arrayCuentas;
  };

  const SetSimulacionData = async (json) => {
    json.codInv = codInv;
    json.fechaInicial = moment(new Date(json.fechaInicial)).format("YYYY-MM-DD");
    
    if (json.montoInversion <= 0) {
      setmessageError("El monto de inversión debe ser mayor a 0.");
      setshow(false);
      setIsModalOpen(true);
      return false;
    }

    if (json.fechaInicial.toString().trim() == "Invalid date") {
      setmessageError("Debes seleccionar la fecha de inicio de inversión.");
      setshow(false);
      setIsModalOpen(true);
      return false;
    }
    

    if (typeof json.tipoTasa == "undefined") {
        setmessageError("Debes seleccionar un tipo de tasa.");
        setshow(false);
        setIsModalOpen(true);
        return false;
    }
    else{
      if(json.tipoTasa==1 && json.tasaAdicional==0 ){
        setmessageError("La tasa adicional debe ser mayor que 0.");
        setshow(false);
        setIsModalOpen(true);
        return false;
      }
    }

    

    ShowLoading(true);
    let SimulacionData = await InversionService.postSimulacion(json);

    setpathDocument(SimulacionData.path);

    if (SimulacionData.estado == 90 || SimulacionData.estado == 81 ) {
      ShowLoading(false);
      setmessageError(" Error al generar la simulación.");
      setshow(false);
      setIsModalOpen(true);
      return false;
    }

    if (SimulacionData.estado == 403) {
      CloseSession();
      return false;
    }
    if (SimulacionData.estado == 500) {
      ShowLoading(false);
      if (typeof json.tipoTasa == "undefined") {
        setmessageError(" Debe seleccionar un tipo de tasa.");
      }
      else{
        setmessageError("No se pudo generar la simulación para los datos seleccionados.");
      }
      setshow(false);
      setIsModalOpen(true);
      return false;
    } else {
      if (typeof json.tipoTasa == "undefined") {
        setmessageError(" Debe seleccionar un tipo de tasa.");
        setshow(false);
      } else {
        if (SimulacionData.estado == 10) {
          setmessageError(
            "No se pudo generar la simulación para los datos seleccionados."
          );
          setshow(false);
        } else {
          setshow(true);
          //console.log(SimulacionData);

          SimulacionData.arrHeader[0].width = 70;

          setcolumnSimulacion(SimulacionData.arrHeader);

          for (
            let index = 0;
            index < SimulacionData.arrSimulacion.length;
            index++
          ) {
            if (
              typeof SimulacionData.arrSimulacion[index].capFin != "undefined"
            ) {
              SimulacionData.arrSimulacion[index].capFin =
                SimulacionData.arrSimulacion[index].capFin == "0"
                  ? "-"
                  : "$" +
                    SimulacionData.arrSimulacion[index].capFin.toLocaleString(
                      "en",
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    );
            }

            if (
              typeof SimulacionData.arrSimulacion[index].capIni != "undefined"
            ) {
              SimulacionData.arrSimulacion[index].capIni =
                SimulacionData.arrSimulacion[index].capIni == "0"
                  ? "-"
                  : "$" +
                    SimulacionData.arrSimulacion[index].capIni.toLocaleString(
                      "en",
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    );
            }

            SimulacionData.arrSimulacion[index].capitalPago =
              SimulacionData.arrSimulacion[index].capitalPago == "0"
                ? "-"
                : "$" +
                  SimulacionData.arrSimulacion[
                    index
                  ].capitalPago.toLocaleString("en", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });

            SimulacionData.arrSimulacion[index].interes =
              SimulacionData.arrSimulacion[index].interes == "0"
                ? "-"
                : "$" +
                  SimulacionData.arrSimulacion[index].interes.toLocaleString(
                    "en",
                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                  );

            SimulacionData.arrSimulacion[index].pagoTotal =
              SimulacionData.arrSimulacion[index].pagoTotal == "0"
                ? "-"
                : "$" +
                  SimulacionData.arrSimulacion[index].pagoTotal.toLocaleString(
                    "en",
                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                  );

            SimulacionData.arrSimulacion[index].key = index;
          }

          setDataSource(SimulacionData.arrSimulacion);
          setcapitalSimulacion(
            SimulacionData.arrFooter.capital == "0"
              ? "-"
              : "$ " +
                  SimulacionData.arrFooter.capital.toLocaleString("en", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
          );
          setrendimientoSimulacion(
            SimulacionData.arrFooter.sumaRendimiento == "0"
              ? "-"
              : "$ " +
                  SimulacionData.arrFooter.sumaRendimiento.toLocaleString(
                    "en",
                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                  )
          );
          settotalSimulacion(
            SimulacionData.arrFooter.total == "0"
              ? "-"
              : "$ " +
                  SimulacionData.arrFooter.total.toLocaleString("en", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
          );
        }
      }
    }

    ShowLoading(false);

    setIsModalOpen(true);

    //console.log(SimulacionData.arrSimulacion);
    //setOptionsEstados(estadosData.data);

    // form.setFieldsValue({
    //   renapo: {
    //     estado: cpData.data[0].estado,
    //     municipio: cpData.data[0].municipio,
    //     ciudad: cpData.data[0].ciudad,
    //   },
    // });
    // setOptionsColonia(cpData.data);
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const onChange = (value) => {
    //console.log("changed", value);
  };

  const onChangeTipoTasa = (valor) => {
    //console.log(valor);
    let filterNumbers = optTasas.filter((value) => value.value == valor);

    setTimeout(function () {
      form.setFieldsValue({
        inversion: {
          tipoTasa: valor,
        },
      });
    }, 0);

    setmessageTooltip(
      "Valor: " + filterNumbers[0].valor + " Al: " + filterNumbers[0].fecha
    );
  };

  const HandleDownload = async () => {
    setOpenModelDocument(true);
  };

  const handleUseAccount = () => {
    
    if(cuentaSeleccionada.length==0){
      console.log("No se selecciono ninguna Inversión")
    }
    else{

      let fecha = dayjs(
        moment().format("DD") +
          "/" +
          moment().format("MM") +
          "/" +
          moment().format("YYYY"),
        dateFormat
      );

      setTimeout(function () {
        form.setFieldsValue({
          inversion: {
            montoInversion: cuentaSeleccionada.montoInversion,
            periodos: cuentaSeleccionada.periodos,
            tipoTasa: cuentaSeleccionada.tipoTasa,
            tasaAdicional: cuentaSeleccionada.tasaAdicional,
            fechaInicial: fecha,
            retornoInversion: cuentaSeleccionada.retornoInversion
          },
        });

        setidInversion(cuentaSeleccionada.id);
      }, 0);
      

      setisModalInversionOpen(false);
    }
    
  };

  const handleNewDateTasas = async (date, dateString) => {
    
    let tasasData = await TasasService.getTasas(moment(new Date(date)).format("YYYY-MM-DD"));

    if (tasasData.estado == 403) {
      CloseSession();
      return false;
    }

    let tasas = await AddItemComboTasa(tasasData.tasas);
    
    setoptTasas(tasas);

  }

  return (
    <Structure breadcrumb={"Inicio / Inversiones"}>
      <MainInversion step={2}>
        <center>
          <Divider>
            <Typography.Title level={2} style={{ margin: 0, color: "#081b3e" }}>
              Simulador de inversión
            </Typography.Title>
          </Divider>
        </center>
        <center>
          <Skeleton loading={loading} active={true}>
            <Form
              form={form}
              style={{ marginTop: 20, maxWidth: 380 }}
              name="horizontal_login"
              layout="inline"
              onFinish={onFinish}
            >
              <Row>
                <Col span={12} style={{ textAlign: "left", marginLeft: 30 }}>
                  <Form.Item
                    name={["inversion", "montoInversion"]}
                    label="Monto de inversión"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputCustom
                      prefix="$"
                      inputtype={InputTypes.Integer}
                      onChange={onChange}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      style={{ width: 320 }}
                      key={1}
                    />
                    
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ marginTop: 10 }}>
                <Col span={20} style={{ textAlign: "left", marginLeft: 30 }}>
                  <Form.Item
                    name={["inversion", "periodos"]}
                    label="En cuantos meses desea invertir?"
                    initialvalues={12}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Slider
                      min={periodoIni}
                      max={periodoFin}
                      marks={periodos}
                      step={12}
                      // defaultValue={12}
                      style={{ width: 308 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              
              <Row>
                <Col span={20} style={{ textAlign: "left", marginLeft: 30 }}>
                  <Form.Item
                    name={["inversion", "fechaInicial"]}
                    label="Fecha inicia inversión"
                  >
                    <DatePickerCustom
                      format={dateFormat}
                      style={{ width: 320 }}
                      // ref={(dateSelect) => { this.dateSelect = dateSelect }}
                      // disabledDate={(current) => {
                      //   return moment().add(-1, 'days')  >= current;
                      //   }}
                      onChange={ handleNewDateTasas }
                    />
                  </Form.Item>
                </Col>
              </Row>
            
              <Row>
                <Col span={20} style={{ textAlign: "left", marginLeft: 30 }}>
                  <Form.Item
                    name={["inversion", "tipoTasa"]}
                    label="Tasa de interes"
                  >
                      <Select
                        style={{ width: 320 }}
                        placeholder="Seleccione una opción"
                        options={optTasas}
                        onChange={onChange}                        
                      />
                    
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={20} style={{ textAlign: "left", marginLeft: 30 }}>
                  <Form.Item
                    name={["inversion", "tasaAdicional"]}
                    label="Tasa adicional"
                    initialvalues={0}
                  >
                    <InputNumber
                      // defaultValue={0}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      onChange={onChange}
                      style={{ width: 320 }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={20} style={{ textAlign: "left", marginLeft: 30 }}>
                  <Form.Item
                    name={["inversion", "retornoInversion"]}
                    label="Retorno mensual"
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>Rendimiento</Radio>
                      <Radio value={2}>Capital + Rendimiento</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <div style={{ width: "100%", marginTop: 15 }}>
                <Button
                  style={{ width: 320 }}
                  type="primary"
                  onClick={() => showModal()}
                  icon={<FundProjectionScreenOutlined />}
                >
                  Mostrar tabla de inversión
                </Button>

                <Row style={{ marginLeft: 15, marginTop: 10 }}>
                  <Col span={12}>
                    <Button
                      style={{ width: 150 }}
                      type="primary"
                      onClick={() => handleBack()}
                      icon={<ArrowLeftOutlined />}
                    >
                      Atras
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      style={{ width: 150, marginBottom: 10 }}
                      type="primary"
                      // onClick={() => handleNext()}
                      htmlType="submit"
                    >
                      Continuar <ArrowRightOutlined />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </Skeleton>
        </center>

        <Modal
          closable={false}
          title={
            <div>
              <Typography.Title
                level={4}
                style={{ margin: 0, color: "#081b3e" }}
              >
                <FundProjectionScreenOutlined /> Simulador de inversión
              </Typography.Title>
            </div>
          }
          open={isModalOpen}
          onCancel={handleClose}
          maskClosable={false}
          width={800}
          footer={[
            <Button key={1} type="primary" onClick={handleClose}>
              OK
            </Button>,
          ]}
        >
          {!show && (
            <>
              <Typography.Title level={5} style={{ margin: 0 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{messageError}
              </Typography.Title>
            </>
          )}
          {show && (
            <div>
              <Typography.Title
                level={5}
                style={{ margin: 0, color: "#081b3e" }}
              >
                Tabla de inversión
              </Typography.Title>
              <Table
                columns={columnSimulacion}
                dataSource={dataSource}
                size="small"
                pagination={false}
                scroll={{
                  y: 160,
                }}
              />
              <br></br>
              <Row gutter={22}>
                <Col span={5}>&nbsp;</Col>
                <Col span={14}>
                  <Card
                    title={
                      <Typography.Title level={5} style={{ margin: 0 }}>
                        Inversión final
                      </Typography.Title>
                    }
                    headStyle={{ textAlign: "center" }}
                    bordered={false}
                    size={"small"}
                  >
                    <table width={"90%"} border={0}>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              textAlign: "right",
                              paddingRight: 15,
                              width: 115,
                            }}
                          >
                            <Typography.Text strong>
                              Capital&nbsp;&nbsp;
                            </Typography.Text>
                          </td>
                          <td style={{ width: 20 }}></td>
                          <td
                            style={{
                              paddingLeft: 15,
                              width: 80,
                              textAlign: "right",
                            }}
                          >
                            <Typography.Text strong>
                              {capitalSimulacion}&nbsp;&nbsp;
                            </Typography.Text>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ textAlign: "right", paddingRight: 15 }}>
                            <Typography.Text strong>
                              Suma rendimiento&nbsp;&nbsp;
                            </Typography.Text>
                          </td>
                          <td style={{ textAlign: "center", width: 30 }}>=</td>
                          <td
                            style={{
                              paddingLeft: 10,
                              width: 100,
                              textAlign: "right",
                            }}
                          >
                            <Typography.Text strong>
                              {rendimientoSimulacion}&nbsp;&nbsp;
                            </Typography.Text>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ textAlign: "right", paddingRight: 15 }}>
                            <Typography.Text strong>
                              Total&nbsp;&nbsp;
                            </Typography.Text>
                          </td>
                          <td style={{ textAlign: "center" }}>=</td>
                          <td
                            style={{
                              paddingLeft: 15,
                              width: 80,
                              textAlign: "right",
                            }}
                          >
                            <Typography.Text strong>
                              {totalSimulacion}&nbsp;&nbsp;
                            </Typography.Text>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card>
                </Col>
                <Col span={5}>
                  <Card
                    title={
                      <Typography.Title level={5} style={{ margin: 0 }}>
                        Imprimir
                      </Typography.Title>
                    }
                    bordered={false}
                    size={"small"}
                  >
                    <Button
                      type="primary"
                      icon={<FundViewOutlined />}
                      size={"large"}
                      onClick={HandleDownload}
                    >
                      PDF
                    </Button>
                  </Card>
                </Col>
              </Row>
            </div>
          )}
        </Modal>
                  

        <Modalinversion
          closable={false}
          title={
            <div>
              <Typography.Title
                level={4}
                style={{ margin: 0, color: "#081b3e" }}
              >
                <BankOutlined /> Inversiones registradas
              </Typography.Title>
            </div>
          }
          maskClosable={false}
          open={isModalInversionOpen}
          onCancel={handleInversionesClose}
          width={800}
          footer={[
            <Button
              key={1}
              type="primary"
              onClick={handleInversionesClose}
              icon={<FileAddOutlined />}
            >
              Nueva inversión
            </Button>,
            <Button
            key={0}
            type="primary"
            onClick={handleUseAccount}
            icon={<FileDoneOutlined />}
            disabled={modalButtonDisabled}
          >
            Usar Inversión seleccionada
          </Button>
          ]}
        >
          <Typography.Title level={5} style={{ margin: 0, color: "#081b3e" }}>
            El inversionista ya tiene registradas las siguientes inversiones, desea
            modificar alguna:
          </Typography.Title>
          <Table
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            columns={columnCuentas}
            dataSource={dataSourceInversiones}
            size="small"
            pagination={false}
            scroll={{
              y: 160,
            }}
          />
        </Modalinversion>
        <ModalDocumentViewer
          open={OpenModelDocument}
          titulo={"Proyección de inversión"}
          url={pathDocument}
          style={{top: 80}}
          footer={[
            <Button type="primary" onClick={handleModalClose}>
              OK
            </Button>,
          ]}
        />
      </MainInversion>
    </Structure>
  );
};
export default Step2;
