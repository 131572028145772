import React, { useState, useEffect, useRef, useContext } from "react";
import AuthService from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import Highlighter from "react-highlight-words";
import Structure from "../../componentes/Structure";
import InversionService from "../../services/inversion.Service";
import { ComponentesContext } from "../../contextos/componentesContext";

import returnValidUploadFileClass from "../../clases/returnValidUploadFile.class";

import "./Prospecciones.css";

import {
  PlusOutlined,
  SearchOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
  CloseOutlined,
  CheckCircleOutlined
} from "@ant-design/icons";

import {
  Button,
  Input,
  Tag,
  Space,
  Table,
  Typography,
  Modal,
  Upload,
  List
} from "antd";

const { Column } = Table;

const Prospecciones = () => {
  const navigate = useNavigate();
  const { CloseSession, ShowLoading, ModalMessage } =
    useContext(ComponentesContext);
  const [dataSource, setDataSource] = useState([]);
  const [codInvRow, setcodInvRow] = useState("");
  const [idInversion, setidInversion] = useState("");
  const [rfcRow, setrfcRow] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenDocs, setisModalOpenDocs] = useState(false);
  const [pathDocument, setpathDocument] = useState([]);
  const [isModalSolicitudOpen, setIsModalSolicitudOpen] = useState(false);
  const [OpenModelDocument, setOpenModelDocument] = useState(false);
  const [loadingModal, setloadingModal] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [canRemove, setcanRemove] = useState(true);
  const [ModelAttachementType, setModelAttachementType] = useState(1);
  const [idInversionBorrar, setidInversionBorrar] = useState(null);
  const [isModalOpenBorrar, setisModalOpenBorrar] = useState(false);

  const [taskCompleted1, setTaskCompleted1] = useState(false);
  const [taskCompleted2, setTaskCompleted2] = useState(false);
  const [taskCompleted3, setTaskCompleted3] = useState(false);

  const handleModalCloseDocs = () => {
    setisModalOpenDocs(false);
  }

  const handlePreview = async (file) => {

    const fileExtension = file.name.split(".").pop();

    switch (fileExtension.toLowerCase() ) {
      case "pdf":
        setpathDocument(file.url);
        setOpenModelDocument(true);
        break;
    }
  };

  const handleBeforeUpload = async (file) =>{
    let flagArchivoValido= await returnValidUploadFileClass.returnPdfValidUploadFile(file.type);

    //console.log(file.type);
    if(flagArchivoValido==false){
      ModalMessage("Mensaje","El tipo de archivo que se desea adjuntar no es valido." );
      return false;
    }

    const fileSizeLimit = 10 * 1024 * 1024; // 5MB

    if (file.size > fileSizeLimit) {
      ModalMessage("Mensaje","El archivo no puede pesar mas de 10MB." );
      return false; // Cancela la carga del archivo
    }


    setloadingModal(true);
    return true;
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 0,
        }}
      >
        Adjuntar
      </div>
    </div>
  );

  const SuccessButton = (
    <div>
      <CheckCircleOutlined style={{ color: 'green' }}/>
      <div
        style={{
          marginTop: 0,
        }}
      >
        Completado
      </div>
    </div>
  );

  function UploadDocument(props) {
    const { id, title } = props;

    const guardarDocumento = async (file) => {
      ShowLoading(true);
      let resActivar = await InversionService.postGuardarDocumento(idInversion, id, file);
  
      if (resActivar.status == 403) {
        
        CloseSession();
        return false;
      }
  
      if (resActivar.estado == 500) {
        ModalMessage("Mensaje", "Se produjo un error al guardar el documento.");
        return false;
      }

      setTaskCompleted1(true);
      ShowLoading(false);
      ModalMessage(
        "Mensaje",
        "Documento Guardado Correctamente."
      );
    };
    return (
      <div id={props.id} style={{ marginLeft: 30 }}>
        <table border={0}>
          <tr>
            <td width={200}>{props.title}</td>
            <td style={{textAlign:"center"}}>

              <div style={{ backgroundColor: "#f5f5f5", borderRadius: 10, textAlign:"center", paddingLeft:7 }}>
                <Upload
                  name="archivo"
                  listType="picture-card"
                  showUploadList={{
                    showRemoveIcon: canRemove,
                  }}
                  action={guardarDocumento}
                >
                  {taskCompleted1 == true ? SuccessButton : uploadButton}
                </Upload>
              </div>

            </td>
          </tr>
        </table>
      </div>
    );
  }

  function UploadDocument2(props) {
    const { id, title } = props;

    const guardarDocumento = async (file) => {
      ShowLoading(true);
      let resActivar = await InversionService.postGuardarDocumento(idInversion, id, file);
  
      if (resActivar.status == 403) {
        
        CloseSession();
        return false;
      }
  
      if (resActivar.estado == 500) {
        ModalMessage("Mensaje", "Se produjo un error al guardar el documento.");
        return false;
      }

      setTaskCompleted1(true);
      ShowLoading(false);
      ModalMessage(
        "Mensaje",
        "Documento Guardado Correctamente."
      );
    };
    return (
      <div id={props.id} style={{ marginLeft: 30 }}>
        <table border={0}>
          <tr>
            <td width={200}>{props.title}</td>
            <td style={{textAlign:"center"}}>

              <div style={{ backgroundColor: "#f5f5f5", borderRadius: 10, textAlign:"center", paddingLeft:7 }}>
                <Upload
                  name="archivo"
                  listType="picture-card"
                  showUploadList={{
                    showRemoveIcon: canRemove,
                  }}
                  action={guardarDocumento}
                >
                  {taskCompleted2 == true ? SuccessButton : uploadButton}
                </Upload>
              </div>

            </td>
          </tr>
        </table>
      </div>
    );
  }

  function UploadDocument3(props) {
    const { id, title } = props;

    const guardarDocumento = async (file) => {
      ShowLoading(true);
      let resActivar = await InversionService.postGuardarDocumento(idInversion, id, file);
  
      if (resActivar.status == 403) {
        
        CloseSession();
        return false;
      }
  
      if (resActivar.estado == 500) {
        ModalMessage("Mensaje", "Se produjo un error al guardar el documento.");
        return false;
      }

      setTaskCompleted1(true);
      ShowLoading(false);
      ModalMessage(
        "Mensaje",
        "Documento Guardado Correctamente."
      );
    };
    return (
      <div id={props.id} style={{ marginLeft: 30 }}>
        <table border={0}>
          <tr>
            <td width={200}>{props.title}</td>
            <td style={{textAlign:"center"}}>

              <div style={{ backgroundColor: "#f5f5f5", borderRadius: 10, textAlign:"center", paddingLeft:7 }}>
                <Upload
                  name="archivo"
                  listType="picture-card"
                  showUploadList={{
                    showRemoveIcon: canRemove,
                  }}
                  action={guardarDocumento}
                >
                  {taskCompleted3 == true ? SuccessButton : uploadButton}
                </Upload>
              </div>

            </td>
          </tr>
        </table>
      </div>
    );
  }


  const dataUpload = [
    <UploadDocument
      id={1}
      title={"Reporte de simulación tabla de amortización"}
    />,
    <UploadDocument2
      id={2}
      title={"Contrato"}
    />,
    <UploadDocument3
      id={3}
      title={"Pagaré"}
    />,
  ];

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getProspecciones = async () => {
    //console.log("entro");
    let respuesta = await InversionService.postProspecciones();

    if (respuesta.estado == 403) {
      CloseSession();
      return false;
    }

    if (respuesta.estado == 500) {
      ModalMessage(
        "Mensaje",
        "Error al obtener las prospecciones e inversiones activas."
      );
      return false;
    }

    for (const element of respuesta.inversiones) {
      element.montoInversion =
        element.montoInversion == "0"
          ? "-"
          : "$" +
            element.montoInversion.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });

      element.status = "0";
    }

    setDataSource(respuesta.inversiones);
    //console.log(respuesta.inversiones);
  };

  const action = (dataIndex) => ({
    return: (
      <Space size="middle">
        <a>Invite {dataIndex}</a>
        <a>Delete</a>
      </Space>
    ),
  });
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Limpiar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleEdit = (id) => {
    //console.log("Clicked edit button", id);
    navigate("/conf-usuarios-update", { state: { id: id } });
  };
  const handleCancel = () => {
    //console.log("Clicked cancel button");
  };

  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    //console.log("USER HOOK", usr);

    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate("/login");
    }

    getProspecciones();
   
  }, []);

  const ObtenerListDocumentos = async () => {
    const respDocs = await InversionService.getListDocumentos(idInversion);

    const { data } = respDocs;
    for (const element of data.documentoList){
      switch (element.tipoDocumento) {
        case 1:
          setTaskCompleted1(true);
          break;
        case 2:
          setTaskCompleted2(true);
          break;
        case 3:
          setTaskCompleted3(true);
          break;
      }
    }
  }

  const HandleActivar = async () => {
    let resDocs = await InversionService.getListDocumentos(idInversion);

    if (!resDocs.result){
      ModalMessage("Mensaje", resDocs.msg);
      return false;
    }

    if (resDocs.data.documentoList.length < 3){
      ModalMessage("Mensaje", `Favor de Subir los documentos completos solo ha subido ${resDocs.data.documentoList.length} archivos`);
      setIsModalOpen(false);
      return false;
    }

    let resActivar = await InversionService.postActivarInversion(idInversion);

    if (resActivar.status == 403) {
      CloseSession();
      return false;
    }

    if (resActivar.estado == 500) {
      ModalMessage("Mensaje", "Se produjo un error al activar la inversión.");
      return false;
    }

    getProspecciones();
    setIsModalOpen(false);
    ModalMessage(
      "Mensaje",
      "La inversión se activo correctamente."
    );
  };

  const HandleEditar = () => {

    navigate("/inversion-registro", {
      state: {
        record: {
          rfc: rfcRow,
          status: 0,
          codInv: codInvRow,
        },
      },
    });
  };

  const HandleMouseEnter = (record) => {
    setcodInvRow(record.codInv);
    setrfcRow(record.rfc);
    setidInversion(record.id);
  };

  const handleDelete = async() => {
    let respuestaData = await InversionService.postEliminarInversion(idInversionBorrar);

    if (respuestaData.status == 403) {
      CloseSession();
      return false;
    }

    if (respuestaData.estado == 500) {
      ModalMessage("Mensaje", "Se produjo un error al eliminar la inversión.");
      return false;
    }
    
    getProspecciones();
    ModalMessage(
      "Mensaje",
      "La inversión se elimino correctamente."
    );
    setisModalOpenBorrar(false);
  };

  const aggEmpleado = () => {
    navigate("/user", { state: { id: 0 } });
  };

  const columns = [
    {
      title: "Folio",
      dataIndex: "id",
      key: "id",

      //...getColumnSearchProps('name'),
    },
    {
      title: "Inversionista",
      dataIndex: "descripcionInversionista",
      key: "descripcionInversionista",

      ...getColumnSearchProps("name"),
    },
    {
      title: "RFC",
      dataIndex: "rfc",
      key: "rfc",

      //...getColumnSearchProps('name'),
    },
    {
      title: "Monto inversión",
      dataIndex: "montoInversion",
      key: "montoInversion",

      //...getColumnSearchProps('name'),
    },
    {
      title: "Periodos",
      dataIndex: "periodos",
      key: "periodos",

      //...getColumnSearchProps('age'),
    },
    {
      title: "Tipo Tasa",
      dataIndex: "descripcionTipoTasa",
      key: "descripcionTipoTasa",
      filters: [
        {
          text: "Cetes",
          value: "Cetes",
        },
        {
          text: "TIIE",
          value: "TIIE",
        },
        {
          text: "UDIS",
          value: "UDIS",
        },
      ],
      onFilter: (value, record) => record.descripcionTipoTasa.startsWith(value),
    },
    {
      title: "Fecha inicio",
      dataIndex: "fechaInicial",
      key: "fechaInicial",
      // ...getColumnSearchProps('address'),
    },
    {
      title: "Estatus",
      key: "status",
      dataIndex: "status",
      filters: [
        {
          text: "Prospección",
          value: "0",
        },
        {
          text: "Inversión",
          value: "1",
        },
      ],
      onFilter: (value, record) => record.status.startsWith(value),
      defaultSortOrder: "descend",
      sorter: (a, b) => a.status - b.status,
      render: (_, record) => <>{ReturnStatusTag(record.status)}</>,
    },
    {
      title: "Opciones",
      key: 'action',
      render: (_, record) => (
        <div>
          <a className="button" onClick={() => HandleOpenModalArchivos(record)}>
            <span>Archivos</span>
          </a>

          <a className="button" onClick={HandleEditar}>
            <span>Editar</span>
          </a>
          <a
            className="button"
            onClick={() => HandleOpenModalBorrar(record)}
          >
            <span>Eliminar</span>
          </a>
          <a className="button" onClick={handleModalOpen}>
            <span>Activar</span>
          </a>
        </div>
      ),
    },
  ];

  const HandleOpenModalArchivos = (cuenta) =>{
    ObtenerListDocumentos();
    setisModalOpenDocs(true)
  }

  const HandleOpenModalBorrar = (cuenta) =>{
    setidInversionBorrar(cuenta.id);
    setisModalOpenBorrar(true);
  }

  const HandleCloseModalBorrar = ()  =>{
    setisModalOpenBorrar(false);
  }

  const ReturnStatusTag = (status) => {
    let color = "";
    let text = "";
    switch (status) {
      case "0":
        color = "#ffc300";
        text = "Prospección";
        break;
      case "1":
        color = "#24b237";
        text = "Inversión en curso";
        break;
      default:
        color = "#ff00bb";
        text = "Otro";
    }
    return (
      <Tag color={color} key={status}>
        {text.toUpperCase()}
      </Tag>
    );
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Structure breadcrumb={"Inversiones / Prospecciones"}>
      <center>
        <Typography.Title level={2} style={{ margin: 0, color: "#081b3e" }}>
          Lista de prospecciones.
        </Typography.Title>
      </center>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{
          y: 350,
        }}
        onRow={(record, rowIndex) => {
          return {
            onMouseEnter: (event) => {
              HandleMouseEnter(record);
            }, // click row
          };
        }}
      />

      <Modal
        title={
          <div>
            <QuestionCircleOutlined /> &nbsp; Confirmación
          </div>
        }
        open={isModalOpen}
        onCancel={handleModalClose}
        footer={[
          <Button type="primary" onClick={handleModalClose}>
            No
          </Button>,
          <Button type="primary" onClick={HandleActivar}>
          Si
        </Button>,
        ]}
      >
        <Typography.Title
          level={5}
          style={{ margin: 20, color: "#081b3e", marginBottom: -10 }}
        >
          Desea activar la inversión seleccionada?
        </Typography.Title>
      </Modal>

      <Modal
        title={
          <div>
            <QuestionCircleOutlined /> &nbsp; Confirmación
          </div>
        }
        open={isModalOpenBorrar}
        onCancel={HandleCloseModalBorrar}
        footer={[
          <Button type="primary" onClick={handleDelete}>
            Si
          </Button>,
          <Button type="primary" onClick={HandleCloseModalBorrar}>
          No
        </Button>
        ]}
      >
        <Typography.Title
          level={5}
          style={{ margin: 20, color: "#081b3e", marginBottom: -10 }}
        >
          Deseas borrar la Inversión seleccionada?
        </Typography.Title>
      </Modal>


      <Modal
        title={
          <div>
            <Typography.Title
              level={3}
              style={{ margin: 0, color: "#081b3e", marginBottom: 10 }}
            >
              <UploadOutlined /> &nbsp; Subir documentos.
            </Typography.Title>
          </div>
        }
        style={{ marginTop: -70 }}
        width={470}
        open={isModalOpenDocs}
        onCancel={handleModalCloseDocs}
        footer={[
          <Button type="primary" onClick={handleModalCloseDocs} icon={<CloseOutlined />} >
            Cerrar
          </Button>,

        ]}
      >

      <List
            bordered
            style={{ alignContent: "center" }}
            dataSource={dataUpload}
            renderItem={(item) => (
              <List.Item>
                <Typography.Title level={5} style={{ margin: 0 }}>
                  {item}
                </Typography.Title>
              </List.Item>
            )}
          />

      </Modal>
    </Structure>
  );
};
export default Prospecciones;
