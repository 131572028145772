import React, { useState, useEffect } from "react";
import AuthService from "../../services/auth.service";
import UsuarioService from "../../services/usuarios.Service";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import DatePickerCustom from "../../componentes/DatePickerCustom";
import Structure from "../../componentes/Structure";
import dayjs from 'dayjs';
import {
  HomeOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  LockOutlined,
  AlignCenterOutlined,
} from "@ant-design/icons";

import {
  Button,
  Input,
  FloatButton,
  Form,
  Space,
  theme,
  Modal,
  Col,
} from "antd";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} es requerido!",
  types: {
    email: "${label} no es valido!",
    number: "${label} no es un numero valido!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
/* eslint-enable no-template-curly-in-string */

const UsuarioUpdate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [id, SetId] = useState(
    location.state.record.id != null ? location.state.record.id : 0
  );
  const [primerNombre, SetprimerNombre] = useState(
    location.state.record.primerNombre != null
      ? location.state.record.primerNombre
      : ""
  );
  const [segundoNombre, SetsegundoNombre] = useState(
    location.state.record.segundoNombre != null
      ? location.state.record.segundoNombre
      : ""
  );
  const [apellidoPaterno, SetapellidoPaterno] = useState(
    location.state.record.apellidoPaterno != null
      ? location.state.record.apellidoPaterno
      : ""
  );
  const [apellidoMaterno, SetapellidoMaterno] = useState(
    location.state.record.apellidoMaterno != null
      ? location.state.record.apellidoMaterno
      : ""
  );
  const [correo, Setcorreo] = useState(
    location.state.record.correo != null ? location.state.record.correo : ""
  );
  const [password, Setpassword] = useState(
    location.state.record.password != null ? location.state.record.password : ""
  );
  const [fechaNacimiento, setfechaNacimiento] = useState(
    location.state.record.fecha_nac != null
      ? location.state.record.fecha_nac
      : ""
  );

  const [fechaNac, setFechaNac] = useState({ value: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Mensaje, SetModalMessage] = useState("");
  const dateFormat = 'YYYY/MM/DD';

  const setUserData = async () => {
    let respuesta = await UsuarioService.queryStatement(
      {
        id: "1",
      },
      "returnuser"
    );
  };

  const showModal = (strMessage) => {
    SetModalMessage(strMessage);
    setIsModalOpen(true);
  };

  const handleClose = () => {
    navigate("/conf-usuarios");
  };

  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    console.log("USER HOOK", usr);

    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate("/login");
    }
  }, []);

  const onFinish = async (values) => {
    let respuesta = await UsuarioService.queryStatement(
      values.user,
      "updateuser"
    );
    showModal(respuesta.message);
  };

  const onChange = (date, dateString) => {
    setFechaNac(dateString);
  };

  return (
    <Structure breadcrumb={"Configuración / Usuarios / Alta "}>
      <Modal
        title="Mensaje"
        open={isModalOpen}
        onCancel={handleClose}
        footer={[
          <Button type="primary" onClick={handleClose}>
            OK
          </Button>,
        ]}
      >
        <p>{Mensaje}</p>
      </Modal>
      <center>
      <Form
        {...layout}
        name="nest-messages"
        onFinish={onFinish}
        style={{
          maxWidth: 600,
        }}
        validateMessages={validateMessages}
      >
        <Form.Item
          name={["user", "correo"]}
          label="Correo"
          rules={[
            {
              type: "email",
            },
          ]}
        >
          <Input defaultValue={correo} />
        </Form.Item>

        <Form.Item
          name={["user", "password"]}
          label="Contraseña"
          rules={[
            {
              required: true,
              message: "Por favor ingrese la contraseña!",
            },
          ]}
        >
          <Input.Password
            size="large"
            defaultValue={password}
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Ingrese una contraseña de 8 caracteres"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>

        <Form.Item
          name={["user", "password2"]}
          label="Reingrese la Contraseña"
          rules={[
            {
              required: true,
              message: "Por favor ingrese la contraseña!",
            },
          ]}
        >
          <Input.Password
            size="large"
            defaultValue={password}
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Ingrese la contraseña nuevamente"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>

        <Form.Item
          name={["user", "primerNombre"]}
          label="Primer Nombre"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input defaultValue={primerNombre} />
        </Form.Item>

        <Form.Item
          name={["user", "segundoNombre"]}
          label="Segundo Nombre"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input defaultValue={segundoNombre} />
        </Form.Item>

        <Form.Item
          name={["user", "apellidoPaterno"]}
          label="Apellido Paterno"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input defaultValue={apellidoPaterno} />
        </Form.Item>

        <Form.Item
          name={["user", "apellidoMaterno"]}
          label="Apellido Materno"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input defaultValue={apellidoMaterno} />
        </Form.Item>

        <Form.Item
          name={["user", "fechaNacimiento"]}
          label="Fecha de Nacimiento"
          rules={[
            {
              type: "date",
            },
          ]}
        >
          <DatePickerCustom
            onChange={onChange}
            defaultValue={dayjs(fechaNacimiento, dateFormat)}
            format={dateFormat}
            style={{width:"100%"}}
          />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            ...layout.wrapperCol,
            offset: 8,
          }}
        >
          <Space size={30}>
            <Button type="primary" htmlType="submit">
              Guardar
            </Button>
            
            <Button type="primary" onClick={() => navigate("/conf-usuarios")}>
              Cancelar
            </Button>
          </Space>
        </Form.Item>
      </Form>
      </center>
      <FloatButton
        icon={<HomeOutlined />}
        type="primary"
        style={{
          right: 24,
        }}
        onClick={() => showModal("Otro mensaje")}
      />
    </Structure>
  );
};
export default UsuarioUpdate;
