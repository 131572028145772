import React, { useState, useEffect, useContext } from "react";
import { ComponentesContext } from "../../contextos/componentesContext";
import AuthService from "../../services/auth.service";
import TasasService from "../../services/tasasBanxico.Service";
import { useNavigate } from "react-router-dom";
import { Typography, Form, Input, Button, Modal, Row, Col } from "antd";
import Structure from "../../componentes/Structure";
import { HistoryOutlined } from "@ant-design/icons";

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 200,
  },
};

const ActualizaTasas = () => {
  const { varsInversion, RegistraVars,ShowLoading, ModalMessage } =
    useContext(ComponentesContext);

  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [value, setValue] = useState("");

  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    //console.log("USER HOOK", usr);
    setUser(usr);

    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate("/login");
    }

    //varsInversion.inversionista.RFC="";
  }, []);

  const FormValidateMessages = {
    required: "${label} es requerido!",
    types: {
      email: "${label} no es valido!",
      number: "${label} no es un numero valido!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  // const handleChange = (event) => {

  //   const { value: inputValue } = event.target;
  //   const reg = /^-?\d*(\.\d*)?$/;
  //   if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
  //     onChange(inputValue);
  //   }

  // };
  const onFinish = async (values) => {
    console.log(values.baxico.token);

    if (values.baxico.token.length == 0) {
      ModalMessage("Mensaje", "El token no puede estar en blanco.");
      return false;
    }

    ShowLoading(true);
    let respuesta = await TasasService.getActualizaTasas({
      token: values.baxico.token,
    });

    console.log(respuesta.estado);

    if(respuesta.estado==0){
      ModalMessage("Mensaje", "Las tasas se actualizarón correctamente.");
    }

    ShowLoading(false);
    //
  };

  const handleNext = async (values) => {
    //console.log(values);
    //
  };

  return (
    <Structure breadcrumb={"Inicio / Configuración / Actualiza tasas"}>
      <center>
        <Typography.Title level={3} style={{ margin: 0, color: "#081b3e" }}>
          Actualiza tasas Banxico
        </Typography.Title>

        <Form
          form={form}
          style={{ marginTop: 20, maxWidth: 380 }}
          name="horizontal_login"
          {...layout}
          onFinish={onFinish}
        >
          <Form.Item name={["baxico", "token"]}>
            <Input
              style={{ width: "100%" }}
              placeholder={"Ingrese el token proporcionado por Banxico"}
            />
          </Form.Item>

          <Button style={{ width: "100%" }} type="primary" htmlType="submit">
            <HistoryOutlined /> Actualizar tasas
          </Button>
        </Form>
      </center>
    </Structure>
  );
};
export default ActualizaTasas;
