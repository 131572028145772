import React, { useState, useEffect, useContext, Component } from "react";
import { ComponentesContext } from "../../contextos/componentesContext";
import AuthService from "../../services/auth.service";
import InversionistaService from "../../services/inversionista.Service";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Typography,
  Divider,
  Form,
  Input,
  Button,
  Modal,
  Alert,
} from "antd";
import Structure from "../../componentes/Structure";
import MainInversion from "./Main";
import { ArrowRightOutlined, MessageOutlined } from "@ant-design/icons";
import InputCustom from "../../componentes/InputCustom";

const { Search } = Input;

const RfcInput = (props) => {
  const { value, onChange } = props;
  const [AlertVisible, setAlertVisible] = useState(false);
  

  const handleChange = (e) => {
    const reg =
      /^[A-Za-zñÑ&]{1,2}([A-Za-zñÑ&]([A-Za-zñÑ&](\d(\d(\d(\d(\d(\d(\w(\w(\w)?)?)?)?)?)?)?)?)?)?)?$/;

    if (reg.test(e.target.value) || e.target.value === "") {
      onChange(e.target.value);
      setAlertVisible(false);
    } else {
      if (e.target.value.length < 12) {
        setAlertVisible(true);
      }
    }

    if (e.target.value.length == 1) {
      if (!isNaN(e.target.value)) {
        onChange("");
      }
    }

    //onChange(inputValue);

    //varsInversion.RFC.value = e.target.value;
    //RegistraVars(varsInversion);
  };

  return (
    <>
      <Input
        {...props}
        onChange={handleChange}
        placeholder="Ingrese un rfc valido"
        maxLength={14}
      />
      <div style={{ display: AlertVisible ? "block" : "none" }}>
        <Alert
          message={<div style={{ textAlign: "left" }}>Carácter inválido</div>}
          type="error"
          showIcon
        />
      </div>
    </>
  );
};

const validateMessages = {
  required: "${label} es requerido!",
  types: {
    email: "${label} no es valido!",
    number: "${label} no es un numero valido!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 16,
  },
};

const { Meta } = Card;

const Step0 = () => {
  const { varsInversion, RegistraVars, InputTypes } = useContext(ComponentesContext);
  
  //console.log("context",varsInversion.RFC.value);

  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const [offsetButton, setoffsetButton] = useState(5);
  const [BtnDisabled, setBtnDisabled] = useState(true);
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ModalContent, SetModalContent] = useState("");

  const [value, setValue] = useState("");

  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    //console.log("USER HOOK", usr);
    setUser(usr);

    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate("/login");
    }
  }, []);

  const FormValidateMessages = {
    required: "${label} es requerido!",
    types: {
      email: "${label} no es valido!",
      number: "${label} no es un numero valido!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  // const handleChange = (event) => {

  //   const { value: inputValue } = event.target;
  //   const reg = /^-?\d*(\.\d*)?$/;
  //   if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
  //     onChange(inputValue);
  //   }

  // };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const showModal = (strContent) => {
    SetModalContent(strContent);
    setIsModalOpen(true);
  };

  const handleNext = () => {
    let data = form.getFieldsValue();

    if (data.prospecto.rfc.length == 0) {
      showModal("El RFC no puede estar en blanco");
    } else {
      if (data.prospecto.rfc.length < 12) {
        showModal("La longitud del RFC no puede ser menor a 12 digitos");
      } else {
        getRFCData(data.prospecto.rfc);
      }
    }
  };

  const getRFCData = async (rfc) => {
    // let RFCData = await InversionistaService.postRFC({
    //   rfc: rfc,
    // });
    // varsInversion.RFC.estatus = RFCData.estado;
    // RegistraVars(varsInversion);

    navigate("/simulador-generar", {
      state: {
        record: { rfc: rfc, status: 1 },
      },
    });
  };

  const onFinish = async (values) => {
    //console.log(values);
    //
  };

  const handleWindowResize = () => {
    if (window.innerWidth > 575) {
      setoffsetButton(4);
    } else {
      setoffsetButton(0);
    }
  };

  window.addEventListener("resize", handleWindowResize);

  return (
    <Structure breadcrumb={"Inicio / Inversiones / Simulador"}>
      <MainInversion step={0}>
        <center>
          <Divider>
            <Typography.Title level={3} style={{ margin: 0, color: "#081b3e" }}>
              Inversionista
            </Typography.Title>
          </Divider>

          <Form
            form={form}
            {...layout}
            name="nest-messages"
            onFinish={onFinish}
            style={{
              maxWidth: 600,
            }}
            validateMessages={FormValidateMessages}
          >
            <Form.Item
              name={["prospecto", "nombre"]}
              label="Nombre cliente"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputCustom inputtype={InputTypes.Text} key={1} />
            </Form.Item>

            <Form.Item name={["prospecto", "razonSocial"]} label="Razon social">
              <InputCustom inputtype={InputTypes.Text} key={2} />
            </Form.Item>

            <Form.Item name={["prospecto", "rfc"]} label="RFC:" rules={[{}]}>
              <RfcInput
                value={varsInversion.inversionista.RFC}
                onChange={setValue}
                onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
              />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
                offset: offsetButton,
              }}
            >
              <Button
                style={{ width: "100%" }}
                type="primary"
                onClick={handleNext}
              >
                Continuar <ArrowRightOutlined />
              </Button>
            </Form.Item>
          </Form>
        </center>
        <Modal
          title={
            <div>
              <MessageOutlined /> &nbsp; Mensaje
            </div>
          }
          open={isModalOpen}
          onCancel={handleModalClose}
          footer={[
            <Button type="primary" onClick={handleModalClose}>
              OK
            </Button>,
          ]}
        >
          <Typography.Title
            level={5}
            style={{ margin: 20, color: "#081b3e", marginBottom: -10 }}
          >
            {ModalContent}
          </Typography.Title>
        </Modal>
      </MainInversion>
    </Structure>
  );
};
export default Step0;
