import React, { useEffect, useState, createContext }  from "react";
import "./Main.css";
import { Layout, Breadcrumb, theme, Steps } from "antd";
import { Content, Header } from "antd/es/layout/layout";


import {
  SearchOutlined,
  IdcardOutlined,
  FundProjectionScreenOutlined,
  BankOutlined,
  AuditOutlined,
} from "@ant-design/icons";

export default function MainInversion({ children, step }) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  

  const [titleState0, settitleState0] = useState("Buscar inversionista");
  const [titleState1, settitleState1] = useState("Inversionista");
  const [titleState2, settitleState2] = useState("Simulación rendimiento");
  const [titleState3, settitleState3] = useState("Datos Bancarios");
  const [titleState4, settitleState4] = useState("Formalización");

  const handleWindowResize = () => {
    if (window.innerWidth > 1000) {
      settitleState0("Buscar inversionista");
      settitleState1("Inversionista");
      settitleState2("Simulación rendimiento");
      settitleState3("Datos Bancarios");
      settitleState4("Formalización");
    } else {
      settitleState0("");
      settitleState1("");
      settitleState2("");
      settitleState3("");
      settitleState4("");
    }
  };

  window.addEventListener("resize", handleWindowResize);

  return (
    <div
      style={{
        padding: 0,
        background: colorBgContainer,
        borderRadius: 10,
      }}
    >
      
      <Layout>
        <Header style={{ background: colorBgContainer, marginBottom: -20 }}>
          <Steps
            current={step}
            responsive={false}
            items={[
              { title: titleState0, icon: <SearchOutlined /> },
              { title: titleState1, icon: <IdcardOutlined /> },
              {
                title: titleState2,
                icon: <FundProjectionScreenOutlined />,
              },
              { title: titleState3, icon: <BankOutlined /> },
              { title: titleState4, icon: <AuditOutlined /> },
            ]}
          />
        </Header>
        <Content
          style={{
            background: colorBgContainer,
            maxHeight: window.innerHeight - 260,
            overflow: "auto",
          }}
        >
          {children}
        </Content>
      </Layout>
      
    </div>
  );
}
