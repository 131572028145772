import AuthService from "../services/auth.service";

const getBancos = () => {
  const URL = process.env.REACT_APP_URL_API;

  const resp = fetch(`${URL}bancos/all`, {
    method: "GET",
    headers: {
      "Authorization": AuthService.getCurrentToken()
    },
  })
    .then((response) => response.json())
    .then((responseData) => {

      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":500};
        }
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postSaveDatosBancarios = (json) => {
  const URL = process.env.REACT_APP_URL_API;
  const resp = fetch(`${URL}datos/bancarios/save`, {
    method: "POST",
    headers: {
      "Authorization": AuthService.getCurrentToken(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":500};
        }
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};


const postCuentasBancarias = (json) => {
  const URL = process.env.REACT_APP_URL_API;
  const resp = fetch(`${URL}datos/bancarios/view/all`, {
    method: "POST",
    headers: {
      "Authorization": AuthService.getCurrentToken(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":500};
        }
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const deleteCuenta = (getParameter) => {
  const URL = process.env.REACT_APP_URL_API;

  //console.log(AuthService.getCurrentToken());

  const resp = fetch(`${URL}datos/bancarios/${getParameter}`, {
    method: "DELETE",
    headers: {
      "Authorization": AuthService.getCurrentToken()
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.path == 'undefined') {
        return {"estado":500};
      }
      else{
        return {"estado":200, "response": responseData};
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const UsuarioService = {
  getBancos,
  postSaveDatosBancarios,
  postCuentasBancarias,
  deleteCuenta
};

export default UsuarioService;
