import React, { useState, useEffect, useContext } from "react";
import AuthService from "../../services/auth.service";
import InversionistaService from "../../services/inversionista.Service";
import { useNavigate, useLocation } from "react-router-dom";
import { ComponentesContext } from "../../contextos/componentesContext";
import {
  Col,
  Row,
  Typography,
  Divider,
  Radio,
  Form,
  Input,
  Button,
  Select,
  Modal,
  InputNumber,
  Skeleton,
  Tooltip,
} from "antd";
import Structure from "../../componentes/Structure";
import MainInversion from "./Main";
import DatePickerCustom from "../../componentes/DatePickerCustom";
import dayjs from "dayjs";
import {
  ArrowRightOutlined,
  MessageOutlined,
  PhoneOutlined,
  ExclamationCircleFilled,
  ArrowLeftOutlined
} from "@ant-design/icons";
import InputCustom from "../../componentes/InputCustom";

import SearchCustom from "../../componentes/SearchCustom";

const { Search } = Input;

const Step1 = () => {
  const { CloseSession, ShowLoading, InputTypes, ModalMessage } =
    useContext(ComponentesContext);
  const location = useLocation();
  const navigate = useNavigate();
  const dateFormat = "DD-MM-YYYY";
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const [value, setValue] = useState(1);
  const [PfaeisVisible, setIsPfaeisVisible] = useState(true);
  const [PmisVisible, setPmisVisible] = useState(false);
  const [OptionsIdentificacion, setOptionsIdentificacion] = useState([]);
  const [OptionsEstados, setOptionsEstados] = useState([]);
  const [OptionsColonia, setOptionsColonia] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ModalContent, SetModalContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [PfDisabled, setPfDisabled] = useState(false);
  const [PmDisabled, setPmDisabled] = useState(false);
  const [PfTooltip, setPfTooltip] = useState("");
  const [PmTooltip, setPmTooltip] = useState("");

  const [RFC, setRFC] = useState(
    typeof location.state.record.rfc != "undefined"
      ? location.state.record.rfc != null
        ? location.state.record.rfc
        : ""
      : ""
  );
  const [statusRFC, setstatusRFC] = useState(
    typeof location.state.record.status != "undefined"
      ? location.state.record.status != null
        ? location.state.record.status
        : ""
      : ""
  );

  const [codInv, setcodInv] = useState(
    typeof location.state.record.codInv != "undefined"
      ? location.state.record.codInv != null
        ? location.state.record.codInv
        : ""
      : ""
  );

  const layout = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 23,
    },
  };

  const FormValidateMessages = {
    required: "${label} es requerido!",
    types: {
      email: "${label} no es valido!",
      number: "${label} no es un numero valido!",
    },
    number: {
      min: "${label} Debe de tener almenos ${min} caracteres!",
    },
    string: {
      min: "${label} Debe de tener almenos ${min} caracteres!",
    },
  };

  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    //setUser(usr);
    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate("/login");
    }
    setIdentificacionData();
    setEstadosData();
    //console.log("status rfc", statusRFC);
    setFormData(statusRFC, RFC);
  }, []);

  //----------LLENA OBJETOS------------------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------------------------
  const setFormData = async (status, rfc) => {
    if (status == "0") {
      let RFCData = await InversionistaService.postRFC({
        rfc: rfc,
      });

      if (RFCData.estado == 403) {
        CloseSession();
        return false;
      }

      if (RFCData.estado == 500) {
        ModalMessage("Mensaje", "Se género un error al cargar los datos.");
        return false;
      }

      let fecha = RFCData.data.inversionista.fechaNacimiento;

      if (fecha !== null) {
        RFCData.data.inversionista.fechaNacimiento = dayjs(
          fecha.substring(8, 10) +
            "/" +
            fecha.substring(5, 7) +
            "/" +
            fecha.substring(0, 4),
          dateFormat
        );
      }

      setOptionsColonia([
        {
          colonia: RFCData.data.renapo.colonia,
          idColoniaCpCons: RFCData.data.renapo.idColoniaCpCons,
        },
      ]);

      form.setFieldsValue({ inversionista: RFCData.data.inversionista });
      form.setFieldsValue({ renapo: RFCData.data.renapo });

      if (RFCData.data.inversionista.codRegFiscal == 2) {
        setIsPfaeisVisible(false);
        setPmisVisible(true);
      }
    } else {
      var regFiscal = 1;
      if (rfc.length == 12) {
        var regFiscal = 2;

        setIsPfaeisVisible(false);
        setPmisVisible(true);
      }

      //se uso setTimeOut por que sin el se generaba un warning.
      setTimeout(function () {
        form.setFieldsValue({
          inversionista: {
            codRegFiscal: regFiscal,
            rfc: RFC,
          },
        });
      }, 0);
    }

    if (rfc.length == 13) {
      setPfDisabled(false);
      setPmDisabled(true);

      setPmTooltip(
        "No se puede cambiar ya que el rfc ingresado es de persona física"
      );
      setPfTooltip("");
    } else {
      setPfDisabled(true);
      setPmDisabled(false);

      setPmTooltip("");
      setPfTooltip(
        "No se puede cambiar ya que el rfc ingresado es de persona moral"
      );
    }

    setLoading(false);
  };

  const setIdentificacionData = async () => {
    let identificacionesData = await InversionistaService.getIdentificaciones();
    if (identificacionesData.status == 403) {
      CloseSession();
      return false;
    }
    setOptionsIdentificacion(identificacionesData.data);
  };

  const setEstadosData = async () => {
    let estadosData = await InversionistaService.getEstados();
    if (estadosData.status == 403) {
      CloseSession();
      return false;
    }

    setOptionsEstados(estadosData.data);
  };

  const getCpData = async (strCp) => {
    ShowLoading(true);
    let cpData = await InversionistaService.postCp({
      cp: strCp,
    });
    ShowLoading(false);

    if (cpData.estado == 403) {
      CloseSession();
      return false;
    }

    if (cpData.estado == 500 || cpData.estado == 99) {
      form.setFieldsValue({
        renapo: {
          estado: "",
          municipio: "",
          ciudad: "",
        },
      });
      setOptionsColonia([]);
      showModal("Codigo postal no localizado.");
    } else {
      form.setFieldsValue({
        renapo: {
          estado: cpData.data[0].estado,
          municipio: cpData.data[0].municipio,
          ciudad: cpData.data[0].ciudad,
        },
      });
      setOptionsColonia(cpData.data);
    }
  };
  //-----------------------------------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------------------------

  //----------EVENTOS------------------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------------------------
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const showModal = (strContent) => {
    SetModalContent(strContent);
    setIsModalOpen(true);
  };

  const onChange = (e) => {
    ClearForm(e.target.value);
    if (e.target.value == 1) {
      setIsPfaeisVisible(true);
      setPmisVisible(false);
    } else {
      setIsPfaeisVisible(false);
      setPmisVisible(true);
    }

    setValue(e.target.value);
  };

  const onSearch = (value, event) => {
    event.preventDefault();
    getCpData(value);
  };

  const onFinish = async (values) => {
    var respuesta = [];
    if (statusRFC != "0") {
      ShowLoading(true);
      respuesta = await InversionistaService.postSaveInversionista(
        values.inversionista
      );
      ShowLoading(false);

      if (respuesta.estado == 403) {
        CloseSession();
        return false;
      }

      if (respuesta.estado == 500) {
        ModalMessage("Mensaje", "Error al guardar el inversionista.");
        return false;
      }
    } else {
      ShowLoading(true);
      respuesta = await InversionistaService.postEditInversionista(
        values.inversionista
      );
      ShowLoading(false);

      if (respuesta.estado == 403) {
        CloseSession();
        return false;
      }

      if (respuesta.estado == 500) {
        ModalMessage("Mensaje", "Error al guardar el inversionista.");
        return false;
      }
    }

    if (respuesta.estado == 0) {
      navigate("/inversion-rendimiento", {
        state: {
          record: {
            rfc: RFC,
            status: respuesta.estado,
            codInv: respuesta.data.inversionista.codInv,
          },
        },
      });
    } else {
      showModal("ERROR AL REGISTRAR AL INVERSIONISTA");
      //console.log("ERROR AL REGISTRAR AL INVERSIONISTA");
    }
  };

  const ClearForm = (option) => {
    if (option != 2) {
      form.setFieldsValue({
        inversionista: {
          primerNombre: "",
          segundoNombre: "",
          apellidoPaterno: "",
          apellidoMaterno: "",
          sexo: "",
          fechaNacimiento: "",
          curp: "",
          codEdoNac: "",
          tipoIdentificacion: "",
          folioIdentificacion: "",
          correo: "",
          correoFactura: "",
          tel: "",
        },
      });
    } else {
      form.setFieldsValue({
        inversionista: {
          razonSocial: "",
          razonSocialFacturacion: "",
          apoderadoRepLeg: "",
          correo: "",
          correoFactura: "",
          tel: "",
        },
      });
    }
  };

  const showConfirm = (e) => {
    if (statusRFC == "0") {
      confirm({
        title:
          "El inversionista ya esta registrado, esta segur@ de cambiar su régimen fiscal?",
        icon: <ExclamationCircleFilled />,
        onOk() {
          onChange(e);
        },
        onCancel() {
          var val = 1;
          if (e.target.value == 1) {
            val = 2;
          } else {
            val = 1;
          }
          form.setFieldsValue({
            inversionista: {
              codRegFiscal: val,
            },
          });
        },
      });
    } else {
      onChange(e);
    }
  };

  const handleBack = () => {
    navigate("/inversion-inicio");
  };
  //-----------------------------------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------------------------

  return (
    <Structure breadcrumb={"Inicio / Inversiones"}>
      <MainInversion step={1}>
        <center>
          <Typography.Title
            level={2}
            style={{ margin: 0, color: "#081b3e", marginBottom: -10 }}
          >
            Información de inversionista
          </Typography.Title>
          <Divider>
            <Typography.Title level={3} style={{ margin: 0, color: "#081b3e" }}>
              Régimen fiscal
            </Typography.Title>
          </Divider>
          <Skeleton loading={loading} active={true}>
            <Form
              {...layout}
              form={form}
              style={{ maxWidth: 800 }}
              layout="vertical"
              onFinish={onFinish}
              validateMessages={FormValidateMessages}
              scrollToFirstError
            >
              <Form.Item name={["inversionista", "codRegFiscal"]}>
                <Radio.Group
                  name="codRegFiscal"
                  onChange={(e) => {
                    showConfirm(e);
                  }}
                  value={value}
                >
                  <Tooltip placement="left" title={PfTooltip}>
                    <Radio value={1} disabled={PfDisabled}>
                      <Typography.Title
                        level={5}
                        style={{
                          margin: 0,
                          color: "#081b3e",
                          marginBottom: -10,
                        }}
                      >
                        PF -Persona Física
                      </Typography.Title>
                    </Radio>
                  </Tooltip>

                  <Tooltip placement="right" title={PmTooltip}>
                    <Radio value={2} disabled={PmDisabled}>
                      <Typography.Title
                        level={5}
                        style={{
                          margin: 0,
                          color: "#081b3e",
                          marginBottom: -10,
                        }}
                      >
                        PM - Persona Moral
                      </Typography.Title>
                    </Radio>
                  </Tooltip>
                </Radio.Group>
              </Form.Item>
              <div
                style={{
                  display: PfaeisVisible ? "block" : "none",
                  marginTop: 20,
                }}
              >
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name={["inversionista", "primerNombre"]}
                      label="Primer Nombre"
                      rules={[
                        {
                          required: PfaeisVisible,
                        },
                      ]}
                    >
                      <InputCustom inputtype={InputTypes.Text} key={1} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name={["inversionista", "segundoNombre"]}
                      label="Segundo Nombre"
                    >
                      <InputCustom inputtype={InputTypes.Text} key={2} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Form.Item
                      name={["inversionista", "apellidoPaterno"]}
                      label="Apellido paterno"
                      rules={[
                        {
                          required: PfaeisVisible,
                        },
                      ]}
                    >
                      <InputCustom inputtype={InputTypes.Text} key={3} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name={["inversionista", "apellidoMaterno"]}
                      label="Apellido materno"
                    >
                      <InputCustom inputtype={InputTypes.Text} key={4} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Form.Item
                      name={["inversionista", "sexo"]}
                      label="Sexo"
                      rules={[
                        {
                          required: PfaeisVisible,
                        },
                      ]}
                    >
                      <Select
                        style={{ textAlign: "left" }}
                        options={[
                          { value: "F", label: "Femenino" },
                          { value: "M", label: "Masculino" },
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name={["inversionista", "fechaNacimiento"]}
                      label="Fecha de nacimiento"
                      rules={[
                        {
                          required: PfaeisVisible,
                        },
                      ]}
                    >
                      <DatePickerCustom
                        format={dateFormat}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Form.Item
                      name={["inversionista", "rfc"]}
                      label="RFC"
                      rules={[
                        {
                          required: PfaeisVisible,
                        },
                      ]}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name={["inversionista", "curp"]}
                      label="CURP"
                      rules={[
                        {
                          type: "string",
                          required: PfaeisVisible,
                          min:18
                        },
                      ]}
                    >
                      <InputCustom inputtype={InputTypes.Curp} key={5} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Form.Item
                      name={["inversionista", "codEdoNac"]}
                      label="Estado Nacimiento"
                      rules={[
                        {
                          required: PfaeisVisible,
                        },
                      ]}
                    >
                      <Select
                        style={{ textAlign: "left" }}
                        options={OptionsEstados}
                        placeholder="Seleccione una opción"
                        fieldNames={{
                          label: "descripcion",
                          value: "cod_estado",
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name={["inversionista", "tipoIdentificacion"]}
                      label="Identificación"
                      rules={[
                        {
                          required: PfaeisVisible,
                        },
                      ]}
                    >
                      <Select
                        style={{ textAlign: "left" }}
                        options={OptionsIdentificacion}
                        placeholder="Seleccione una opción"
                        fieldNames={{ label: "descripcion", value: "id" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Form.Item
                      name={["inversionista", "folioIdentificacion"]}
                      label="Folio"
                      rules={[
                        {
                          required: PfaeisVisible,
                        },
                      ]}
                    >
                      <InputCustom inputtype={InputTypes.Text} key={6} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name={["inversionista", "correo"]}
                      label="Correo"
                      rules={[
                        {
                          type: "email",
                          required: PfaeisVisible,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Form.Item
                      name={["inversionista", "correoFactura"]}
                      label="Correo facturación"
                      rules={[
                        {
                          type: "email",
                          required: PfaeisVisible,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name={["inversionista", "tel"]}
                      label="Telefono"
                      rules={[
                        {
                          type: "string",
                          required: PfaeisVisible,
                          min: 10
                        },
                      ]}
                    >
                      <InputCustom  addonBefore={<PhoneOutlined />} style={{ width: "100%" }} maxLength={10} inputtype={InputTypes.Integer} key={15} />
                      
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <div style={{ display: PmisVisible ? "block" : "none" }}>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name={["inversionista", "razonSocial"]}
                      label="Razon social"
                      rules={[
                        {
                          required: PmisVisible,
                        },
                      ]}
                    >
                      <Input style={{ width: 785 }} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name={["inversionista", "razonSocialFacturacion"]}
                      label="Razon social facturación"
                      rules={[
                        {
                          required: PmisVisible,
                        },
                      ]}
                    >
                      <Input style={{ width: 785 }} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name={["inversionista", "rfc"]}
                      label="RFC"
                      rules={[
                        {
                          required: PmisVisible,
                        },
                      ]}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name={["inversionista", "apoderadoRepLeg"]}
                      label="Representante legal"
                      rules={[
                        {
                          required: PmisVisible,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={8}>
                    <Form.Item
                      name={["inversionista", "correo"]}
                      label="Correo"
                      rules={[
                        {
                          required: PmisVisible,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      name={["inversionista", "correoFactura"]}
                      label="Correo facturación"
                      rules={[
                        {
                          required: PmisVisible,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      name={["inversionista", "tel"]}
                      label="Telefono"
                      rules={[
                        {
                          type: "string",
                          required: PfaeisVisible,
                          min: 10
                        },
                      ]}
                    >
                      <InputCustom  addonBefore={<PhoneOutlined />} style={{ width: "100%" }} maxLength={10} inputtype={InputTypes.Integer} key={16} />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Divider>
                <Typography.Title
                  level={3}
                  style={{ margin: 0, color: "#081b3e" }}
                >
                  Domicilio
                </Typography.Title>
              </Divider>

              <Row>
                <Col span={6}>
                  <Form.Item
                    name={["inversionista", "cp"]}
                    label="C.P."
                    rules={[
                      {
                        type: "string",
                        required: true,
                        min:5
                      },
                    ]}
                  >
                    <SearchCustom inputtype={InputTypes.Integer} maxLength={5} placeholder="Ingrese un C.P." onSearch={onSearch} />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item name={["renapo", "estado"]} label="Estado">
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item name={["renapo", "municipio"]} label="Municipio">
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item name={["renapo", "ciudad"]} label="Ciudad">
                    <InputCustom inputtype={InputTypes.Text} key={7} />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={6}>
                  <Form.Item
                    name={["inversionista", "codColonia"]}
                    label="Colonia"
                    rules={[{required: true}]}
                  >
                    <Select
                      style={{ textAlign: "left" }}
                      options={OptionsColonia}
                      placeholder="Seleccione una opción"
                      fieldNames={{
                        label: "colonia",
                        value: "idColoniaCpCons",
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={18}>
                  <Form.Item
                    name={["inversionista", "calle"]}
                    label="Calle"
                    rules={[{}]}
                  >
                    <InputCustom
                      inputtype={InputTypes.Text}
                      style={{ width: 590 }}
                      key={8}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={8}>
                  <Form.Item
                    name={["inversionista", "numExt"]}
                    label="Numero ext."
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <InputCustom
                      inputtype={InputTypes.Integer}
                      style={{ width: "100%" }}
                      key={9}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name={["inversionista", "numInt"]}
                    label="Numero int."
                  >
                    <InputCustom
                      inputtype={InputTypes.Integer}
                      style={{ width: "100%" }}
                      key={10}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name={["inversionista", "referenciaDomicilio"]}
                    label="Referencia(calles)"
                  >
                    <InputCustom inputtype={InputTypes.Text} key={11} />
                  </Form.Item>
                </Col>
              </Row>
              {/* <div style={{ display: PfaeisVisible ? "block" : "none" }}>
                <Divider></Divider>
                <Collapse bordered={true} defaultActiveKey={["0"]}>
                  <Panel
                    header={
                      <>
                        <Typography.Title
                          level={4}
                          style={{
                            margin: 0,
                            color: "#081b3e",
                            marginBottom: -10,
                          }}
                        >
                          <UserAddOutlined /> Captura de beneficiario
                        </Typography.Title>
                      </>
                    }
                    key="1"
                  >
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          name={["beneficiario", "primerNombre"]}
                          label="Primer Nombre"
                          rules={[
                            {
                              
                            },
                          ]}
                        >
                          <InputCustom inputtype={InputTypes.Text} key={12} />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          name={["beneficiario", "segundoNombre"]}
                          label="Segundo Nombre"
                        >
                          <InputCustom inputtype={InputTypes.Text} key={13} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <Form.Item
                          name={["beneficiario", "apellidoPaterno"]}
                          label="Apellido paterno"
                          rules={[
                            {
                              
                            },
                          ]}
                        >
                          <InputCustom inputtype={InputTypes.Text} key={14} />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          name={["beneficiario", "apellidoMaterno"]}
                          label="Apellido materno"
                        >
                          <InputCustom inputtype={InputTypes.Text} key={15} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <Form.Item
                          name={["beneficiario", "fechaNacimiento"]}
                          label="Fecha de nacimiento"
                          rules={[
                            {
                              
                            },
                          ]}
                        >
                          <DatePickerCustom
                            format={dateFormat}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          name={["beneficiario", "parentesco"]}
                          label="Parentesco"
                        >
                          <Select
                            style={{ textAlign: "left" }}
                            options={[
                              { id: '0', descripcion: 'Esposo(a)' },
                              { id: '1', descripcion: 'Hermano(a)' },
                              { id: '2', descripcion: 'Padre o Madre' },
                              { id: '3', descripcion: 'Otro parentesco'},
                            ]}
                            placeholder="Seleccione una opción"
                            fieldNames={{ label: "descripcion", value: "id" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </div> */}
              {/* <Form.Item>
                <div style={{ width: "100%", marginTop: 30, marginBottom: 10 }}>
                  <Button
                    style={{ width: 300 }}
                    type="primary"
                    htmlType="submit"
                  >
                    Registrar y continuar <ArrowRightOutlined />
                  </Button>
                </div>
              </Form.Item> */}
              <div style={{ width: "100%", marginTop: 15, paddingLeft:178, paddingRight:178 }}>
              <Row style={{  marginTop: 10 }}>
                <Col span={11}>
                  <Button
                    style={{ width: 190 }}
                    type="primary"
                    onClick={() => handleBack()}
                    icon={<ArrowLeftOutlined />}
                  >
                    Atras
                  </Button>
                </Col>
                <Col>
                  <Button
                    style={{ width: 190, marginBottom: 10 }}
                    type="primary"
                    // onClick={() => handleNext()}
                    htmlType="submit"
                  >
                    Registrar y Continuar <ArrowRightOutlined />
                  </Button>
                </Col>
              </Row>
              </div>
            </Form>
          </Skeleton>
        </center>

        <Modal
          title={
            <div>
              <MessageOutlined /> &nbsp; Mensaje
            </div>
          }
          open={isModalOpen}
          onCancel={handleModalClose}
          footer={[
            <Button type="primary" onClick={handleModalClose}>
              OK
            </Button>,
          ]}
        >
          <Typography.Title
            level={5}
            style={{ margin: 20, color: "#081b3e", marginBottom: -10 }}
          >
            {ModalContent}
          </Typography.Title>
        </Modal>
      </MainInversion>
    </Structure>
  );
};
export default Step1;
