import React, { useState, useEffect, useContext } from "react";
import AuthService from "../../services/auth.service";
import DatosBancariosService from "../../services/datosBancarios.Service";
import { useNavigate, useLocation } from "react-router-dom";
import { ComponentesContext } from "../../contextos/componentesContext";
import {
  Col,
  Row,
  Typography,
  Divider,
  Form,
  Select,
  Button,
  Modal,
  Table,
} from "antd";
import Structure from "../../componentes/Structure";
import MainInversion from "./Main";
import InputCustom from "../../componentes/InputCustom";
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  BankOutlined,
  FileAddOutlined,
  FileDoneOutlined,
  DeleteOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons";

const Step3 = () => {
  const { CloseSession, ShowLoading, InputTypes, ModalMessage } =
    useContext(ComponentesContext);
  const location = useLocation();
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [optBancos, setoptBancos] = useState([]);

  const [isModalOpenBorrar, setisModalOpenBorrar] = useState(false);
  const [modalButtonDisabled, setmodalButtonEnabled] = useState(true);
  const [idCuentaBancariaBorrar, setidCuentaBancariaBorrar] = useState(null);

  const [cuentaSeleccionada, setcuentaSeleccionada] = useState({});
  const [idCuentaBancaria, setidCuentaBancaria] = useState(null);
  const [columnCuentas, setcolumnCuentas] = useState([
    {
      title: "Banco",
      dataIndex: "banco",
      key: "banco",
    },
    {
      title: "Clabe interbancaria",
      dataIndex: "noClabeInterbancaria",
      key: "noClabeInterbancaria",
    },
    {
      title: "No. Cuenta",
      dataIndex: "noCuenta",
      key: "noCuenta",
    },

    {
      title: "",
      width: 140,
      render: (_, record) => (
        <div>
          <a
            className="button"
            onClick={() => HandleOpenModalBorrar(record)}
            style={{ width: 50 }}
          >
            <span><DeleteOutlined /></span>
          </a>
        </div>
      ),
    }

  ]);

  

  const [dataSource, setDataSource] = useState([]);
  const [RFC, SetRFC] = useState(
    typeof location.state.record.rfc != "undefined"
      ? location.state.record.rfc != null
        ? location.state.record.rfc
        : ""
      : ""
  );
  const [statusRFC, SetstatusRFC] = useState(
    typeof location.state.record.status != "undefined"
      ? location.state.record.status != null
        ? location.state.record.status
        : ""
      : ""
  );
  const [codInv, SetcodInv] = useState(
    typeof location.state.record.codInv != "undefined"
      ? location.state.record.codInv != null
        ? location.state.record.codInv
        : ""
      : ""
  );

  const [idInversion, SetidInversion] = useState(
    typeof location.state.record.idInversion != "undefined"
      ? location.state.record.idInversion != null
        ? location.state.record.idInversion
        : ""
      : ""
  );
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setcuentaSeleccionada(selectedRows[0]);
      setmodalButtonEnabled(false);
    },
  };
  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    setUser(usr);

    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate("/login");
    }
    setBancosData(codInv);
  }, []);

  const setBancosData = async (codinv) => {
    ShowLoading(true);
    let bancosData = await DatosBancariosService.getBancos();

    if (bancosData.estado == 403) {
      CloseSession();
      return false;
    }

    //console.log(bancosData);

    let bancos = await AddItemComboBank(bancosData.bancos);
    setoptBancos(bancos);

    let CuentasBancarias = await DatosBancariosService.postCuentasBancarias({
      codInv: codinv,
    });

    if (CuentasBancarias.estado == 403) {
      CloseSession();
      return false;
    }

    if (CuentasBancarias.estado == 500) {
      return false;
    }

    let Respuesta = await AddNameBank(CuentasBancarias.data, bancos);
    setDataSource(Respuesta);

    if (Respuesta.length > 0) {
      setIsModalOpen(true);
    }

    ShowLoading(false);
  };
  
  const AddItemComboBank = async (arrayBancos) => {
    for (const element of arrayBancos) {
      element.value = element.id;
      element.label = element.descripcion;
    }

    return arrayBancos;
  };

  const AddNameBank = async (arrayCuentas, arrayBancos) => {
    for (const element of arrayCuentas) {
      let nombreBanco = arrayBancos
        .filter((item) => item.id === element.idBanco)
        .map((item) => item.descripcion);

      element.banco = nombreBanco[0];
      element.key = element.id;
    }
    return arrayCuentas;
  };

  const onFinish = async (values) => {
    let respuesta = [];
    values.cuentas.id = idCuentaBancaria;
    values.cuentas.codInversionista = codInv;
    values.cuentas.idInversion = idInversion;
    
    //console.log("finish",values);

    if (typeof values.cuentas.idBanco == "undefined") {
      ModalMessage("Mensaje","Debe seleccionar un banco." );
      return false;
    }

    if (typeof values.cuentas.noCuenta == "undefined"){
      ModalMessage("Mensaje","El numero de cuenta no puede estar en blanco." );
      return false;
    }

    if(values.cuentas.noCuenta.length<10){
      ModalMessage("Mensaje","El número de cuenta no puede ser menor a 10 dígitos." );
      return false;
    }

    if (typeof values.cuentas.noClabeInterbancaria == "undefined"){
      ModalMessage("Mensaje","El número de clabe no puede estar en blanco." );
      return false;
    }

    if(values.cuentas.noClabeInterbancaria.length<18){
      ModalMessage("Mensaje","El numero de Clabe no puede ser menor a 18 dígitos." );
      return false;
    }
    
    respuesta = await DatosBancariosService.postSaveDatosBancarios(
      values.cuentas
    );

    // navigate("/inversion-contrato", {
    //   state: { record: { rfc: RFC, status: statusRFC, codInv: codInv } },
    // });

    if (respuesta.estado == 0) {
      //console.log("SE REGISTRO CORRECTAMENTE EL INVERSIONISTA");
      navigate("/inversion-contrato", {
        state: { record: { rfc: RFC, status: statusRFC, codInv: codInv, idInversion: idInversion } },
      });
    } else {
      
      if (respuesta.estado == 403) {
        CloseSession();
        return false;
      }

      if (respuesta.estado == 500) {
        ModalMessage("Mensaje","Error al guardar los datos bancarios." );
        return false;
      }

    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };


  const HandleOpenModalBorrar = (cuenta) =>{
    setidCuentaBancariaBorrar(cuenta.id);
    setisModalOpenBorrar(true);
  }

  const HandleCloseModalBorrar = ()  =>{
    setisModalOpenBorrar(false);
  }

  const HandleBorrarCuenta = async() => {

    if (idCuentaBancariaBorrar !== null) {
      let respuestaBorrado = await DatosBancariosService.deleteCuenta(idCuentaBancariaBorrar);
      //console.log(respuestaBorrado);

      let CuentasBancarias = await DatosBancariosService.postCuentasBancarias({
        codInv: codInv,
      });
  
      if (CuentasBancarias.estado == 403) {
        CloseSession();
        return false;
      }
  
      if (CuentasBancarias.estado == 500) {
        return false;
      }

      let bancosData = await DatosBancariosService.getBancos();

      if (bancosData.estado == 403) {
        CloseSession();
        return false;
      }

      let bancos = await AddItemComboBank(bancosData.bancos);
  
      let Respuesta = await AddNameBank(CuentasBancarias.data, bancos);
      setDataSource(Respuesta);
  
      if (Respuesta.length == 0) {
        setIsModalOpen(false);
      }

    }
    else{
      ModalMessage("Mensaje","Se produjo un error al borrar el registro.");
    }

    setisModalOpenBorrar(false);
    
  }

  const handleUseAccount = () => {

    if (cuentaSeleccionada.length == 0) {
      console.log("No se ha seleccionado ninguna Cuenta");
    } else {
      form.setFieldsValue({
        cuentas: {
          idBanco: cuentaSeleccionada.idBanco,
          noCuenta: cuentaSeleccionada.noCuenta,
          noClabeInterbancaria: cuentaSeleccionada.noClabeInterbancaria,
        },
      });
      setidCuentaBancaria(cuentaSeleccionada.id);
      setIsModalOpen(false);
    }
  };

  const onChange = (value) => {
  };

  const handleBack = () => {
    navigate("/inversion-rendimiento", {
      state: { record: { rfc: RFC, status: statusRFC, codInv: codInv } },
    });
  };
  
  return (
    <Structure breadcrumb={"Inicio / Inversiones"}>
      <MainInversion step={3}>
        <center>
          <Divider>
            <Typography.Title level={2} style={{ margin: 0, color: "#081b3e" }}>
              Datos bancarios
            </Typography.Title>
          </Divider>
        </center>
        <center>
          <Form
            form={form}
            style={{ marginTop: 20, maxWidth: 380 }}
            name="horizontal_login"
            layout="inline"
            onFinish={onFinish}
          >
            <Row>
              <Col span={12} style={{ textAlign: "left", marginLeft: 30 }}>
                <Form.Item name={["cuentas", "idBanco"]} label="Banco">
                  <Select
                    style={{ width: 320 }}
                    placeholder="Escriba y seleccione una opción"
                    options={optBancos}
                    onChange={onChange}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    onInput={(e) =>
                      (e.target.value = e.target.value.toUpperCase())
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={20} style={{ textAlign: "left", marginLeft: 30 }}>
                <Form.Item name={["cuentas", "noCuenta"]} label="No. Cuenta">
                  <InputCustom
                    inputtype={InputTypes.Integer}
                    key={1}
                    style={{ width: 320 }}
                    maxLength={11}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={20} style={{ textAlign: "left", marginLeft: 30 }}>
                <Form.Item
                  name={["cuentas", "noClabeInterbancaria"]}
                  label="No. Clabe"
                >
                  <InputCustom
                    inputtype={InputTypes.Integer}
                    key={2}
                    style={{ width: 320 }}
                    maxLength={19}
                  />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ width: "100%", marginTop: 20, paddingLeft:20, paddingRight:10 }}>
              {/* <Button
                style={{ width: 320 }}
                type="primary"
                htmlType="submit"
                icon={<ArrowRightOutlined />}
              >
                Continuar
              </Button> */}
              <Row style={{  marginTop: 10 }}>
                <Col span={12}>
                  <Button
                    style={{ width: 155 }}
                    type="primary"
                    onClick={() => handleBack()}
                    icon={<ArrowLeftOutlined />}
                  >
                    Atras
                  </Button>
                </Col>
                <Col>
                  <Button
                    style={{ width: 155, marginBottom: 10 }}
                    type="primary"
                    // onClick={() => handleNext()}
                    htmlType="submit"
                  >
                    Continuar <ArrowRightOutlined />
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </center>

        <Modal
          closable={false}
          title={
            <div>
              <Typography.Title
                level={4}
                style={{ margin: 0, color: "#081b3e" }}
              >
                <BankOutlined /> Cuentas del inversionista registradas
              </Typography.Title>
            </div>
          }
          maskClosable={false}
          open={isModalOpen}
          onCancel={handleClose}
          width={800}
          footer={[
            <Button
              key={1}
              type="primary"
              onClick={handleClose}
              icon={<FileAddOutlined />}
            >
              Cuenta nueva
            </Button>,
            <Button
            key={0}
            type="primary"
            onClick={handleUseAccount}
            icon={<FileDoneOutlined />}
            disabled={modalButtonDisabled}
          >
            Usar Cuenta seleccionada
          </Button>
          ]}
        >
          <Typography.Title level={5} style={{ margin: 0, color: "#081b3e" }}>
            El inversionista ya tiene registradas las siguientes cuentas, desea
            utilizar alguna para esta inversión:
          </Typography.Title>
          <Table
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            columns={columnCuentas}
            dataSource={dataSource}
            size="small"
            pagination={false}
            scroll={{
              y: 160,
            }}
          />
        </Modal>



        <Modal
          title={
            <div>
              <QuestionCircleOutlined /> &nbsp; Confirmación
            </div>
          }
          open={isModalOpenBorrar}
          onCancel={HandleCloseModalBorrar}
          footer={[
            <Button type="primary" onClick={HandleBorrarCuenta}>
              Si
            </Button>,
            <Button type="primary" onClick={HandleCloseModalBorrar}>
            No
          </Button>
          ]}
        >
          <Typography.Title
            level={5}
            style={{ margin: 20, color: "#081b3e", marginBottom: -10 }}
          >
            Deseas borrar la cuenta seleccionada?
          </Typography.Title>
        </Modal>
      </MainInversion>
    </Structure>
  );
};
export default Step3;
