import React, { useEffect, useState, createContext }  from "react";
import "./Main.css";
import { Layout, Breadcrumb, theme, Steps } from "antd";
import { Content, Header } from "antd/es/layout/layout";


import {
  SearchOutlined,
  IdcardOutlined,
  FundProjectionScreenOutlined,
  BankOutlined,
  AuditOutlined,
} from "@ant-design/icons";

export default function MainInversion({ children, step }) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  

  const [titleState0, settitleState0] = useState("Datos inversionista");
  const [titleState1, settitleState1] = useState("Simulación rendimiento");
  

  const handleWindowResize = () => {
    if (window.innerWidth > 1000) {
      settitleState0("Datos del inversionista");
      settitleState1("Simulación rendimiento");
    } else {
      settitleState0("");
      settitleState1("");
    }
  };

  window.addEventListener("resize", handleWindowResize);

  return (
    <div
      style={{
        padding: 0,
        background: colorBgContainer,
        borderRadius: 10,
      }}
    >
      
      <Layout>
        <Header style={{ background: colorBgContainer, marginBottom: -20 }}>
          <Steps
            current={step}
            responsive={false}
            items={[
              { title: titleState0, icon: <SearchOutlined /> },
              { title: titleState1, icon: <IdcardOutlined /> },
            ]}
          />
        </Header>
        <Content
          style={{
            background: colorBgContainer,
            maxHeight: window.innerHeight - 260,
            overflow: "auto",
          }}
        >
          {children}
        </Content>
      </Layout>
      
    </div>
  );
}
