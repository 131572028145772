import React, { useState, useEffect, useRef, useContext } from "react";
import AuthService from "../../services/auth.service";
import InversionService from "../../services/inversion.Service";
import Highlighter from "react-highlight-words";
import { ComponentesContext } from "../../contextos/componentesContext";
import TasasService from "../../services/tasasBanxico.Service";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import {
  Card,
  Col,
  Row,
  Typography,
  Divider,
  Form,
  Input,
  Slider,
  InputNumber,
  Select,
  Radio,
  Button,
  Modal,
  Table,
  Tooltip,
} from "antd";
import Structure from "../../componentes/Structure";
import MainInversion from "./Main";

import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  FundProjectionScreenOutlined,
  SearchOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

const { Meta } = Card;

const Step2 = () => {
  const { CloseSession } =  useContext(ComponentesContext);
  const [user, setUser] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [value, setValue] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [columnSimulacion, setcolumnSimulacion] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [capitalSimulacion, setcapitalSimulacion] = useState(0);
  const [rendimientoSimulacion, setrendimientoSimulacion] = useState(0);

  const [totalSimulacion, settotalSimulacion] = useState(0);
  const [messageTooltip, setmessageTooltip] = useState("");
  
  const [optTasas, setoptTasas] = useState([
    {
      value: 1,
      label: "UDIS",
      valor: "7.71",
      fecha: "14-02-2023",
    },
    {
      value: 2,
      label: "CTES",
      valor: "10.82",
      fecha: "14-02-2023",
    },
    {
      value: 3,
      label: "TIIE",
      valor: "11.2257",
      fecha: "14-02-2023",
    },
  ]);

  const [show, setshow] = useState(false);
  const [messageError, setmessageError] = useState("");

  const [RFC, SetRFC] = useState(
    typeof location.state.record.rfc != "undefined"
      ? location.state.record.rfc != null
        ? location.state.record.rfc
        : ""
      : ""
  );
  const [statusRFC, SetstatusRFC] = useState(
    typeof location.state.record.status != "undefined"
      ? location.state.record.status != null
        ? location.state.record.status
        : ""
      : ""
  );

  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    //console.log("USER HOOK", usr);
    setUser(usr);

    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate("/login");
    }

    form.setFieldsValue({
      inversion: {
        montoInversion: 0,
        periodos: 12,
        retornoInversion: 1,
        tasaAdicional: 0,
      },
    });

    setTasasData();
  }, []);

  const setTasasData = async () => {
    let fecha = moment().format("YYYY-MM-DD")
    let tasasData = await TasasService.getTasas(fecha);

    if(tasasData.estado==403){
      CloseSession();
        return false;
    }
    setoptTasas(tasasData.tasas);
  };

  const handleBack = () => {
    navigate("/simulador-inicio");
  };

  const showModal = () => {
    let data = form.getFieldsValue();
    SetSimulacionData(data.inversion);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const SetSimulacionData = async (json) => {
    let SimulacionData = await InversionService.postSimulacion(json);
    if (SimulacionData.estado == 500) {
      setmessageError("Error al generar la simulación");
      setshow(false);
    } else {
      if (typeof json.tipoTasa == "undefined") {
        setmessageError(" Debe seleccionar un tipo de tasa.");
        setshow(false);
      } else {
        if (SimulacionData.estado == 10) {
          setmessageError(
            "No se pudo generar la simulación para los datos seleccionados."
          );
          setshow(false);
        } else {
          setshow(true);
          console.log(SimulacionData);

          SimulacionData.arrHeader[0].width = 70;

          setcolumnSimulacion(SimulacionData.arrHeader);

          for (
            let index = 0;
            index < SimulacionData.arrSimulacion.length;
            index++
          ) {
            if (
              typeof SimulacionData.arrSimulacion[index].capFin != "undefined"
            ) {
              SimulacionData.arrSimulacion[index].capFin =
                SimulacionData.arrSimulacion[index].capFin == "0"
                  ? "-"
                  : "$" +
                    SimulacionData.arrSimulacion[index].capFin.toLocaleString(
                      "en",
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    );
            }

            if (
              typeof SimulacionData.arrSimulacion[index].capIni != "undefined"
            ) {
              SimulacionData.arrSimulacion[index].capIni =
                SimulacionData.arrSimulacion[index].capIni == "0"
                  ? "-"
                  : "$" +
                    SimulacionData.arrSimulacion[index].capIni.toLocaleString(
                      "en",
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    );
            }

            SimulacionData.arrSimulacion[index].capitalPago =
              SimulacionData.arrSimulacion[index].capitalPago == "0"
                ? "-"
                : "$" +
                  SimulacionData.arrSimulacion[
                    index
                  ].capitalPago.toLocaleString("en", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });

            SimulacionData.arrSimulacion[index].interes =
              SimulacionData.arrSimulacion[index].interes == "0"
                ? "-"
                : "$" +
                  SimulacionData.arrSimulacion[index].interes.toLocaleString(
                    "en",
                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                  );

            SimulacionData.arrSimulacion[index].pagoTotal =
              SimulacionData.arrSimulacion[index].pagoTotal == "0"
                ? "-"
                : "$" +
                  SimulacionData.arrSimulacion[index].pagoTotal.toLocaleString(
                    "en",
                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                  );
          }

          setDataSource(SimulacionData.arrSimulacion);
          setcapitalSimulacion(
            SimulacionData.arrFooter.capital == "0"
              ? "-"
              : "$ " +
                  SimulacionData.arrFooter.capital.toLocaleString("en", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
          );
          setrendimientoSimulacion(
            SimulacionData.arrFooter.sumaRendimiento == "0"
              ? "-"
              : "$ " +
                  SimulacionData.arrFooter.sumaRendimiento.toLocaleString(
                    "en",
                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                  )
          );
          settotalSimulacion(
            SimulacionData.arrFooter.total == "0"
              ? "-"
              : "$ " +
                  SimulacionData.arrFooter.total.toLocaleString("en", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
          );
        }
      }
    }

    setIsModalOpen(true);

    //console.log(SimulacionData.arrSimulacion);
    //setOptionsEstados(estadosData.data);

    // form.setFieldsValue({
    //   renapo: {
    //     estado: cpData.data[0].estado,
    //     municipio: cpData.data[0].municipio,
    //     ciudad: cpData.data[0].ciudad,
    //   },
    // });
    // setOptionsColonia(cpData.data);
  };

  const columns = [
    {
      title: "No.",
      dataIndex: "numero",
      width: "10%",
    },
    {
      title: "Capital",
      dataIndex: "capital",
      width: "20%",
    },
    {
      title: "Rendimiento",
      dataIndex: "rendimiento",
    },
  ];

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const validateMessages = {
    required: "${label} es requerido!",
    types: {
      email: "${label} no es valido!",
      number: "${label} no es un numero valido!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const marks = {
    12: "12",
    24: "24",
    36: "36",
  };

  const onChange = (value) => {
    //console.log("changed", value);
  };

  const onChangeTipoTasa = (valor) => {
    //console.log(valor);
    let filterNumbers = optTasas.filter((value) => value.value == valor);

    form.setFieldsValue({
      inversion: {
        tipoTasa: valor,
      },
    });

    setmessageTooltip(
      "Valor: " + filterNumbers[0].valor + " Al: " + filterNumbers[0].fecha
    );

    //console.log(filterNumbers);
  };

  const HandleDownload = () => {
    let link = document.createElement("a");
    link.target = "_blank";
    link.type = "application/pdf";
    link.href = "http://192.168.0.91/pdfs/TPI_IV0000001_240202.pdf";
    link.download = "archivo.pdf";

    document.body.appendChild(link);
    link.click();
    //document.body.removeChild(link);
  };

  return (
    <Structure breadcrumb={"Inicio / Inversiones / Simulador"}>
      <MainInversion step={2}>
        <center>
          <Divider>
            <Typography.Title level={2} style={{ margin: 0, color: "#081b3e" }}>
              Simulador de inversión
            </Typography.Title>
          </Divider>
        </center>
        <center>
          <Form
            form={form}
            style={{ marginTop: 20, maxWidth: 380 }}
            name="horizontal_login"
            layout="inline"
          >
            <Row>
              <Col span={12} style={{ textAlign: "left", marginLeft: 30 }}>
                <Form.Item
                  name={["inversion", "montoInversion"]}
                  label="Monto de inversión"
                >
                  <InputNumber
                    defaultValue={0.0}
                    prefix="$"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    onChange={onChange}
                    style={{ width: 320 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Col span={20} style={{ textAlign: "left", marginLeft: 30 }}>
                <Form.Item
                  name={["inversion", "periodos"]}
                  label="En cuantos meses desea invertir?"
                >
                  <Slider
                    min={12}
                    max={36}
                    marks={marks}
                    step={12}
                    defaultValue={12}
                    style={{ width: 308 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={20} style={{ textAlign: "left", marginLeft: 30 }}>
                <Form.Item
                  name={["inversion", "tipoTasa"]}
                  label="Tasa de interes"
                >
                  <Tooltip placement="right" title={messageTooltip}>
                    <Select
                      style={{ width: 320 }}
                      placeholder="Seleccione una opción"
                      options={optTasas}
                      onChange={onChangeTipoTasa}
                    />
                  </Tooltip>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={20} style={{ textAlign: "left", marginLeft: 30 }}>
                <Form.Item
                  name={["inversion", "tasaAdicional"]}
                  label="Tasa adicional"
                >
                  <InputNumber
                    defaultValue={0}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    onChange={onChange}
                    style={{ width: 320 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={20} style={{ textAlign: "left", marginLeft: 30 }}>
                <Form.Item
                  name={["inversion", "retornoInversion"]}
                  label="Retorno mensual"
                >
                  <Radio.Group onChange={onChange} value={value}>
                    <Radio value={1}>Rendimiento</Radio>
                    <Radio value={2}>Capital + Rendimiento</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <div style={{ width: "100%", marginTop: 15 }}>
              <Button
                style={{ width: 320 }}
                type="primary"
                onClick={() => showModal()}
                icon={<FundProjectionScreenOutlined />}
              >
                Mostrar tabla de inversión
              </Button>

              <Button
                style={{ width: 320, marginTop: 5 }}
                type="primary"
                onClick={() => handleBack()}
                icon={<ArrowLeftOutlined />}
              >
                Atras
              </Button>
            </div>
          </Form>
        </center>

        <Modal
          closable={false}
          title={
            <div>
              <Typography.Title
                level={4}
                style={{ margin: 0, color: "#081b3e" }}
              >
                <FundProjectionScreenOutlined /> Simulador de inversión
              </Typography.Title>
            </div>
          }
          open={isModalOpen}
          onCancel={handleClose}
          width={800}
          maskClosable={false}
          footer={[
            <Button type="primary" onClick={handleClose}>
              OK
            </Button>,
          ]}
        >
          {!show && (
            <>
              <Typography.Title level={5} style={{ margin: 0 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{messageError}
              </Typography.Title>
            </>
          )}
          {show && (
            <div>
              <Typography.Title
                level={5}
                style={{ margin: 0, color: "#081b3e" }}
              >
                Tabla de inversión
              </Typography.Title>
              <Table
                columns={columnSimulacion}
                dataSource={dataSource}
                size="small"
                pagination={false}
                scroll={{
                  y: 160,
                }}
              />
              <br></br>
              <Row gutter={22}>
                <Col span={5}>&nbsp;</Col>
                <Col span={14}>
                  <Card
                    title={
                      <Typography.Title level={5} style={{ margin: 0 }}>
                        Inversión final
                      </Typography.Title>
                    }
                    headStyle={{ textAlign: "center" }}
                    bordered={false}
                    size={"small"}
                  >
                    <table width={"90%"} border={0}>
                      <tr>
                        <td
                          style={{
                            textAlign: "right",
                            paddingRight: 15,
                            width: 115,
                          }}
                        >
                          <Typography.Text strong>
                            Capital&nbsp;&nbsp;
                          </Typography.Text>
                        </td>
                        <td style={{ width: 20 }}></td>
                        <td
                          style={{
                            paddingLeft: 15,
                            width: 80,
                            textAlign: "right",
                          }}
                        >
                          <Typography.Text strong>
                            {capitalSimulacion}&nbsp;&nbsp;
                          </Typography.Text>
                        </td>
                      </tr>

                      <tr>
                        <td style={{ textAlign: "right", paddingRight: 15 }}>
                          <Typography.Text strong>
                            Suma rendimiento&nbsp;&nbsp;
                          </Typography.Text>
                        </td>
                        <td style={{ textAlign: "center", width: 30 }}>=</td>
                        <td
                          style={{
                            paddingLeft: 10,
                            width: 100,
                            textAlign: "right",
                          }}
                        >
                          <Typography.Text strong>
                            {rendimientoSimulacion}&nbsp;&nbsp;
                          </Typography.Text>
                        </td>
                      </tr>

                      <tr>
                        <td style={{ textAlign: "right", paddingRight: 15 }}>
                          <Typography.Text strong>
                            Total&nbsp;&nbsp;
                          </Typography.Text>
                        </td>
                        <td style={{ textAlign: "center" }}>=</td>
                        <td
                          style={{
                            paddingLeft: 15,
                            width: 80,
                            textAlign: "right",
                          }}
                        >
                          <Typography.Text strong>
                            {totalSimulacion}&nbsp;&nbsp;
                          </Typography.Text>
                        </td>
                      </tr>
                    </table>
                  </Card>
                </Col>
                <Col span={5}>
                  <Card
                    title={
                      <Typography.Title level={5} style={{ margin: 0 }}>
                        Imprimir
                      </Typography.Title>
                    }
                    bordered={false}
                    size={"small"}
                  >
                    <Button
                      type="primary"
                      icon={<DownloadOutlined />}
                      size={"large"}
                      onClick={HandleDownload}
                    >
                      PDF
                    </Button>
                  </Card>
                </Col>
              </Row>
            </div>
          )}
        </Modal>
      </MainInversion>
    </Structure>
  );
};
export default Step2;
