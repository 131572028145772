import React from "react";

import FooterCustom from "../componentes/FooterCustom";
import { Layout, Breadcrumb, theme, ConfigProvider } from "antd";
import HeaderCustom from "../componentes/HeaderCustom";
import { Content } from "antd/es/layout/layout";

export default function Structure({ children, breadcrumb }) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#04142b",
          },
        }}
      >
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      
      <Layout className="site-layout">
        <HeaderCustom />

        <Breadcrumb
          style={{
            margin: "16px 20px",
          }}
        >
          <Breadcrumb.Item>{breadcrumb}</Breadcrumb.Item>
        </Breadcrumb>

        <Content
          style={{
            margin: "0 20px",
          }}
        >
          <div
            style={{
              padding: 24,
              background: colorBgContainer,
              borderRadius: 10,
              maxHeight: 475,
              minHeight: window.innerHeight - 170,
            }}
          >
            {children}
          </div>
        </Content>
        <FooterCustom />
      </Layout>
    </Layout>
    </ConfigProvider>
  );
}
