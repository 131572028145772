import React, { useState, useEffect, useContext } from "react";
import AuthService from "../services/auth.service";
import TasasService from "../services/tasasBanxico.Service";
import { useNavigate } from "react-router-dom";
import "./Welcome.css";
import { Card, Col, Row, Typography, Statistic } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import Structure from "../componentes/Structure";
import ReactFrappeChart from "react-frappe-charts";
import { ComponentesContext } from "../contextos/componentesContext";
import moment from "moment";

const Welcome = () => {
  const { ShowLoading, CloseSession } = useContext(ComponentesContext);
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  const [valorTiie, setvalorTiie] = useState(0);
  const [valorCetes, setvalorCetes] = useState(0);
  const [valorUdis, setvalorUdis] = useState(0);

  const [datasetsDashboard, setdatasetsDashboard] = useState([
    {
      name: "UDIS",
      values: [0],
    },
    {
      name: "CETES",
      values: [0],
    },
    {
      name: "TIIE",
      values: [0],
    },
  ]);

  const [labelsDashBoard, setlabelsDashBoard] = useState(["01", "02"]);

  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    setUser(usr);

    if (typeof usr === "undefined") {
      AuthService.logout();
      navigate("/login");
    }

    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate("/login");
    } else {
      setTasasData();
    }
  }, []);

  const setTasasData = async () => {
    ShowLoading(true);
    let fecha = moment().format("YYYY-MM-DD")
    let tasasData = await TasasService.getTasas(fecha);
    ShowLoading(false);

    if (tasasData.estado == 403) {
      CloseSession();
      return false;
    }


    let tasaUdis = tasasData.tasas
      .filter((item) => item.value === 1)
      .map((item) => item.valor);
    setvalorUdis(tasaUdis);

    let tasaCetes = tasasData.tasas
      .filter((item) => item.value === 2)
      .map((item) => item.valor);
    setvalorCetes(tasaCetes);

    let tasaTiie = tasasData.tasas
      .filter((item) => item.value === 3)
      .map((item) => item.valor);
    setvalorTiie(tasaTiie);

    let dashboardData = await TasasService.getDashboard();
    console.log(dashboardData);

    if(dashboardData.estado!=500){
      setlabelsDashBoard(dashboardData.labels);
      setdatasetsDashboard(dashboardData.datasets);
    }
  };

  return (
    <Structure breadcrumb={"Bienvenido al sistema Fondeadora Finamo"}>
      <center>
        <Typography.Title level={1} style={{ margin: 0, color: "#081b3e" }}>
          Bienvenido al Sistema Fondeadora Finamo
        </Typography.Title>
      </center>
      <center>
        <div style={{ width: "90%", marginTop: 20 }}>
          <Row gutter={30}>
            
            <Col span={8}>
              <Card bordered={false}>
                <Statistic
                  title="UDIS"
                  value={valorUdis}
                  precision={6}
                  valueStyle={{
                    color: "#73a8f2",
                  }}
                  prefix={<ArrowUpOutlined />}
                  
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered={false}>
                <Statistic
                  title="CETES"
                  value={valorCetes}
                  precision={2}
                  valueStyle={{
                    color: "#5bc837",
                  }}
                  prefix={<ArrowUpOutlined />}
                  suffix="%"
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered={false}>
                <Statistic
                  title="TIIE"
                  value={valorTiie}
                  precision={4}
                  valueStyle={{
                    color: "#04142b",
                  }}
                  prefix={<ArrowUpOutlined />}
                  suffix="%"
                />
              </Card>
            </Col>
          </Row>
          <ReactFrappeChart
            type="line"
            colors={["#73a8f2", "#5bc837", "#04142b"]}
            axisOptions={{ xAxisMode: "tick", yAxisMode: "tick", xIsSeries: 1 }}
            height={250}
            data={{
              labels: labelsDashBoard,
              datasets: datasetsDashboard,
            }}
          />
        </div>
      </center>
    </Structure>
  );
};
export default Welcome;
