
import {Footer} from 'antd/es/layout/layout';

export default function FooterCustom({...props }) {


return(
    <Footer
    style={{
      textAlign: 'center',
      height: 5,
    }}
  >
    <div style={{margin:-10}}>
      Finamo ©2023 Todos los derechos reservados
    </div>
  </Footer>
);
}