import LocalData from '../persistencia/LocalData';



const login = (email, password, token) => {
  const URL = process.env.REACT_APP_URL_API; 
  const json = { username:'', password:''};
  //const json = { username:'', password:'',jwt:'', dataEmp: {fullname: "VICTOR ROGELIO VILLANUEVA AYON", primerNombre: "VICTOR", apellidoMaterno: "VILLANUEVA"}  };

  json.username = email;
  json.password = password;
  //json.token_recatcha =token;

  //goHome(json);
  //return 200;

 const resp = fetch(`${URL}api/auth/authenticate`, {
        method: 'POST',
        headers: {
          'Content-Type':'application/json'
        },
        
        body:JSON.stringify(json),})
  .then( response => checkStatus(response) )
  .then(data => {

            if(data.status === 204){
            return 204;
             
            }else{
              goHome(data.json());
        
              return 200;
           
            }     
          
        }
       
  )
  .catch(err => {
    return 500;
});
return resp;
};

const register = (username, email, password) => {
 return JSON.parse('');
}

const getCurrentUser = () => {
  try {
    return LocalData.getItem('user');

    } catch(e){

    }
  
}

const getCurrentToken = () => {
  try {
    return LocalData.getItem('token_login');

    } catch(e){

    }
  
}

const getIsAuthenticated = async () => {
  try {

    let usr= LocalData.getItem('user');

    if (usr === null || usr === undefined) {
      return false;
    }
    else{
      return true;
    }
    } catch(e){

    }
  
}
 

const logout = () => {
  LocalData.clearAll();
  
};

async function goHome(user) {
    
  let obj = await user
  LocalData.save('token_login',obj.jwt);
  LocalData.save('token_expired',obj.expired);
  LocalData.save('user',obj.dataEmp);
  return  obj.dataEmp;
  
}

function checkStatus(response) {
    if (response.status === 200 || response.status === 204) {
      return Promise.resolve(response);
    }
    else{
      throw new Error(`HTTP ${response.status} - ${response.statusText}`);
     
    }
  
}





const AuthService = {
 
  register,
  login,
  logout,
  getCurrentUser,
  getCurrentToken,
  getIsAuthenticated
}

export default AuthService;