import React, { useState, useRef, useEffect, useContext } from "react";
import AuthService from "../../services/auth.service";
import ReCAPTCHA from "react-google-recaptcha";
import Logo from "../../componentes/Logo";
import "./Login.css";
import ParticlesBackground from "../../componentes/ParticlesBackground";
import { ComponentesContext } from "../../contextos/componentesContext";
import {
  UserOutlined,
  LockOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Form,
  Input,
  Layout,
  Card,
  Spin,
  message,
  FloatButton,
  Modal,
} from "antd";
const { Content, Footer } = Layout;

const Login = () => {
  const { ShowLoading } = useContext(ComponentesContext);
  const captchaRef = useRef(null);
  const [bandera, setBandera] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [token, setToken] = useState("");
  const navigate = useNavigate();

  const onChangeToken = () => {
    setToken(captchaRef.current.getValue());
  };


  

  useEffect(() => {
    ShowLoading(false);
  }, []);

  const getApiAsync = async (values) => {
    setBandera(true);
    if (token == null) {
      messageApi.open({
        type: "error",
        content: "Favor de capturar el catcha",
      });
    } else {
      let auth = await AuthService.login(
        values["username"],
        values["password"],
        token
      );
      //console.log(auth);
      if (auth === 200) {
        setTimeout(() => {
          navigate("/home");
        }, 200);
      } else if (auth === 204) {
        messageApi.open({
          type: "error",
          content: "Credenciales Invalidas, valide usuario, contraseña",
        });
      } else {
        messageApi.open({
          type: "error",
          content: "Ocurrio un error inesperado",
        });
      }
    }
    setBandera(false);
  };

  const info = () => {
    Modal.info({
      title: "¿Como iniciar Sesion?",
      content: (
        <div>
          <p>
            <b>Pasos ...</b>
          </p>
          <p>1.- Ingrese su correo proporcionado por Finamo</p>
          <p>2.- Ingrese la contraseña que se establecio en este portal</p>
          <p>
            si no recuerda su contraseña intente cambiarla en ¿olvide mi
            contraseña?
          </p>
          <p>3.- da click en: No soy un robot</p>
          <p>4.- presiona el boton Ingresar</p>
          <p>
            <b>soporte.finamo@finamo.mx</b>
          </p>
          <p>
            <b>EXT 1524</b>
          </p>
        </div>
      ),
      onOk() {},
    });
  };

  return (
    <Spin tip="Cargando..." size="large" spinning={bandera}>
      {contextHolder}
      <Layout
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          background: "#051D49",
          backgroundImage: `linear-gradient(rgb(20,9,9), rgb(5,29,73))`,

          height: "50vh",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        {/* <ParticlesBackground/> */}

        <div
          style={{
            height: 50,
          }}
        />

        <Content>
          <Card bordered={true} style={{ maxWidth: 600 }}>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={getApiAsync}
            >
              <Form.Item
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Logo />
              </Form.Item>
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese el usuario!",
                  },
                ]}
              >
                <Input
                  type="email"
                  size="large"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Username@finamo.mx"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese la contraseña!",
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Contraseña123.."
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
              <Form.Item>
                <a className="login-form-forgot" href="userchangepassword/123">
                  ¿Olvidaste la contraseña?
                </a>
              </Form.Item>
              <Form.Item>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  ref={captchaRef}
                  onChange={onChangeToken}
                />
                {process.env.URL_API}
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  disabled={token.length > 1 ? false : true}
                >
                  Ingresar
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Content>
        <Footer
          style={{
            textAlign: "center",
            justifyContent: "center",
            width: "100%",
            display: "flex",
            alignItems: "center",
            minHeight: "5vh",
            height: "2%",
            background: "#051D49",
            color: "#fff",
          }}
        >
          2023 Finamo. Todos los derechos reservados ©
        </Footer>
      </Layout>
      <FloatButton
        icon={<QuestionCircleOutlined />}
        type="primary"
        style={{
          right: 24,
        }}
        onClick={info}
      />
    </Spin>
  );
};
export default Login;
