import React, { useState, useEffect, useContext } from "react";
import AuthService from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import Structure from "../../componentes/Structure";
import InversionService from "../../services/inversion.Service";
import { ComponentesContext } from "../../contextos/componentesContext";
import "./InversionesActivas.css";

import {
  Tag,
  Table,
  Typography,
} from "antd";


const InversionesActiva = () => {
  const navigate = useNavigate();
  const { CloseSession, ShowLoading, ModalMessage } =
    useContext(ComponentesContext);
  const [dataSource, setDataSource] = useState([]);
  const [columnsData, setcolumnsData] = useState([]);

  const getInversionesActivas = async () => {
    let respuesta = await InversionService.postInversionesActivas();

    if (respuesta.estado == 403) {
      CloseSession();
      return false;
    }

    if (respuesta.estado == 500) {
      ModalMessage(
        "Mensaje",
        "Error al obtener las inversiones activas."
      );
      return false;
    }

    const newColumns = [...respuesta.headerTabla];
    console.log(newColumns);

    for (const element of respuesta.inversiones) {
      element.montoInversion =
        element.montoInversion == "0"
          ? "-"
          ://Validar si quedaria con este campo o se quitaria 
            element.montoInversion.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });

      element.status = "0";
    }

    const columnsToHide = ['estado'];
    const filteredColumns = newColumns.filter(column => !columnsToHide.includes(column.dataIndex));
    console.log(filteredColumns);
    const newData = filteredColumns.map((item) => {
        return {
          ...item,
          key: item.dataIndex,
          width: parseInt(item.width)
        };
    });
    console.log(newData);


    setcolumnsData(newData);
    setDataSource(respuesta.inversiones);
  };

  useEffect(() => {
    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate("/login");
    }

    getInversionesActivas();
  }, []);

  return (
    <Structure breadcrumb={"Inversiones / Activas"}>
      <center>
        <Typography.Title level={2} style={{ margin: 0, color: "#081b3e" }}>
          Lista de Inversiones Activas.
        </Typography.Title>
      </center>
      <Table
        columns={columnsData}
        dataSource={dataSource}
        pagination={false}
        scroll={{
          y: 350,
        }}
      />

    </Structure>
  );
};
export default InversionesActiva;
