import { createContext, useState } from "react";
import { Typography, Spin, message, notification, Modal  } from "antd";
import AuthService from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import { HistoryOutlined } from "@ant-design/icons";

export const ComponentesContext = createContext();
const { confirm } = Modal;

export function ComponentesContextProvider({ children }) {
  const navigate = useNavigate();
  const [varsInversion, SetvarsInversionC] = useState({
    inversionista: {
      codInv: 0,
      RFC: "",
      statusRFC: 1,
    },
    simulacion: {
      monto: 0,
    },
    cuenta: {
      idBanco: 0,
      noClabeInterbancaria: 0,
      noCuenta: 0,
    },
  });
  const [statusRFC, SetstatusRFC] = useState("");
  const [minutos, Setminutos] = useState(3);
  const [loading, setLoading] = useState(false);

  const [api, contextHolder] = notification.useNotification();


  const ModalMessage = (titulo, contenido) => {
    Modal.info({
      title: titulo,
      content: contenido,
      onOk() {},
    });
  };

  const InputTypes = {
    Text: 0,
    Integer: 1,
    Decimal: 2,
    Rfc: 3,
    Curp: 4
  }

  const configWarning = {
    icon: "",
    content: (
      <Typography.Title level={3} style={{ margin: 0, color: "#081b3e" }}>
        <HistoryOutlined style={{ fontSize: 30, color: "#f9dc06" }} /> Sigues
        ahi?? si no tu sessión se cerrará en{" "}
        {minutos == 1 ? minutos + " minuto" : minutos + " minutos"}.
      </Typography.Title>
    ),
    duration: 10,
    key: 1,
  };

  const openNotification = () => {
    api.open({
      message: 'Inactividad',
      description:(
        <Typography.Title level={3} style={{ margin: 0, color: "#081b3e" }}>
          <HistoryOutlined style={{ fontSize: 30, color: "#f9dc06" }} /> Sigues
          ahi?? si no tu sessión se cerrará en{" "}
          {minutos == 1 ? minutos + " minuto" : minutos + " minutos"}.
        </Typography.Title>
      ),
      className: 'custom-class',
      style: {
        width: 600,
      },
    });
  };
  
  const ShowLoading = (flagLoading) => {
    setLoading(flagLoading);
  }

  const handleCloseSession = async (values) => {
    AuthService.logout();
    navigate("/login");
  };

  const CloseSession = () => {
    message.warning(
      <Typography.Title level={3} style={{ margin: 0, color: "#081b3e" }}>
        Su sessión ha expirado. La pagina se redigirirá al inicio de sesión.
      </Typography.Title>,
      4,
      handleCloseSession
    );
  };

  const WarningSession = (minutos) => {
    Setminutos(minutos);
    //message.open(configWarning);
    openNotification();
  };

  const RegistraVars = (array) => {
    SetvarsInversionC(array);
  };

  function RegistraStatusRFC(valor) {
    SetstatusRFC(valor);
  }

  return (
    <ComponentesContext.Provider
      value={{ varsInversion, RegistraVars, CloseSession, WarningSession, ShowLoading, InputTypes, ModalMessage }}
    >
      <Spin size="large" spinning={loading}>{children}</Spin>
    </ComponentesContext.Provider>
  );
}
