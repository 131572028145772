import AuthService from "./auth.service";


const postRegresaReportes = (json) => {
  const URL = process.env.REACT_APP_URL_API;

  //console.log(AuthService.getCurrentToken());

  const resp = fetch(`${URL}reportes/formalizacion`, {
    method: "POST",
    headers: {
      "Authorization": AuthService.getCurrentToken(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      //console.log("respuesta",responseData);
      if (typeof responseData.datasets == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{

          if(responseData.reportes.length>0){
            return responseData;
          }
          else{
            return {"estado":500};
          }
        }
        
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const ReportesService = {
  postRegresaReportes,
};

export default ReportesService;
