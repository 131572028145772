import React, { useEffect, useState } from "react";
import { IdcardOutlined, ExportOutlined } from "@ant-design/icons";
import {
  Layout,
  Avatar,
  Badge,
  Dropdown,
  theme,
  Modal,
  Card,
  Button,
} from "antd";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import MenuCustom from "./MenuCustom";
import Logo from "./Logo_tiny";
import {
  SettingOutlined,
  EditOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

const { Header } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

export default function HeaderCustom() {
  const [user, setUser] = useState({});
  const [name, setName] = useState("?");
  const navigate = useNavigate();
  const [nombreUsuario, setNombreUsuario] = useState(user.fullname);
  const { Meta } = Card;
  const [modal1Open, setModal1Open] = useState(false);
  const [Correo, setCorreo] = useState(user.correo);
  const [messageAvatar, setmessageAvatar] = useState("");

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      setUser({});
      setName(" ");
      setCorreo("");
      setmessageAvatar("")
      
    } else {
      setUser(usr);
      setName(usr.primerNombre);
      setNombreUsuario(usr.fullname);
      setCorreo(usr.correo);
      setmessageAvatar(usr.primerNombre.substring(0, 1).toUpperCase())
    }
  }, []);

  const logOut = () => {
    AuthService.logout();
    navigate("/login");
  };

  const items = [
    getItem(
      <a onClick={(e) => setModal1Open(true)}>perfil</a>,
      "3",
      <IdcardOutlined />
    ),
    getItem(<a onClick={(e) => logOut()}>Salir</a>, "4", <ExportOutlined />),
  ];

  const handleWindowResize = () => {
    if (window.innerWidth > 1270) {
      setNombreUsuario(user.fullname);
    } else {
      if (window.innerWidth > 800) {
        setNombreUsuario(user.primerNombre + " " + user.apellidoMaterno);
      } else {
        setNombreUsuario("");
      }
    }
  };

  const handleMouseOver =() =>{
    setmessageAvatar((<><a><EditOutlined />Editar</a></>));
  }

  const handleMouseOut =() =>{
    setmessageAvatar(name.substring(0, 1).toUpperCase());
  }
  
  const handleClick =() =>{
    //setmessageAvatar(name.substring(0, 1).toUpperCase());
    document.getElementById('target').click();
  }
  

  window.addEventListener("resize", handleWindowResize);

  return (
    <Header
      style={{
        padding: 0,
        background: colorBgContainer,
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "right",
            paddingRight: 0,
            backgroundColor: "#04142b",
            height: 70,
          }}
        >
          <div style={{ width: 170 }}>
            <Logo href="./home" />
          </div>
          <div style={{ width: "60%" }}>
            <MenuCustom />
          </div>
          <div style={{ width: "25%", textAlign: "right", color: "#dce5e5" }}>
            {nombreUsuario} &nbsp;
            <Dropdown
              menu={{ items }}
              placement="bottomRight"
              arrow={{ pointAtCenter: true }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <span>
                  <Badge count={0}>
                    <Avatar
                      style={{
                        color: "#000000",
                        fontWeight: "bold",
                        backgroundColor: "#ffffff",
                      }}
                    >
                      {name.substring(0, 1).toUpperCase()}
                    </Avatar>
                  </Badge>
                </span>
              </a>
            </Dropdown>
            &nbsp;
          </div>
        </div>
      </div>

      <Modal
        style={{ top: 15, left: window.innerWidth / 2 - 280 }}
        open={modal1Open}
        onOk={() => setModal1Open(false)}
        onCancel={() => setModal1Open(false)}
        footer={<></>}
        closable={false}
        maskClosable={false}
      >
        <input type="file" id="target" style={{display:"none"}} accept="image/png, image/gif, image/jpeg" />
        <Card
          style={{
            width: "100%",
            marginTop: 16,
          }}
          actions={[
            <Button
              style={{ color: "#696969" }}
              icon={<SettingOutlined />}
              size="large"
            >
              Cambiar contraseña
            </Button>,
            <Button
              onClick={(e) => {
                setModal1Open(false);
              }}
              style={{ color: "#696969", width: 200 }}
              icon={<CloseCircleOutlined />}
              size="large"
            >
              Cerrar
            </Button>,
          ]}
        >
          <Meta
            avatar={
              <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={handleClick} >
              <Avatar
                size={88}
                shape="square"
                style={{
                  color: "#000000",
                  fontWeight: "bold",
                  backgroundColor: "#f1eeef",
                }}
              >
                {messageAvatar}
              </Avatar>
              </div>
            }
            title={"Usuario: " + nombreUsuario}
            description={"Correo: " + Correo}
          />
        </Card>
      </Modal>
    </Header>
  );
}
