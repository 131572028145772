import {  Modal as MyModal } from "antd";
import { FilePdfOutlined} from "@ant-design/icons";
//import 'dayjs/locale/es';
//import locale from 'antd/es/date-picker/locale/es_ES';

export default function ModalDocumentViewer(props) {
  //const { value, onChange } = props;

  return (
    <MyModal
      {...props}
      destroyOnClose={true}
      title={
        <div>
          <FilePdfOutlined /> &nbsp; {props.titulo}
        </div>
      }
      closable={false}
      width={"90%"}
    >
      <object
        type="application/pdf"
        data={props.url}
        width="100%"
        height={ window.innerHeight - 250}
        style={{borderRadius:6}}
        
      ></object>
    </MyModal>
  );
}
