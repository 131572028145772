
import { Tag } from "antd";

const ReturnStatusTag = (status) => {
  let color = "";
  let text = "";
  switch (status) {
    case "1F":
      color = "#ff6a00";
      text = "Tasa pendiente";
      break;
    case "2s":
      color = "#ffbb00";
      text = "Por pagar";
      break;
    case "3P":
      color = "#22f194";
      text = "Pagado";
      break;
    default:
      color = "#ff00bb";
      text = "Otro";
  }
  return (
    <Tag color={color} key={status}>
      {text.toUpperCase()}
    </Tag>
  );
};
export default ReturnStatusTag;