import AuthService from "./auth.service";

const getSolicitudes = () => {
  const URL = process.env.REACT_APP_URL_API;

  const resp = fetch(`${URL}solicitud/facturacion/consulte/all-solicitudes-pago`, {
    method: "GET",
    headers: {
      "Authorization": AuthService.getCurrentToken()
    },
  })
    .then((response) => response.json())
    .then((responseData) => {

      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":500};
        }
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};


const postGeneraCortePago = (json) => {
  const URL = process.env.REACT_APP_URL_API;

  const resp = fetch(`${URL}solicitud/facturacion/generate/corte-de-pago`, {
    method: "POST",
    headers: {
      "Authorization": AuthService.getCurrentToken(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {

      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":500};
        }
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postSolicitudFacturacion = (json) => {
  const URL = process.env.REACT_APP_URL_API;

  const resp = fetch(`${URL}solicitud/facturacion/generate/view/solicitud-facturacion`, {
    method: "POST",
    headers: {
      "Authorization": AuthService.getCurrentToken(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {

      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":500};
        }
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getFactura = (getParameter) => {
  const URL = process.env.REACT_APP_URL_API;

  const resp = fetch(`${URL}reportes/solfacturames/${getParameter}`, {
    method: "GET",
    headers: {
      "Authorization": AuthService.getCurrentToken()
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.path == 'undefined') {
        return {"estado":500};
      }
      else{
        return {"estado":200, "path": responseData.path};
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getEstadoCuenta = (getParameter) => {
  const URL = process.env.REACT_APP_URL_API;

  const resp = fetch(`${URL}reportes/estadocuenta/${getParameter}`, {
    method: "POST",
    headers: {
      "Authorization": AuthService.getCurrentToken()
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.path == 'undefined') {
        return {"estado":500};
      }
      else{
        return {"estado":200, "path": responseData.path};
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postEnvioCorreoFactura = (json) => {
  const URL = process.env.REACT_APP_URL_API;

  const resp = fetch(`${URL}email/solfacturames`, {
    method: "POST",
    headers: {
      "Authorization": AuthService.getCurrentToken(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":500};
        }
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { "estado": 500 };
    });
  return resp;
};

const postEnvioCorreoEstadoCuenta = (json) => {
  const URL = process.env.REACT_APP_URL_API;

  const resp = fetch(`${URL}email/estadocuenta`, {
    method: "POST",
    headers: {
      "Authorization": AuthService.getCurrentToken(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
 
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":500};
        }
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { "estado": 500 };
    });
  return resp;
};


const FacturacionService = {
  getSolicitudes,
  postGeneraCortePago,
  postSolicitudFacturacion,
  getFactura,
  getEstadoCuenta,
  postEnvioCorreoFactura,
  postEnvioCorreoEstadoCuenta
};

export default FacturacionService;
