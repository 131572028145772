import LocalData from "../persistencia/LocalData";
import AuthService from "../services/auth.service";

const getEstados = () => {
  const URL = process.env.REACT_APP_URL_API;
  

  const resp = fetch(`${URL}estados/all`, {
    method: "GET",
    headers: {
      "Authorization": AuthService.getCurrentToken()
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":500};
        }
        
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getIdentificaciones = () => {
  const URL = process.env.REACT_APP_URL_API;

  const resp = fetch(`${URL}identificaciones/all`, {
    method: "GET",
    headers: {
      "Authorization": AuthService.getCurrentToken()
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":500};
        }
        
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postCp = (json) => {
  const URL = process.env.REACT_APP_URL_API;
  const resp = fetch(`${URL}renapo/cp`, {
    method: "POST",
    headers: {
      "Authorization": AuthService.getCurrentToken(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":500};
        }
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postRFC = (json) => {
  const URL = process.env.REACT_APP_URL_API;
  const resp = fetch(`${URL}inversionistas/view/rfc`, {
    method: "POST",
    headers: {
      "Authorization": AuthService.getCurrentToken(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      //console.log(responseData);
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":500};
        }
        
      }
      else{
        return responseData;
      }
    })
    .catch(err => {
      return {"estado":500};
  });
  return resp;
};

const postSaveInversionista = (json) => {

  const URL = process.env.REACT_APP_URL_API;
  const resp = fetch(`${URL}inversionistas/save`, {
    method: "POST",
    headers: {
      "Authorization": AuthService.getCurrentToken(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":500};
        }
        
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postEditInversionista = (json) => {
  
  const URL = process.env.REACT_APP_URL_API;
  const resp = fetch(`${URL}inversionistas/edit`, {
    method: "POST",
    headers: {
      "Authorization": AuthService.getCurrentToken(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":500};
        }
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const UsuarioService = {
  getEstados,
  getIdentificaciones,
  postCp,
  postRFC,
  postSaveInversionista,
  postEditInversionista
};

export default UsuarioService;
