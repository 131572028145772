import React, { useState, useEffect, useRef, useContext } from "react";
import AuthService from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import Highlighter from "react-highlight-words";
import Structure from "../../componentes/Structure";
import InversionService from "../../services/inversion.Service";
import FacturacionService from "../../services/pagos.Service";
import { ComponentesContext } from "../../contextos/componentesContext";
import "./Prospecciones.css";
import DatePickerCustom from "../../componentes/DatePickerCustom";
import moment from "moment";
import ModalDocumentViewer from "../../componentes/ModalDocumentViewer";
import InversionistaService from "../../services/inversionista.Service";
import ReturnStatusTag from "../../componentes/ReturnStatusTag";
import returnValidUploadFileClass from "../../clases/returnValidUploadFile.class";
import {
  SearchOutlined,
  QuestionCircleOutlined,
  MailOutlined,
  CloseOutlined,
  UploadOutlined,
  FilePdfOutlined,
    PlusOutlined
} from "@ant-design/icons";

import {
  Button,
  Input,
  Upload,
  Space,
  Table,
  Typography,
  Modal,
  Collapse,
  List,
  Tooltip
} from "antd";




const SolicitudFactura = () => {
  const URL = process.env.REACT_APP_URL_API;
  const navigate = useNavigate();
  const { CloseSession, ShowLoading, ModalMessage } =
    useContext(ComponentesContext);
  const [dataSource, setDataSource] = useState([]);
  const [columnsData, setcolumnsData] = useState([]);
  const [codInvRow, setcodInvRow] = useState("");
  const [idInversion, setidInversion] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalCuentaOpen, setIsModalCuentaOpen] = useState(false);
  const [pathDocument, setpathDocument] = useState([]);
  const [isModalSolicitudOpen, setIsModalSolicitudOpen] = useState(false);
  const [arrdetalleSolicitud, setarrdetalleSolicitud] = useState([]);
  const [urlPathFactura, seturlPathFactura] = useState("");
  const [urlPathEstadoCuenta, seturlPathEstadoCuenta] = useState("");
  const [mailValue, setmailValue] = useState("");
  const [DateValue, setDateValue] = useState("");
  const [isModalOpenDocs, setisModalOpenDocs] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [canRemove, setcanRemove] = useState(true);
  const [OpenModelDocument, setOpenModelDocument] = useState(false);
  const [loadingModal, setloadingModal] = useState(false);
  const [ModelAttachementType, setModelAttachementType] = useState(1);
  const [height, setHeight] = useState(0);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getSolicitudes = async () => {
    ShowLoading(true);
    let respuesta = await FacturacionService.getSolicitudes();

    if (respuesta.estado == 403) {
      CloseSession();
      return false;
    }

    if (respuesta.estado == 500) {
      ModalMessage("Mensaje", "Error al obtener la solicitudes.");
      ShowLoading(false);
      return false;
    }
    const newColumns = [...respuesta.arrHeader];

    //console.log(newColumns);
    //console.log(respuesta.listSolicitudes);

    const columnsToHide = ['capital', 'isr', 'iva', 'diasIntereses', 'intereses'];
    const filteredColumns = newColumns.filter(column => !columnsToHide.includes(column.dataIndex));
    const newData = filteredColumns.map((item) => {
      var arrwidth = item.width;
      switch (item.dataIndex) {
        case "rfc":
          arrwidth = 130;
          break;
        case "tasaDescripcion":
          arrwidth = 80;
          break;
        case "codInversion":
          arrwidth = 90;
          break;
        case "periodo":
          arrwidth = 90;
          break;
        case "fechaReporte":
          arrwidth = 120;
          break;
        case "nombreInversionista":
          arrwidth = 250;
          break;
      }

      if (item.dataIndex == "estado") {
        return {
          ...item,
          key: item.dataIndex,
          width: 150,
          render: (_, record) => <>&nbsp;&nbsp;&nbsp;&nbsp;{ReturnStatusTag(record.estado)}</>,
        };
      }
      else {
        return {
          ...item,
          key: item.dataIndex,
          width: arrwidth,
          align: item.align
        };
      }
    });

    const extraColumn = {
      title: "Opciones",
      width: 140,
      render: (_, record) => (
        <div>
          {/* <a className="button" onClick={HandleEditar}>
            <span>Editar</span>
          </a> */}
          {/* <a
            className="button"
            onClick={() => setisModalOpenDocs(true)}
            style={{ width: 50 }}
          >
            <span><UploadOutlined /></span>
          </a> */}
          <Tooltip title="Solicitud Factura">
            <a
              className="button"
              onClick={() => handleModalOpen(record)}
              style={{ width: 50 }}
            >
              <span><FilePdfOutlined /></span>
            </a>
          </Tooltip>
          <Tooltip title="Estado de Cuenta">
            <a
              className="button"
              onClick={() => handleModalOpenEstadoCuenta(record)}
              style={{ width: 50 }}
            >
              <span><FilePdfOutlined /></span>
            </a>
          </Tooltip>
         
        </div>
      ),
    };


    newData.push(extraColumn);
    setcolumnsData(newData);
    setDataSource(respuesta.listSolicitudes);
    //console.log(respuesta.listSolicitudes);
    ShowLoading(false);

  };

  const action = (dataIndex) => ({
    return: (
      <Space size="middle">
        <a>Invite {dataIndex}</a>
        <a>Delete</a>
      </Space>
    ),
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Limpiar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });



  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    //console.log("USER HOOK", usr);

    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate("/login");
    }

    const windowHeight = window.innerHeight;
    const eightyPercentOfHeight = windowHeight * 0.7;
    setHeight(eightyPercentOfHeight);
    
    getSolicitudes();
  }, []);

  const HandleActivar = async () => {
    let respuestaData = await InversionService.postCrearInversion({
      codInversionista: codInvRow,
      idInversion: idInversion,
    });
    if (respuestaData.status == 403) {
      CloseSession();
      return false;
    }

    if (respuestaData.estado == 500) {
      ModalMessage("Mensaje", "Se produjo un error al activar la inversión.");
      return false;
    }

    getSolicitudes();
    setIsModalOpen(false);
    ModalMessage("Mensaje", "La inversión se activo correctamente.");
  };

  const sendEmailFactura = async() => {
    setIsModalOpen(false);
    ShowLoading(true);
    const respuesta = await FacturacionService.postEnvioCorreoFactura({
      destinatario: mailValue,
      solicitudId: arrdetalleSolicitud.solicitudId,
    });

    if (respuesta.estado == 403) {
      CloseSession();
      return false;
    }

    if (respuesta.estado == 500) {
      ModalMessage(
        "Mensaje",
        "Error al enviar el correo solicitud factura."
      );
      return false;
    }
    ShowLoading(false);
    setIsModalOpen(true);

    ModalMessage(
      "Mensaje",
      "La Solicitud Factura a sido enviada Satisfactoriamente."
    );

  };

  const sendEmailEstadoCuenta = async() => {
    setIsModalCuentaOpen(false);
    ShowLoading(true);
    const respuesta = await FacturacionService.postEnvioCorreoEstadoCuenta({
      destinatario: mailValue,
      inversionCarteraId: arrdetalleSolicitud.inversionCarteraId,
    });

    if (respuesta.estado == 403) {
      CloseSession();
      return false;
    }

    if (respuesta.estado == 500) {
      ModalMessage(
        "Mensaje",
        "Error al enviar el correo Estado de Cuenta."
      );
      return false;
    }
    ShowLoading(false);
    setIsModalCuentaOpen(true);

    ModalMessage(
      "Mensaje",
      "El Estado de Cuenta a sido enviado Satisfactoriamente."
    );

  };

  const handleModalOpen = async (detalleSolicitud) => {
    setarrdetalleSolicitud([]);
    //console.log(detalleSolicitud);
    setarrdetalleSolicitud(detalleSolicitud);
    //setnombreInversionista(detalleSolicitud.nombreInversionista);

    setmailValue(detalleSolicitud.correo);

    let respuestaData = await FacturacionService.getFactura(detalleSolicitud.solicitudId);
    //console.log(respuestaData);

    if (respuestaData.estado == 200) {
      seturlPathFactura(respuestaData.path)
    }

    setIsModalOpen(true);
  };

  const handleModalOpenEstadoCuenta = async (detalleSolicitud) => {
    setarrdetalleSolicitud([]);
    setarrdetalleSolicitud(detalleSolicitud);
    setmailValue(detalleSolicitud.correo);

    let respuestaData = await FacturacionService.getEstadoCuenta(detalleSolicitud.inversionCarteraId);
    console.log("Respuesta del estado de cuenta: ", respuestaData);

    if (respuestaData.estado == 200) {
      seturlPathEstadoCuenta(respuestaData.path)
    }

    setIsModalCuentaOpen(true);
  };

  const handleModalSolicitudOpen = async () => {
    let respuestaData = await FacturacionService.postSolicitudFacturacion({
      codInversionista: codInvRow,
      idInversion: idInversion,
    });

    if (respuestaData.status == 403) {
      CloseSession();
      return false;
    }

    if (respuestaData.estado == 500) {
      ModalMessage(
        "Mensaje",
        "Se produjo un error al generar al registrar la fecha de la orden de pago."
      );
      return false;
    }

    //console.log(respuestaData);

    setpathDocument(respuestaData.path);

    let respuesta = await InversionistaService.postRFC({
      rfc: respuestaData.objReport.rfc,
    });
    if (respuesta.status == 403) {
      CloseSession();
      return false;
    }

    if (respuesta.data === null) {
      setmailValue("victor.villanueva@finamo.mx");
    } else {
      setmailValue(respuesta.data.inversionista.correoFactura);
    }

    setIsModalSolicitudOpen(true);
  };


  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalCuentaClose = () => {
    setIsModalCuentaOpen(false);
  };

  // const handleModalSolicitudClose = () => {
  //   setIsModalSolicitudOpen(false);
  // };

  // const handleDateInputChange = (date) => {
  //   console.log(moment(new Date(date)).format("YYYY-MM-DD"));
  //   setDateValue(moment(new Date(date)).format("YYYY-MM-DD"));
  // };

  const handleInputMail = (e) => {
    setmailValue(e.target.value);
  };

  const handleEnviarCorreo = () => {
    var reg =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

    if (mailValue == "") {
      ModalMessage("Mensaje", "El correo no puede estan en blanco.");
      return false;
    }

    if (!reg.test(mailValue)) {
      //onChange(e.target.value);
      //setAlertVisible(false);
      ModalMessage("Mensaje", "El formato del correo es incorrecto.");
      return false;
    }

    ModalMessage("Mensaje", "El correo se envió correctamente.");
  };

  
  const handlePreview = async (file) => {

    const fileExtension = file.name.split(".").pop();

    switch (fileExtension.toLowerCase() ) {
      case "pdf":
        setpathDocument(file.url);
        setOpenModelDocument(true);
        break;
    }
  };

  const handleRemove = async (file) => {
    setloadingModal(true);
    // let ticketsData = await TicketsService.postDeleteFileDetailTicket({
    //   nombre_archivo: file.name,
    //   id_ticket: IdTicket,
    // });
  };

  const handleChange = async ({ fileList: newFileList }) => {
    
    setTimeout(async function () {
      // let DetalleAdjuntosData = await TicketsService.postAdjuntosTicket({
      //   id_ticket: IdTicket,
      //   tipo: 1,
      //   id_ticket_detalle: 0,
      //   id_base_conocimiento: 0,
      // });

      //setFileList(DetalleAdjuntosData.data);
      setloadingModal(false);  
    }, 6000);

  };

  const handleBeforeUpload = async (file) =>{
    let flagArchivoValido= await returnValidUploadFileClass.returnPdfValidUploadFile(file.type);

    //console.log(file.type);
    if(flagArchivoValido==false){
      ModalMessage("Mensaje","El tipo de archivo que se desea adjuntar no es valido." );
      return false;
    }

    const fileSizeLimit = 10 * 1024 * 1024; // 5MB

    if (file.size > fileSizeLimit) {
      ModalMessage("Mensaje","El archivo no puede pesar mas de 10MB." );
      return false; // Cancela la carga del archivo
    }


    setloadingModal(true);
    return true;
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 0,
        }}
      >
        Adjuntar
      </div>
    </div>
  );

  function UploadDocument(props) {
    return (
      <div id={props.id} style={{ marginLeft: 30 }}>
        <table border={0}>
          <tr>
            <td width={200}>{props.title}</td>
            <td style={{textAlign:"center"}}>

              <div style={{ backgroundColor: "#f5f5f5", borderRadius: 10, textAlign:"center", paddingLeft:7 }}>
                <Upload
                  name="archivo"
                  action={`${URL}guarda-archivo?token=${AuthService.getCurrentToken()}&id_ticket=1`}
                  listType="picture-card"
                  fileList={fileList}
                  showUploadList={{
                    showRemoveIcon: canRemove,
                  }}
                  onPreview={handlePreview}
                  onRemove={handleRemove}
                  onChange={handleChange}
                  beforeUpload={handleBeforeUpload}
                >
                  {ModelAttachementType == 0 ? null : uploadButton}
                </Upload>
              </div>

            </td>
          </tr>
        </table>
      </div>
    );
  }

  const dataUpload = [
    <UploadDocument
      id={1}
      title={"Contrato"}
      url={"http://192.168.0.91/pdfs/ff_mutuante.pdf"}
    />,
    <UploadDocument
      id={2}
      title={"Pagaré"}
      url={"http://192.168.0.91/pdfs/ff_mutuante.pdf"}
    />,
    <UploadDocument
      id={2}
      title={"Reporte de simulación tabla de amortización"}
      url={"http://192.168.0.91/pdfs/ff_mutuante.pdf"}
    />,
  ];

  const handleModalCloseDocs = () => {
    setisModalOpenDocs(false);
  }

  return (
    <Structure breadcrumb={"Inversiones / Solicitudes"}>
      <center>
        <Typography.Title level={2} style={{ margin: 0, color: "#081b3e" }}>
          Solicitudes de facturas para pagos.
        </Typography.Title>
      </center>

      <br></br>
      <Table
        hideSelectionColumn={true}
        columns={columnsData}
        dataSource={dataSource}
        pagination={false}
        scroll={{
          y: 310,
        }}
        size={"small"}

      />

      <Modal
        title={
          <div>
            <Typography.Title
              level={3}
              style={{ margin: 0, color: "#081b3e", marginBottom: -10 }}
            >
              <QuestionCircleOutlined /> &nbsp; Solicitud de factura para su pago.
            </Typography.Title>
          </div>
        }
        style={{ marginTop: -50 }}
        width={1100}
        open={isModalOpen}
        onCancel={handleModalClose}
        destroyOnClose={true}
        footer={[
          <Input key={1} placeholder="Correo" style={{ width: 800, marginRight: 5, textAlign: "right" }} value={mailValue} onChange={handleInputMail} />,
          <Button type="primary" onClick={sendEmailFactura} style={{ marginRight: 5 }} icon={<MailOutlined />}>
            Enviar correo
          </Button>,

          <Button type="primary" onClick={handleModalClose} icon={<CloseOutlined />} >
            Cerrar
          </Button>,

        ]}
      >
        <object
          type="application/pdf"
          data={urlPathFactura}
          width="100%"
          height={height}
          style={{ borderRadius: 6, marginTop: 10 }}
        ></object>
      </Modal>

      <Modal
        title={
          <div>
            <Typography.Title
              level={3}
              style={{ margin: 0, color: "#081b3e", marginBottom: -10 }}
            >
              <QuestionCircleOutlined /> &nbsp; Estado de Cuenta.
            </Typography.Title>
          </div>
        }
        style={{ marginTop: -50 }}
        width={1100}
        open={isModalCuentaOpen}
        onCancel={handleModalCuentaClose}
        destroyOnClose={true}
        footer={[
          <Input key={1} placeholder="Correo" style={{ width: 700, marginRight: 5, textAlign: "right" }} value={mailValue} onChange={handleInputMail} />,
          <Button type="primary" onClick={sendEmailEstadoCuenta} style={{ marginRight: 5 }} icon={<MailOutlined />}>
            Enviar correo
          </Button>,

          <Button type="primary" onClick={handleModalCuentaClose} icon={<CloseOutlined />} >
            Cerrar
          </Button>,

        ]}
      >
        <object
          type="application/pdf"
          data={urlPathEstadoCuenta}
          width="100%"
          height={height}
          style={{ borderRadius: 6, marginTop: 10 }}
        ></object>
      </Modal>


      <Modal
        title={
          <div>
            <Typography.Title
              level={3}
              style={{ margin: 0, color: "#081b3e", marginBottom: 10 }}
            >
              <UploadOutlined /> &nbsp; Subir documentos.
            </Typography.Title>
          </div>
        }
        style={{ marginTop: -70 }}
        width={470}
        open={isModalOpenDocs}
        onCancel={handleModalCloseDocs}
        footer={[
          <Button type="primary" onClick={handleModalCloseDocs} icon={<CloseOutlined />} >
            Cerrar
          </Button>,

        ]}
      >

      <List
            bordered
            style={{ alignContent: "center" }}
            dataSource={dataUpload}
            renderItem={(item) => (
              <List.Item>
                <Typography.Title level={5} style={{ margin: 0 }}>
                  {item}
                </Typography.Title>
              </List.Item>
            )}
          />

      </Modal>


    </Structure>
  );
};
export default SolicitudFactura;
